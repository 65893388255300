import { styled } from "@mui/system";
import { COLORS } from "../../../../../utils/constants/colors";

export const DomainsContainer = styled("div")(({ theme }) => ({
  padding: 50,

  ".MuiTabs-root": {
    backgroundColor: COLORS.MAIN_BLUE,
  },

  ".MuiButtonBase-root.MuiTab-root": {
    color: "white",
    textTransform: "initial",
    fontWeight: 600,
  },

  ".MuiTabs-indicator": {
    backgroundColor: COLORS.MAIN_ORANGE,
    height: "4px",
  },

  [theme.breakpoints.down(1550)]: {
    padding: 20,
  },

  [theme.breakpoints.down("sm")]: {
    ".MuiButtonBase-root.MuiTab-root": {
      flex: 1,
    },
  },
}));

export const TitleContainer = styled("div")(({ theme }) => ({
  fontSize: 24,
  fontWeight: 800,
  marginBottom: 20,
  marginTop: 30,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {},

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 20,

    "button": {
      marginTop: 10,
    }
  },
}));

export const TabContentWrapper = styled("div")(({ theme }) => ({
  padding: 40,
  backgroundColor: COLORS.LIGHT_GRAY_2,
  display: "flex",

  [theme.breakpoints.down("md")]: {},

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: 15,
  },
}));

export const KeyWrapper = styled("div")(({ theme }) => ({
  width: "40%",

  [theme.breakpoints.down("md")]: {},

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    fontSize: 12,
  },
}));

export const CopyWrapper = styled("div")(({ theme }) => ({
  padding: 8,
  backgroundColor: "white",
  border: "1px solid #80808038",
  color: COLORS.MAIN_BLUE,
  fontWeight: 500,
  width: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",

  "svg": {
    marginRight: 5,
  },

  [theme.breakpoints.down("md")]: {},

  [theme.breakpoints.down("sm")]: {
    marginTop: 20
  },
}));

export const CopyContainer = styled("div")(({ theme }) => ({
  width: "60%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-start",

  [theme.breakpoints.down("md")]: {},

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "40px",

  [theme.breakpoints.down("sm")]: {
    "button": {
      flex: 1,
    },
    "button:first-of-type": {
      marginRight: 10,
    },
  },
}));

export const PrevValuesListWrapper = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,

    "div": {
      marginBottom: 5,
    }
  },
}));

export const InputValueWrapper = styled("div")(({ theme }) => ({
  backgroundColor: COLORS.MAIN_BLUE,
  color: "white",
  padding: "5px 10px",
  marginRight: 5,
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
  },
}))
