import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { LogoMain } from "../../../components";
import { DomainInfo } from "./components/DomainInfo";
import { RegisterForm } from "./components/RegisterForm";
import { SignUpContainerStyled, SignUpWrapperStyled } from "./utils/styles/styled";
import { SIGN_UP } from "../../../utils/constants/auth";
import { COLORS } from "../../../utils/constants/colors";
import { signUp_ } from "../api/async";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/global";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "../../../pages/router/routes";

export const SignUp = (): React.ReactElement => {
  const { register, handleSubmit } = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [domainInfo, setDomainInfo] = useState<any>(null);

  const user = useAppSelector((store) => store.user);

  useEffect(() => {
    if (user.isLoggedIn) {
      window.location.reload();
    }
  }, [user])

  const onSignUp = (data: any) => {
    dispatch(
      signUp_({
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        password: data.password,
        password_confirmation: data.password_confirm,
        domain_name: domainInfo?.domainName ?? "",
        company: data.company ?? "",
      })
    );
  };

  const goToSignIn = () => {
    navigate(ROUTES.LOGIN);
  };

  useEffect(() => {
    if (location?.state?.domainInfo) {
      setDomainInfo(location.state.domainInfo);
    }
  }, [location]);

  return (
    <SignUpContainerStyled>
      <SignUpWrapperStyled>
        <div style={{ marginBottom: 10, cursor: "pointer" }} onClick={() => navigate(ROUTES.HOME)}>
          <LogoMain size="small" />
        </div>
        {domainInfo ? <DomainInfo domainInfo={domainInfo}/> : null}
        <RegisterForm handleSubmitToSignUp={onSignUp} isFetching={user.isFetching} />
        <div style={{ marginTop: 20 }}>
          {SIGN_UP.ALREADY}{" "}
          <span style={{ cursor: "pointer", color: COLORS.MAIN_BLUE }} onClick={goToSignIn}>
            {SIGN_UP.SIGN_IN}
          </span>
        </div>
      </SignUpWrapperStyled>
    </SignUpContainerStyled>
  );
};

export default React.memo(SignUp);
