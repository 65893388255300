import instance from "../../../service/api";
import { toast } from "react-toastify";

// const getDomains = async (data: any): Promise<AxiosResponse<DomainsResponseModel>> =>
const getDomains = async (data: any) => {
  return await instance
    .get(`/api/v1/domains?domain_name=${encodeURIComponent(data?.domain_name ?? '')}&page=${data?.page ?? 1}&per_page=10&with_untracked=${data.showAll ? 1 : 0}`)
    .then(response => response);
}

const getDomainInfo = async (data: any) => {
  return await instance
    .post(`/api/v1/domain-info`, data)
    .then(response => response);
}

const getDomainHistory = async (data: any) => {
  return await instance
    .get(`/api/v1/domain-check/history/${data.id}?page=${data.page}&per_page=5`)
    .then(response => response);
}

const getDomainFullInfo = async (data: any) => {
  return await instance
    .get(`/api/v1/domains/${data}`)
    .then(response => response);
}

const addDomain = async (data: any) => {
  return await instance
    .post(`/api/v1/domains`, data)
    .then(response => {
      if (response.status === 200) {
        toast.success('The domain has been successfully added!')
      }
      return response;
    });
}

const addDomains = async (data: any) => {
  return await instance
    .post(`/api/v1/domains/multiple`, data)
    .then(response => {
      if (response.status === 200) {
        toast.success('The domains have been successfully added!')
      }
      return response;
    });
}

const addAdditionalEmails = async (data: any) => {
  return await instance
    .put(`/api/v1/domains/${data.id}`, data)
    .then(response => {
      if (response.status === 200) {
        toast.success('Your updates have been saved!')
      }
      return response;
    });
}

const getAdditionalEmails = async () => {
  return await instance
    .get(`/api/v1/user-notifications`)
    .then(response => response);
}

const stopTrackingDomain = async (data: any) => {
  return await instance
    .put(`/api/v1/domains/stop-tracking/${data}`)
    .then(response => response);
}

const startTrackingDomain = async (data: any) => {
  return await instance
    .put(`/api/v1/domains/start-tracking/${data}`)
    .then(response => response);
}

const deleteDomain = async (data: any) => {
  return await instance
      .delete(`/api/v1/domains/${data.id}`)
      .then(response => {
        if (response.status === 200) {
          toast.success('The domain has been successfully deleted!')
        } else {
          toast.error('Server error. Try again!')
        }
        return response;
      });
      // .then(response => response);
}

const editDomainName = async (data: any) => {
  return await instance
      .put(`/api/v1/domains/${data.id}`, {domain_name: data.title})
      .then(response => response);
}

const checkAllDomains = async () => {
  return await instance
    .post(`/api/v1/domains-check`)
    .then(response => response);
}

const checkDomain = async ({id}: {id:string | number}) => {
  return await instance
    .post(`/api/v1/domain-check/${id}`)
    .then(response => response);
}

const clearDomainInfo = () => {
  return true;
}

const exportAllDomains = async (data: any) => {
  return await instance
    .get(`/api/v1/domains/export/csv`, data)
    .then(response => response);
}

const domainsService = {
  getDomains,
  getDomainInfo,
  getDomainFullInfo,
  addDomain,
  addDomains,
  checkAllDomains,
  exportAllDomains,
  stopTrackingDomain,
  startTrackingDomain,
  deleteDomain,
  editDomainName,
  addAdditionalEmails,
  getAdditionalEmails,
  checkDomain,
  clearDomainInfo,
  getDomainHistory,
};

export default domainsService;
