import { createSlice } from "@reduxjs/toolkit";

import { getItemFromLocalStorage } from "../../../utils/helpers";
import { RootState } from "store/store";

import {
  deleteUserProfile,
  getUserInfo,
  login,
  recoveryPassword,
  resetPassword,
  signUp_,
  updateUserInfo,
} from "./async";

const token = getItemFromLocalStorage("token");

const initialState: any = {
  isLoggedIn: !!token && !!token.access_token,
  isFetching: false,
  isUpdated: false,
  isDeleted: false,
  userInfo: null,
  isRecovered: false,
  recoveredToken: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [signUp_.pending.type]: state => {
      state.isFetching = true;
    },
    [signUp_.fulfilled.type]: state => {
      state.isLoggedIn = true;
      state.isFetching = false;
    },
    [signUp_.rejected.type]: state => {
      state.isFetching = false;
    },
    [login.pending.type]: state => {
      state.isFetching = true;
    },
    [login.fulfilled.type]: state => {
      state.isLoggedIn = true;
      state.isFetching = false;
    },
    [login.rejected.type]: state => {
      state.isFetching = false;
    },
    [getUserInfo.pending.type]: state => {
      state.isFetching = true;
    },
    [getUserInfo.fulfilled.type]: (state, action) => {
      state.isFetching = false;
      state.userInfo = action.payload.resource
    },
    [getUserInfo.rejected.type]: state => {
      state.isFetching = false;
    },
    [updateUserInfo.pending.type]: state => {
      state.isFetching = true;
    },
    [updateUserInfo.fulfilled.type]: (state, action) => {
      state.isFetching = false;
      state.isUpdated = true;
      state.userInfo = action.payload.resource.user
    },
    [updateUserInfo.rejected.type]: state => {
      state.isFetching = false;
    },
    [deleteUserProfile.pending.type]: state => {
      state.isFetching = true;
    },
    [deleteUserProfile.fulfilled.type]: (state, action) => {
      state.isFetching = false;
      state.isDeleted = true;
    },
    [deleteUserProfile.rejected.type]: state => {
      state.isFetching = false;
    },
    [recoveryPassword.pending.type]: state => {
      state.isRecovered = false;
    },
    [recoveryPassword.fulfilled.type]: (state, action) => {
      state.isRecovered = true;
      state.recoveredToken = action.payload.resource.token;
    },
    [recoveryPassword.rejected.type]: state => {
      state.isRecovered = false;
    },
    [resetPassword.pending.type]: state => {
    },
    [resetPassword.fulfilled.type]: (state, action) => {
      state.isLoggedIn = true;
    },
    [resetPassword.rejected.type]: state => {
    },
  }
});

export const userSelector = (state: RootState): RootState["user"] => state.user;

const { reducer } = userSlice;

export default reducer;
