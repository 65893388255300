import React from "react";

import { useStartPage } from "./utils/hook";
import { 
  BackgroundFiveWrapper,
  BackgroundFourWrapper,
  BackgroundOneWrapper,
  BackgroundThreeWrapper,
  BackgroundTwoWrapper,
  InputWrapperStyled,
  LogoWrapperStyled,
  StartScreenContainerStyled,
 } from "./ui/styles/styled";
import { DontForgetBlock } from "./ui/DontForgetBlock";
import AnimatedText from "./ui/AnimatedText";
import { DecorativeLine } from "./ui/DecorativeLine";

import ImportantInfoBlock from "./ui/ImportantInfoBlock";
import HelpInfoBlock from "./ui/HelpInfoBlock";
import PricingBlock from "./ui/PricingBlock";
import Footer from "./ui/Footer";
import ButtonsBlock from "./ui/Buttons";
import { InputDomain, LogoMain } from "../../components";

export const StartPage = (): React.ReactElement => {
  const {
    register,
    handleSubmit,
    focusedDomainInput,
    setFocusedDomainInput,
    onHandleGetDomainInfo,
  } = useStartPage();

  return (
    <StartScreenContainerStyled>
      <BackgroundOneWrapper>
        <ButtonsBlock />

        <LogoWrapperStyled>
          <LogoMain size="middle" />
        </LogoWrapperStyled>

        <DontForgetBlock />
        <AnimatedText focusedInput={focusedDomainInput}/>
      </BackgroundOneWrapper>

      <BackgroundTwoWrapper>
        <InputWrapperStyled>
          <InputDomain
            isLabel={false}
            nameInput="domain_name"
            type="search"
            variant="search"
            typeSearch="absolute"
            register={{ ...register("domain_name") }}
            setFocusedDomainInput={setFocusedDomainInput}
            onHandleSubmit={handleSubmit(onHandleGetDomainInfo)}
          />
        </InputWrapperStyled>

        <DecorativeLine />
      </BackgroundTwoWrapper>

      <BackgroundThreeWrapper>
        <ImportantInfoBlock />
      </BackgroundThreeWrapper>

      <BackgroundFourWrapper>
        <HelpInfoBlock />
        <DecorativeLine />
      </BackgroundFourWrapper>

      <BackgroundFiveWrapper>
        <PricingBlock />

        <hr style={{ border: "none", borderTop: "1px solid #094aae8a" }} />

        <Footer />
      </BackgroundFiveWrapper>
    </StartScreenContainerStyled>
  );
};

export default React.memo(StartPage);
