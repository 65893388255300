import { styled } from "@mui/system";

export const ModalWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "white", padding: 30, maxWidth: "30%",

  [theme.breakpoints.down("sm")]: {
    padding: 20,
    maxWidth: "80%",
  },
}));
