export enum ROUTES {
  HOME = "/",
  SIGN_UP = "/sign-up",
  LOGIN = "/login",
  RECOVERY_PASSWORD = '/recovery-password',
  RESTORE_PASSWORD = '/restore-password',
  PROFILE = "/profile",

  DASHBOARD = "/domains",
  NOTIFICATIONS = "/notifications",
  BILLING = "/billing",

  ADD_DOMAIN = "add-domain",
  ADD_DOMAINS = "add-domains",
  DOMAIN_DETAIL = "domain",
}
