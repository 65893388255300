import React, { memo } from "react";

import { ButtonStyled } from "./styled";

interface Props {
  title: string;
  variant: "primary" | "secondary" | "simple" | "gray" | "add" | "transparent";
  size?: "small" | "middle" | "large" | "middle_add";
  disabled?: boolean;
  fullwidth?: boolean;
  typeButton?: "delete" | "none";
  icon?: string;
}

const ButtonMain = ({
  title,
  variant = "primary",
  size = "middle",
  typeButton = "none",
  fullwidth = false,
  disabled = false,
  icon,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <ButtonStyled $fullwidth={fullwidth} $size={size} $variant={variant} $typeButton={typeButton} {...props} disabled={disabled} style={{...props.style}}>
    {icon && <img src={icon} width={20} style={{marginRight: 5}} />}
    {title}
  </ButtonStyled>
);

export default memo(ButtonMain);
