import React, { memo, useEffect, useState } from "react";
import { COLORS } from "../../utils/constants/colors";
import { Title1, Title2, TitleWrapperStyled } from "./utils/styled";
import { TitleModel } from "./utils/models";
import { useNavigate } from "react-router";
import editIcon from "../../assets/images/icon/edit.svg";

export const Title = memo(({title, subtitle, style}: TitleModel): React.ReactElement => {
  return (
    <Title1 style={{...style}}>{title} <br className="brTitle"/>{subtitle && <span style={{color: COLORS.MAIN_GRAY}}>{subtitle}</span>}</Title1>
  );
});

export const SubTitle = memo(({title, subtitle, style}: TitleModel): React.ReactElement => {
  return (
    <Title2 style={{...style}}>{title} <br className="brTitle"/>{subtitle && <span style={{color: COLORS.MAIN_GRAY}}>{subtitle}</span>}</Title2>
  );
});

export const TitleWithOptions = ({name, titleOptions, daysCount}: {name: any, titleOptions: any, daysCount: any}): React.ReactElement => {
  const navigate = useNavigate();
  const [deleteOption, setDeleteOption] = useState<any>(null);
  const [editTitleOption, setEditTitleOption] = useState<any>(null);
  useEffect(() => {
    if (titleOptions.find((option: any) => option.option === "delete")) {
      setDeleteOption(titleOptions.find((option: any) => option.option === "delete"))
    }

    if (titleOptions.find((option: any) => option.option === "editTitle")) {
        setEditTitleOption(titleOptions.find((option: any) => option.option === "editTitle"))
    }
  }, [titleOptions]);

  return (
    <TitleWrapperStyled>
      <div>{name} {editTitleOption && <img onClick={() => editTitleOption.eventHandler()} src={editIcon} alt="Change the name of domain" style={{position: "relative", top: -20, right: 5, cursor: "pointer"}}/>} <br className="brTitle"/>{daysCount && <span style={{color: COLORS.MAIN_GRAY}}>Expires in {daysCount} days</span>}</div>
      <div style={{color: "#f07070", fontSize: 17, cursor: "pointer", textDecoration: "underline"}} onClick={deleteOption ? deleteOption.eventHandler : null}>
        {deleteOption && "Delete this domain"}
      </div>
    </TitleWrapperStyled>
  );
};

export default Title;
