export const START_SCREEN = {
  PRICING_BTN: "Pricing",
  SIGN_UP_BTN: "Free Sign Up",
  MONITORING_BTN: "Set Up Monitoring Now!",
  NOTIFS_BTN: "Set Up Notification for Free",
  START_BTN: "get started",

  TYPED_PHRASES: [
    "not make your customers see an error on your website",
    "not make your customers see an error on your website",
    "not make your customers see an error on your website",
  ],

  DOMAIN_LABEL: "Please enter your domain name to start:",
  DOMAIN_PREFIX: "https://",

  W_TITLE: ["Why", "is", "this", "important?"],
  H_TITLE: ["how", "can", "we", "help?"],
  W_TEXT:
    "Our days it is cruicial to keep your business online 24/7/365. Prevent your website downtime by getting alerts on your SSL certificates expiration or changes.",
  H_TEXT:
    "SSLMemo.com is a simple tool which will help you to setup the notifications which you will get via email and Slack ahead of expiration date so you will make sure you are on track",

  PACKAGE_1_INCLUDES: ["Monitor 2 SSL Certificates Expiration or Changes", "Email notifications"],
  PACKAGE_2_INCLUDES: [
    "Monitor up to 20 SSL Certificates Expiration or Changes",
    "Email / Slack notifications",
  ],

  PRICE_TITLE: "PRICING",
  PRICE_TEXT_1: "We provide clean and straightforward",
  PRICE_TEXT_2: "pricing plans below",
  PACKAGE_NAME_1: "FREE",
  PACKAGE_NAME_2: "PRO",

  COPYRIGHT: {
    YEAR: "© 2024",
    COMPANY: "SSL Tracker.",
    RESERVED: "All rights reserved.",
  },
} as const;
