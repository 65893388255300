import React, { useState } from "react";
import Header from "./components/Header";
import {
  BodyWrapper,
  ContentContainer,
  ContentWrapper,
  NavbarContainer,
} from "./utils/styles/styled";
import Sidebar from "./components/Sidebar";
import {
  FooterCopyright,
  FooterNavItem,
  FooterWrapper,
  NavFooterMenu,
} from "../../pages/start-page/ui/styles/footer.styled";
import { START_SCREEN } from "../../utils/constants/start-screen";
import twitterIcon from "../../assets/images/icon/twitter.svg";

export const NavbarWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [openedAllMenu, setOpenedAllMenu] = useState<boolean>(false);

  return (
    <NavbarContainer>
      <Header setOpenedAllMenu={setOpenedAllMenu} openedAllMenu={openedAllMenu}/>
      <BodyWrapper>
        <Sidebar openedAllMenu={openedAllMenu} setOpenedAllMenu={setOpenedAllMenu}/>
        <ContentContainer>
          <ContentWrapper>{children}</ContentWrapper>
          <FooterWrapper className="noPadding">
            <FooterCopyright>
              {START_SCREEN.COPYRIGHT.YEAR}{" "}
              <span style={{ color: "#224AFF" }}>{START_SCREEN.COPYRIGHT.COMPANY}</span>{" "}
              {START_SCREEN.COPYRIGHT.RESERVED}
            </FooterCopyright>

            <NavFooterMenu>
              <FooterNavItem $name="">Terms of Use</FooterNavItem>
              <FooterNavItem $name="">Privacy Policy</FooterNavItem>
              {/*<FooterNavItem $name="sign-up">Sign Up</FooterNavItem>*/}
              {/*<FooterNavItem $name="sign-in">Sign In</FooterNavItem>*/}
              {/*<div style={{ paddingLeft: "10px", cursor: "pointer" }}>Twitter</div>*/}
              <div style={{ paddingLeft: "10px", cursor: "pointer" }}><img src={twitterIcon} width={20}/></div>
            </NavFooterMenu>
          </FooterWrapper>
        </ContentContainer>
      </BodyWrapper>
    </NavbarContainer>
  );
};

export default React.memo(NavbarWrapper);
