import { styled } from "@mui/system";

export const FormContainerStyled = styled("form")(({ theme }) => ({
  width: 650,
  // height: 300,
  backgroundColor: "white",
  border: "4px solid white",
  borderRadius: 4,
  padding: "16px 35px",
  boxSizing: "border-box",

  ".MuiFormControl-root": {
    marginBottom: "15px !important",
  },

  "button": {
    width: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "16px 15px",
    width: "90%",
  },
}));

export const InputsWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  ".MuiFormControl-root": {
    flex: 1,
  },

  ".MuiFormControl-root:first-child": {
    marginRight: "15px !important",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    display: "block",

    ".MuiFormControl-root:first-child": {
      marginRight: "0px !important",
    },
  },
}));

export const FormTitleStyled = styled("div")(({ theme }) => ({
  textAlign: "center", fontWeight: 800, fontSize: 24, marginBottom: 10,

  [theme.breakpoints.down("sm")]: {
    fontSize: 20,
  },
}));

