import React, { memo, useEffect, useState } from "react";

import { FormGroupStyled } from "./utils/styled";
import { useCheckbox } from "./utils/hook";
import { PropsModel } from "./utils/models";

const Checkbox = (props: any): JSX.Element => {
  const { label, id } = props;
  const { onHandleChange, checked } = useCheckbox(props);

  return <FormGroupStyled>
    <input type="checkbox" id={id} onChange={onHandleChange} checked={checked} />
    <label htmlFor={id}>{label}</label>
  </FormGroupStyled>
};

export default memo(Checkbox);
