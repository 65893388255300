import { styled } from "@mui/system";
import { COLORS } from "../../../../../utils/constants/colors";

export const SignUpContainerStyled = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 39,
  paddingBottom: 75,
  width: "100%",
  height: "100%",
  // height: "inherit",
  background: COLORS.MAIN_GRADIENT,
}));

export const SignUpWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));