import React, { useEffect } from "react";

import { Spinner, Title } from "../../components";
import { DomainsContainer, TemplatesWrapper } from "./ui/styles/styled";
import SelectionsTemplate from "./ui/SelectionsTemplate";
import { useNotificationSettings } from "./utils/hook";
import { NOTIFICATIONS_LIST_TEMPLATE } from "./utils/constants";

export const Domains = (): React.ReactElement => {
  const { notificationSettings, isFetching } = useNotificationSettings();

  return (
    <DomainsContainer>
      <Title title={"Notification Settings"} />
      <Spinner isLoading={isFetching} />
      <div style={{display: 'flex'}}>
      <TemplatesWrapper style={{marginRight: 50}}>
        {NOTIFICATIONS_LIST_TEMPLATE.slice(0,3).map((TEMPLATE: any, i: number) => {
          return (
            <SelectionsTemplate
              title={TEMPLATE.TITLE}
              buttonTitle={TEMPLATE.BUTTON_TITLE}
              buttonVariant={TEMPLATE.BUTTON_VARIANT}
              selections={TEMPLATE.SELECTIONS}
              text={TEMPLATE.TOP_TEXT}
              textBottom={TEMPLATE.BOTTOM_TEXT}
              preBtnText={TEMPLATE.PRE_BUTTON_TEXT}
              preBtnTitle={TEMPLATE.PRE_BUTTON_TITLE}
              preBtnVariant={TEMPLATE.PRE_BUTTON_VARIANT}
              index={i}
              notificationSettings={notificationSettings}
              onSubmit={null}
              key={`template-${i}`}
            />
          );
        })}
      </TemplatesWrapper>

      <TemplatesWrapper>
        {NOTIFICATIONS_LIST_TEMPLATE.slice(3).map((TEMPLATE: any, i: number) => {
          return (
            <SelectionsTemplate
              title={TEMPLATE.TITLE}
              buttonTitle={TEMPLATE.BUTTON_TITLE}
              buttonVariant={TEMPLATE.BUTTON_VARIANT}
              selections={TEMPLATE.SELECTIONS}
              text={TEMPLATE.TOP_TEXT}
              textBottom={TEMPLATE.BOTTOM_TEXT}
              preBtnText={TEMPLATE.PRE_BUTTON_TEXT}
              preBtnTitle={TEMPLATE.PRE_BUTTON_TITLE}
              preBtnVariant={TEMPLATE.PRE_BUTTON_VARIANT}
              index={i}
              notificationSettings={notificationSettings}
              onSubmit={null}
              key={`template-${i}`}
            />
          );
        })}
      </TemplatesWrapper>
      </div>
    </DomainsContainer>
  );
};

export default React.memo(Domains);
