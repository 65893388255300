import { ButtonMain, CheckboxGreen } from "../../../components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../pages/router/routes";

import {
  ButtonWrapper,
  TemplateContainer,
  TitleContainer,
} from "../ui/styles/selections.styled";
import { useAppDispatch } from "../../../utils/hooks/global";
import { setNotifByEmail, setNotifSchedule, setNotifTypes } from "../api/async";

interface ITemplate {
  text?: string;
  title: string;
  index: number;
  buttonTitle: string;
  buttonVariant: "primary" | "secondary";
  selections?: Array<{ value: string; id: string; checked: boolean }>;
  textBottom?: string;
  preBtnText?: string;
  preBtnTitle?: string;
  preBtnVariant?: "primary" | "secondary";
  onSubmit: any;
  notificationSettings: any;
}

export const SelectionsTemplate = ({
  title,
  selections,
  index,
  text,
  buttonTitle,
  buttonVariant,
  textBottom,
  preBtnText,
  preBtnTitle,
  preBtnVariant,
  onSubmit,
  notificationSettings,
}: ITemplate): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [checkboxTypes, setCheckboxTypes] = useState<any>([
    {
      id: 'is_encrypt',
      value: `Let's Encrypt`,
      checked: false,
    },
    {
      id: 'is_change_alerts',
      value: `Change Alerts`,
      checked: false,
    },
  ]);
  
  const [checkboxSchedule, setCheckboxSchedule] = useState<any>([
    {
      id: 'is_one_day_before_expiry',
      value: `1 day before expiry`,
      checked: false,
    },
    {
      id: 'is_three_days_before_expiry',
      value: `3 days before expiry`,
      checked: false,
    },
    {
      id: 'is_seven_days_before_expiry',
      value: `7 days before expiry`,
      checked: false,
    },
    {
      id: 'is_fourteen_days_before_expiry',
      value: `14 day before expiry`,
      checked: false,
    },
    {
      id: 'is_thirty_days_before_expiry',
      value: `30 days before expiry`,
      checked: false,
    },
    {
      id: 'is_sixty_days_before_expiry',
      value: `60 days before expiry`,
      checked: false,
    },
  ]);
  const [checkboxByEmail, setCheckboxByEmail] = useState<any>([
    {
      id: 'is_notify_email',
      value: `Email Notifications Enabled?`,
      checked: false,
    }
  ]);

  useEffect(() => {
    if (notificationSettings) {
      setCheckboxTypes([
        {
          id: 'is_encrypt',
          value: `Let's Encrypt`,
          checked: notificationSettings.is_encrypt,
        },
        {
          id: 'is_change_alerts',
          value: `Change Alerts`,
          checked: notificationSettings.is_change_alerts,
        },
      ])

      setCheckboxByEmail([
        {
          id: 'is_notify_email',
          value: `Email Notifications Enabled?`,
          checked: notificationSettings.is_notify_email,
        }
      ])

      setCheckboxSchedule([
        {
          id: 'is_one_day_before_expiry',
          value: `1 day before expiry`,
          checked: notificationSettings.is_one_day_before_expiry,
        },
        {
          id: 'is_three_days_before_expiry',
          value: `3 days before expiry`,
          checked: notificationSettings.is_three_days_before_expiry,
        },
        {
          id: 'is_seven_days_before_expiry',
          value: `7 days before expiry`,
          checked: notificationSettings.is_seven_days_before_expiry,
        },
        {
          id: 'is_fourteen_days_before_expiry',
          value: `14 day before expiry`,
          checked: notificationSettings.is_fourteen_days_before_expiry,
        },
        {
          id: 'is_thirty_days_before_expiry',
          value: `30 days before expiry`,
          checked: notificationSettings.is_thirty_days_before_expiry,
        },
        {
          id: 'is_sixty_days_before_expiry',
          value: `60 days before expiry`,
          checked: notificationSettings.is_sixty_days_before_expiry,
        },
      ])
    }
  }, [notificationSettings])

  const handleSave = () => {
    if (title === 'Types') {
      dispatch(setNotifTypes({is_encrypt: checkboxTypes[0].checked, is_change_alerts: checkboxTypes[1].checked}))
    } else if (title === 'Email') {
      dispatch(setNotifByEmail({is_notify_email: checkboxByEmail[0].checked}))
    } else if (title === 'Schedule') {
      dispatch(setNotifSchedule({
        is_one_day_before_expiry: checkboxSchedule[0].checked,
        is_three_days_before_expiry: checkboxSchedule[1].checked,
        is_seven_days_before_expiry: checkboxSchedule[2].checked,
        is_fourteen_days_before_expiry: checkboxSchedule[3].checked,
        is_thirty_days_before_expiry: checkboxSchedule[4].checked,
        is_sixty_days_before_expiry: checkboxSchedule[5].checked,
      }))
    }
  };

  const updateTypesState = (id: string, checked: boolean) => {
    setCheckboxTypes((prevState: any) => {
      const newState = [...prevState];
      const itemToUpdate = newState.find((item: any) => item.id === id);
      if (itemToUpdate) {
        itemToUpdate.checked = checked;
      }
      return newState;
    });
  };
  
  const updateEmailState = (id: string, checked: boolean) => {
    setCheckboxByEmail((prevState: any) => {
      const newState = [...prevState];
      const itemToUpdate = newState.find((item: any) => item.id === id);
      if (itemToUpdate) {
        itemToUpdate.checked = checked;
      }
      return newState;
    });
  };
  
  const updateScheduleState = (id: string, checked: boolean) => {
    setCheckboxSchedule((prevState: any) => {
      const newState = [...prevState];
      const itemToUpdate = newState.find((item: any) => item.id === id);
      if (itemToUpdate) {
        itemToUpdate.checked = checked;
      }
      return newState;
    });
  };



  return (
    <TemplateContainer>
      <TitleContainer>{title}</TitleContainer>

      {text ? <p>{text}</p> : null}

      {(title === 'Types' && checkboxTypes)
          && checkboxTypes?.map((selection: any) => {
            return (
              <div style={{ marginBottom: 15 }} key={selection.value}>
                <CheckboxGreen label={selection.value} defaultChecked={selection.checked} onChange={updateTypesState} id={selection.id}/>
              </div>
            );
          })}

          {(title === 'Email' && checkboxByEmail)
          && checkboxByEmail?.map((selection: any) => {
            return (
              <div style={{ marginBottom: 15 }} key={selection.value}>
                <CheckboxGreen label={selection.value} defaultChecked={selection.checked} onChange={updateEmailState} id={selection.id}/>
              </div>
            );
          })
          }

          <div style={{display: 'flex'}}>
          <div>
          {(title === 'Schedule' && checkboxSchedule)
          && checkboxSchedule?.map((selection: any) => {
            return (
              <div style={{ marginBottom: 15 }} key={selection.value}>
                <CheckboxGreen label={selection.value} defaultChecked={selection.checked} onChange={updateScheduleState} id={selection.id}/>
              </div>
            );
          })
          }
          </div>

          </div>

      {textBottom ? <p style={{marginBottom: 30,}}>{textBottom}</p> : null}

      {(preBtnText && preBtnTitle && preBtnVariant) ? (
        <div>
          {preBtnText} <ButtonWrapper><ButtonMain title={preBtnTitle} variant={preBtnVariant} size="small" onClick={() => preBtnTitle === "Upgrade Required" ? navigate(ROUTES.BILLING) : null}/></ButtonWrapper>
        </div>
      ) : null}

      <ButtonWrapper style={{marginTop: 10}}>
        <ButtonMain title={buttonTitle} variant={buttonVariant} size="small" onClick={() => buttonTitle === "Upgrade Required" ? navigate(ROUTES.BILLING) : handleSave()} disabled={title === 'Slack' ? true : false} />
      </ButtonWrapper>
    </TemplateContainer>
  );
};

export default SelectionsTemplate;
