import React, { memo, useEffect, useState } from "react";
import { START_SCREEN } from "utils/constants/start-screen";

import { InputSearchModel } from "../utils/inputs.model";
import { CaptionStyled, FormControlStyled, ButtonStyled } from "../utils/styled";

import {
  InputWrapperStyled,
  InputWrapSearchStyled,
  InputStyled,
  InputContainerStyled,
  LabelStyled,
  ArrowBtnStyled,
  InputValueWrapper,
  PrevValuesListWrapper,
} from "./styled";

const InputDomains = ({
  isLabel = false,
  disabled = false,
  error = false,
  type,
  labelText = "",
  captionText,
  variant,
  nameInput = "input",
  register,
  setFocusedDomainInput,
  typeSearch = "standart",
  placeholder = "Search",
  setValuesList,
  valuesList,
  setEmailError,
  emailError = false,
  regex = null,
  ...props
}: InputSearchModel & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  // const [valuesList, setValuesList] = useState<Array<string>>([]);

  const onHandleChange = (ev: any) => {
    const cleanedValue = ev.target.value.replace(/[;,]/g, "");
    setEmailError && setEmailError(false);
    setInputValue(cleanedValue);
  };

  const onHandlePress = (ev: any) => {
    if (inputValue.length) {
      const cleanedValue = inputValue.replace(/[;,]/g, "");
      // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      // const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValueFormat = regex ? regex.test(cleanedValue) : true;

      if (ev.key === "Enter" || ev.key === "," || ev.key === ";" || ev.key === " ") {
        if (setValuesList && valuesList && isValueFormat) {
          setValuesList([...valuesList, cleanedValue]);
          setInputValue("");
          setEmailError && setEmailError(false);
        } else if (!isValueFormat) {
          setEmailError && setEmailError(true);
        }
      }
    }
  };

  const onHandleKeyUp = (ev: any) => {
    if (ev.code === "Backspace" && !inputValue.length && valuesList && setValuesList) {
      if (valuesList.length) {
        let croppedList = [...valuesList];
        croppedList.pop();
        setValuesList(croppedList);
      }
    }
  };

  useEffect(() => {

  }, [error]);

  return (
    <>
      <InputContainerStyled>
        <LabelStyled>{labelText}</LabelStyled>
        <InputWrapperStyled isError={emailError ? true : false}>
          <PrevValuesListWrapper>
            {valuesList && valuesList.map((prevDomain: string) => {
              return <InputValueWrapper key={prevDomain}>{prevDomain}</InputValueWrapper>;
            })}
          </PrevValuesListWrapper>
          <InputStyled onChange={onHandleChange} onKeyPress={onHandlePress} value={inputValue} onKeyUp={onHandleKeyUp}/>
        </InputWrapperStyled>
      </InputContainerStyled>
    </>
  );
};

export default memo(InputDomains);
