import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../../utils/hooks/global";
import { addDomain } from "../../api/async";
import { history } from "../../../../utils/helpers";
import { ROUTES } from "./../../../../pages/router/routes";

export const useCreateDomain = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const domainsStore = useAppSelector(store => store.domains);

  const [loading, setLoading] = useState(false);

  const onHandleCreateDomain = (data: any) => {
    setLoading(true);
    dispatch(addDomain(data.domain));
  }

  useEffect(() => {
    if (domainsStore.isAddedDomain) {
      setTimeout(() => {
        history.push(ROUTES.DASHBOARD, { replace: true });
        window.location.reload();
      }, 2000)
    }
  }, [domainsStore])

  const onHandleClick = handleSubmit(onHandleCreateDomain);

  return {
    register,
    loading,
    onHandleClick,
    navigate,
  };
};