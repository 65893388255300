import { styled } from "@mui/system";
import { ReactComponent as WLetter } from "../../../../assets/images/icon/w-letter.svg";
import { ExtendedThemeOptions } from "../../../../assets/theme-types";

export const ImportantBlockWrapper = styled("div")(({ theme }) => ({
  maxWidth: "90%",
  paddingLeft: "20%",
  paddingRight: "20%",

  [theme.breakpoints.down("lg")]: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },

  [theme.breakpoints.down("md")]: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
}));

export const ImportantQuestionWrapper = styled("div")(({ theme }) => ({
  textTransform: "uppercase",
  position: "relative",
  ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_124px,

  [theme.breakpoints.down("md")]: {
    ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_52px,
  },
}));

export const ParagraphWrapper = styled("div")(({ theme }) => ({
  fontSize: "36px",
  maxWidth: "75%",
  marginTop: 40,
  marginBottom: 40,
  lineHeight: "52px",

  [theme.breakpoints.down("md")]: {
    fontSize: "23px",
    lineHeight: "32px",
    maxWidth: "95%",
  },
}));

export const WLetterIcon = styled(WLetter)(({ theme }) => ({
  position: "absolute",
  width: 400,
  right: 0,
  top: -60,

  [theme.breakpoints.down("md")]: {
    width: 350,
    top: -30,
  },
}));

export const WLetterWrapperDesktop = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: -60,

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const WLetterWrapperMobile = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down("md")]: {
    display: "block",
    position: "absolute",
    right: "10%",
    top: 0,
  },
}));
