import { styled } from "@mui/system";

import { InputWrapStyled } from "../utils/styled";
import { ReactComponent as ArrowBtn } from "../../../assets/images/icon/arrow-btn.svg";
import { ExtendedThemeOptions } from "../../../assets/theme-types";
import { COLORS } from "../../../utils/constants/colors";

export const InputWrapSearchStyled = styled(InputWrapStyled)({
  minWidth: "44px",
  height: "44px",
  position: "relative",
});

export const InputContainerStyled = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap-reverse",
    padding: "5px 10px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0px",
  },
}))

export const InputValueWrapper = styled("div")(({ theme }) => ({
  backgroundColor: COLORS.MAIN_BLUE,
  color: "white",
  padding: "5px 10px",
  marginRight: 5,
  fontWeight: 600,

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
  },
}))

export const PrevValuesListWrapper = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}))

export const InputWrapperStyled = styled("div")(({ theme }) =>({
  border: "1px solid #224aff",
  display: "flex",
  alignItems: "center",
  padding: "10px 20px",
  height: "50px",
  boxSizing: "border-box",
  fontSize: "16px",
  color: "#224aff",
  fontStyle: "italic",
  position: "relative",

  [theme.breakpoints.down("sm")]: {
    padding: "15px 24px",
    fontSize: "16px",
  },
}));

export const LabelStyled = styled("span")(({ theme }) => ({
  color: COLORS.MAIN_PINK,
  marginBottom: 7,
  textTransform: "uppercase",
  fontWeight: 700,

  // ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_16px,

  [theme.breakpoints.down("md")]: {
    ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_12px,
  },
}))

export const ArrowBtnStyled = styled(ArrowBtn)(({ theme }) => ({
  width: 30,
  height: 30,
  position: "absolute",
  right: "25px",
  cursor: "pointer",

  [theme.breakpoints.down("sm")]: {
    width: 20,
    height: 20,
  },
}));


export const TextareaStyled = styled("textarea")(({ theme }) => ({
  fontSize: "20px",
  color: "#224aff",
  fontStyle: "italic",
  backgroundColor: "#ffffff00",
  minHeight: 120,
  resize: "vertical",

  border: "1px solid #224aff",
  padding: "10px 20px",
  boxSizing: "border-box",
  position: "relative",

  [theme.breakpoints.down("sm")]: {
    padding: "15px 24px",
    fontSize: "16px",
  },
}));
