import React from "react";
import { HashLink } from "react-router-hash-link";

import { ButtonMain } from "../../../components";
import { ButtonsContainerStyled, ButtonWrapperStyled } from "../ui/styles/buttons.styled";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../pages/router/routes";
import { START_SCREEN } from "../../../utils/constants/start-screen";

export const ButtonsBlock = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <ButtonsContainerStyled>
      <ButtonWrapperStyled>
        <HashLink to="#pricing" smooth>
          <ButtonMain title={START_SCREEN.PRICING_BTN} variant="secondary" size="large" />
        </HashLink>
      </ButtonWrapperStyled>
      <ButtonMain
        title={START_SCREEN.SIGN_UP_BTN}
        variant="primary"
        size="large"
        onClick={() => navigate(ROUTES.SIGN_UP)}
        // onClick={() => null}
      />
    </ButtonsContainerStyled>
  );
};

export default ButtonsBlock;
