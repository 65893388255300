import { styled } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { COLORS } from "../../../utils/constants/colors";

// import searchIcon from "assets/images/icon/search.svg";
// import cancelSearchIcon from "assets/images/icon/search-cancel.svg";

const repearedGenerealStyles = {
  // borderRadius: "8px",
  fontSize: "14px",
  lineHeight: "20px",
  width: "100%",
  fontFamily: `'Sora', sans-serif `,
  padding: "2px 16px 2px 16px",
  boxSizing: "border-box",
  whiteSpace: "nowrap",
};

const InputBgWhitehStyles = {
  transitionDuration: "300ms",
  backgroundColor: "#FFFFFF !important",
  outline: "none !important",
};

const SearchInputVariables = {
  standart: {
    backgroundColor: "#FBFBFB",
    border: "1px solid #DEDEDE",
    transitionDuration: "300ms",
    width: "320px",
    maxWidth: "44px",
    height: "44px",
    padding: "0px 0px 0px 0px",
    boxSizing: "border-box",
    color: "transparent !important",
    "&:focus, &:active, &:hover": {
      border: "1px solid #082634",
      maxWidth: "100% !important",
      padding: "2px 16px 2px 50px",
      color: "#082634 !important",
      ...(InputBgWhitehStyles as React.CSSProperties),
    },
    "form:hover &": {
      border: "1px solid #082634",
      transitionDuration: "300ms",
      maxWidth: "100% !important",
      padding: "2px 16px 2px 50px",
      backgroundColor: "#FFFFFF",
      color: "#082634 !important",
      "&::placeholder": {
        color: "#989898 !important",
        transitionDuration: "300ms",
      },
    },
  },
  absolute: {
    backgroundColor: "#FBFBFB",
    border: "1px solid #DEDEDE",
    transitionDuration: "300ms",
    maxWidth: "44px",
    height: "44px",
    padding: "0px 30px 0px 0px",
    boxSizing: "border-box",
    color: "transparent !important",
    right: "0px",
    "&:focus, &:active, &:hover": {
      border: "1px solid #082634",
      width: "270px !important",
      maxWidth: "none",
      padding: "2px 40px 2px 10px",
      transitionDuration: "300ms",
      color: "#082634 !important",
      ...(InputBgWhitehStyles as React.CSSProperties),
      "&::placeholder": {
        color: "#989898 !important",
        transitionDuration: "300ms",
      },
    },
    "form:hover &": {
      border: "1px solid #082634",
      transitionDuration: "300ms",
      width: "270px !important",
      maxWidth: "none",
      padding: "2px 40px 2px 10px",
      backgroundColor: "#FFFFFF",
      color: "#082634 !important",
      "&::placeholder": {
        color: "#989898 !important",
        transitionDuration: "300ms",
      },
    },
  },
};

export const styleOptionsInputVariant = {
  standard: {
    backgroundColor: "#FFFFFF",
    border: `1px solid ${COLORS.MAIN_BLUE}`,
    height: "44px",
    color: `${COLORS.MAIN_BLUE} !important`,
    transition: "0.3s all ease",
    ...(repearedGenerealStyles as React.CSSProperties),

    "&:focus, &:active": {
      border: `1.5px solid ${COLORS.MAIN_BLUE}`,
      transitionDuration: "300ms",
    },
    "&::placeholder": {
      color: "#989898",
    },
  },
  mediumField: {
    backgroundColor: "#F0F0F0",
    border: "1px solid transparent",
    height: "40px",
    color: "#082634 !important",
    outline: "none !important",
    ...(repearedGenerealStyles as React.CSSProperties),

    "&:focus": {
      border: "1px solid #082634 !important",
      ...(InputBgWhitehStyles as React.CSSProperties),
    },
    "&:placeholder": {
      color: "#989898",
    },
  },
  mediumStroke: {
    border: "1px solid #989898",
    height: "40px",
    color: "#082634 !important",
    ...(InputBgWhitehStyles as React.CSSProperties),
    ...(repearedGenerealStyles as React.CSSProperties),

    "&:focus": {
      border: "1px solid #082634",
      ...(InputBgWhitehStyles as React.CSSProperties),
    },
    "&:placeholder": {
      color: "#989898",
    },
  },
  search: {
    ...(repearedGenerealStyles as React.CSSProperties),
  },
  "&:hover": {
    border: "1px solid #082634",
    maxWidth: "100% !important",
    padding: "2px 16px 2px 44px",
    "&::placeholder": {
      color: "#989898 !important",
    },
    ...(InputBgWhitehStyles as React.CSSProperties),
  },
};

export const InputStyled = styled("input", {
  shouldForwardProp: prop => prop !== "$variant" && prop !== "$islabel" && prop !== "$typeSearch",
})<{
  $islabel: boolean;
  $variant: "standard" | "mediumField" | "mediumStroke" | "search";
  $typeSearch: "standart" | "absolute";
}>(({ $islabel, $variant, $typeSearch, theme }) => ({
  display: "flex",
  transitionDuration: "300ms",
  maxWidth: "100%",
  paddingTop: $islabel ? "16px;" : "0px;",
  outline: "none !important",
  fontStyle: "italic",
  "&[disabled]": {
    opacity: "0.5 !important",
    color: "#082634 !important",
    transitionDuration: "300ms",
  },
  "&[data-error='true']": {
    border: "1px solid #F02E1B !important",
    transitionDuration: "300ms",
  },
  "&::-webkit-search-cancel-button": {
    WebkitAppearance: "none",
    width: "13px",
    height: "13px",
    // background: `url(${cancelSearchIcon}) no-repeat 50% 50%`,
    backgroundSize: "13px 13px",
    paddingLeft: "5px",
    cursor: "pointer",
  },
  "&[type='search']": {
    paddingLeft: "44px",
    "&::placeholder": {
      color: "#ffffff",
      transitionDuration: "300ms",
    },
    ...(SearchInputVariables[$typeSearch] as React.CSSProperties),
  },

  ...(styleOptionsInputVariant[$variant] as React.CSSProperties),
}));

export const EyeContainer = styled("div")({
  position: "absolute",
  right: "15px",
  top: "25px",
  cursor: "pointer",

  "svg": {
    color: COLORS.MAIN_BLUE
  }
});

export const LabelStyled = styled(InputLabel)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "16px",
  color: `${COLORS.MAIN_PINK} !important`,
  textTransform: "uppercase",
  fontWeight: "700",
  // fontFamily: `'Sora', sans-serif `,
  transitionDuration: "300ms",
  "&.MuiFormLabel-root.MuiInputLabel-root": {
    position: "initial",
    transform: "initial",
  },
  "&.Mui-disabled": {
    opacity: "0.5 !important",
    color: "#082634 !important",
    transitionDuration: "300ms",
  },
}));

export const InputWrapStyled = styled("div")({ display: "flex" });

export const CaptionStyled = styled(FormHelperText)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "16px",
  color: "#888888",
  fontWeight: "400",
  fontFamily: `'Sora', sans-serif `,
  "&.Mui-disabled": {
    opacity: "0.5",
    color: "#082634",
    transitionDuration: "300ms",
  },
  "&.Mui-error": {
    color: "#F02E1B",
    transitionDuration: "300ms",
  },
}));

export const FormControlStyled = styled(FormControl)({ 
  display: "flex", 
  // maxWidth: 100 
});

export const ButtonStyled = styled("button")({
  // position: "absolute",
  width: "46px",
  height: "100%",
  // background: `url(${searchIcon}) no-repeat 50% 50%`,
  backgroundSize: "18px 18px",
  bottom: "0px",
  left: "0px",
  zIndex: "1",
  border: "none",
  outline: "none",
  cursor: "pointer",
  transitionDuration: "300ms",
  "&:hover": {
    transitionDuration: "300ms",
    backgroundColor: "#c8e8f666",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
});
