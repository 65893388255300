import React from "react";

import { ButtonMain, Title, SubTitle, Input } from "../../../components";
import { DomainsContainer, ButtonWrapper } from "./ui/styles/styled";
import { COLORS } from "../../../utils/constants/colors";
import { useCreateDomain } from "./utils/hook";
import { DOMAIN_CREATE } from "./utils/constants";

export const Domains = (): React.ReactElement => {
  const {
    register,
    loading,
    onHandleClick,
    navigate,
  } = useCreateDomain();

  return (
    <DomainsContainer>
      <Title title={DOMAIN_CREATE.TITLE} />
      <SubTitle style={{ marginTop: 20 }} title={DOMAIN_CREATE.SUBTITLE} />
      <Input
        type="text"
        nameInput="domain"
        register={{
          ...register("domain"),
          required: true,
        }}
        isLabel={true}
        labelText={DOMAIN_CREATE.INPUT_LABEL}
      />
      <div style={{ color: COLORS.MAIN_GRAY, fontStyle: "italic", marginTop: 10 }}>
        {DOMAIN_CREATE.NOTE_1}{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.punycoder.com/"
          style={{ color: COLORS.MAIN_BLUE, cursor: "pointer", textDecoration: "none" }}
        >
          {DOMAIN_CREATE.NOTE_LINK}
        </a>
        {DOMAIN_CREATE.NOTE_2}
      </div>

      <ButtonWrapper>
        <ButtonMain variant="secondary" title="Back" size="small" onClick={() => navigate(-1)}/>
        <ButtonMain variant="primary" title="Create Domain" size="small" onClick={onHandleClick} disabled={loading} />
      </ButtonWrapper>
    </DomainsContainer>
  );
};

export default React.memo(Domains);
