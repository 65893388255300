import React from "react";
import {
  ActionsWrapperStyled,
  ProfileFormWrapper,
} from "../ui/styles/actions.styled";
import Input from "../../../components/inputs/input/Input";

export const ProfileForm = ({userInfo, register, watch}: {userInfo: any, register: any, watch: any}): React.ReactElement => {

  return (
    <ProfileFormWrapper>
      <ActionsWrapperStyled>
        <Input
          type="text"
          nameInput="email"
          register={{
            ...register("email", {
              // pattern: "\b[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}\b",
            }),
            required: true,
          }}
          isLabel={true}
          labelText="Email:"
          wrapperClassName="maxWidthInput marginInput"
          defaultValue={userInfo.email}
          // error={errors?.password ? true : false}
          // captionText={(errors.password?.message?.toString() as string) || ""}
        />
        <Input
          type="text"
          nameInput="first_name"
          register={{
            ...register("first_name"),
            required: true,
          }}
          isLabel={true}
          labelText="First Name:"
          defaultValue={userInfo.user_profile.first_name}
          // error={errors?.password ? true : false}
          // captionText={(errors.password?.message?.toString() as string) || ""}
          className="firstInput"
          wrapperClassName="maxWidthInput marginInput"
        />
        <Input
          type="text"
          nameInput="last_name"
          register={{
            ...register("last_name"),
            required: true,
          }}
          isLabel={true}
          labelText="Last Name:"
          defaultValue={userInfo.user_profile.last_name}
          wrapperClassName="maxWidthInput"
          // error={errors?.password ? true : false}
          // captionText={(errors.password?.message?.toString() as string) || ""}
        />
      </ActionsWrapperStyled>

      <Input
        type="text"
        nameInput="company"
        register={{
          ...register("company"),
          required: true,
        }}
        isLabel={true}
        labelText="Company:"
        defaultValue={userInfo.user_profile.company}
        // error={errors?.password ? true : false}
        // captionText={(errors.password?.message?.toString() as string) || ""}
        className="firstInput"
        wrapperClassName="marginBottomInput"
      />

      {/* <ActionsWrapperStyled>
      <Input
          type="password"
          nameInput="password"
          register={{
            ...register("password"),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="Password:"
          className="firstInput"
          wrapperClassName="maxWidthInput marginInput"
          hidePassword={hidePassword}
          setHidePassword={setHidePassword}
        />
        <Input
          type="password"
          nameInput="password_confirm"
          register={{
            ...register("password_confirm", {
              validate: (val: string) => {
                if (watch('password') !== val) {
                  return "Your passwords do no match";
                }
              },
            }),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="Password CONFIRMATION:"
          wrapperClassName="maxWidthInput marginInput"
          hidePassword={hideConfirmPassword}
          setHidePassword={setHideConfirmPassword}
        />
      <Input
          type="password"
          nameInput="new_password"
          register={{
            ...register("new_password"),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="Current Password:"
          className="firstInput"
          wrapperClassName="maxWidthInput"
          hidePassword={hideCurrentPassword}
          setHidePassword={setHideCurrentPassword}
        />
      </ActionsWrapperStyled> */}
    </ProfileFormWrapper>
  );
};

export default ProfileForm;
