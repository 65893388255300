import React from "react";
import { Routes, Route } from "react-router-dom";
import { SignUp, SignIn, StartScreen, Domains, Notifications, Billing, AddDomain, AddDomains, DomainDetail, Profile } from "../../pages";

import { AuthRoute } from "./AuthRoute";
import { ROUTES } from "./routes";
import { PrivateRoutes } from "./PrivateRoutes";
import { getItemFromLocalStorage } from "../../utils/helpers";
import { RecoveryPassword, ResetPassword } from "../../pages";

const Router = (): JSX.Element => {
  const isLogin = getItemFromLocalStorage("token");
  return (
    <Routes>
      <Route element={<AuthRoute token={isLogin} />}>
        <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
        <Route path={ROUTES.LOGIN} element={<SignIn />} />
        <Route path={ROUTES.RECOVERY_PASSWORD} element={<RecoveryPassword />} />
        <Route path={ROUTES.RESTORE_PASSWORD} element={<ResetPassword />} />
        <Route path={ROUTES.HOME} element={<StartScreen />} />
      </Route>

      <Route element={<PrivateRoutes token={isLogin} />}>
        <Route path={ROUTES.DASHBOARD} element={<Domains />}>
          <Route path={ROUTES.ADD_DOMAIN} element={<AddDomain />} />
          <Route path={ROUTES.ADD_DOMAINS} element={<AddDomains />} />
          <Route path={ROUTES.DOMAIN_DETAIL} element={<DomainDetail />} />
        </Route>
        <Route path={ROUTES.PROFILE} element={<Profile />} />
        <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
        <Route path={ROUTES.BILLING} element={<Billing />} />
      </Route>
    </Routes>
  );
};
export default Router;
