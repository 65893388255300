import { styled } from "@mui/system";
import { COLORS } from "../../utils/constants/colors";

const styleOptions = {
  primary: {
    color: "white",
    backgroundColor: COLORS.MAIN_BLUE,
    border: `3px solid ${COLORS.MAIN_BLUE}`,
    borderWidth: "revert",

    "&:hover": {
      backgroundColor: COLORS.LIGHT_BLUE,
    },
    "&:active": {
      backgroundColor: COLORS.DARK_BLUE,
    },
    "&[disabled]": {
      backgroundColor: COLORS.MAIN_BLUE,
      opacity: "0.4",
    },
  },
  add: {
    color: COLORS.MAIN_BLUE,
    backgroundColor: "#ffffff00",
    padding: "8px 24px",
    border: "1px solid rgb(197 197 197)",
    fontWeight: "500",

    "&:hover": {
      backgroundColor: "#d6d6d6cf",
    },
    "&:active": {
      backgroundColor: "#d6d6d6cf",
    },
    "&[disabled]": {
      backgroundColor: "#d6d6d6cf",
      opacity: "0.4",
    },
  },
  secondary: {
    backgroundColor: "#ffffff00",
    color: COLORS.MAIN_BLUE,
    border: `3px solid ${COLORS.MAIN_BLUE}`,
    borderWidth: "revert",

    "&:hover": {
      color: COLORS.LIGHT_BLUE,
      border: `3px solid ${COLORS.LIGHT_BLUE}`,
      borderWidth: "revert",
    },
    "&:active": {
      color: COLORS.DARK_BLUE,
      border: `3px solid ${COLORS.DARK_BLUE}`,
      borderWidth: "revert",
    },
    "&[disabled]": {
      color: COLORS.MAIN_BLUE,
      border: `3px solid ${COLORS.MAIN_BLUE}`,
      borderWidth: "revert",
      opacity: "0.4",
    },
  },
  gray: {
    backgroundColor: "#E8E8E8",
    color: "#727272",

    "&:hover": {
      backgroundColor: "#DBDBDB",
    },
    "&:active": {
      backgroundColor: "#D4D4D4",
    },
    "&[disabled]": {
      backgroundColor: "#F4F4F4",
      opacity: "0.4",
    },
  },
  transparent: {
    backgroundColor: "transparent",
    color: "#727272",

    "&:hover": {
      backgroundColor: "#fafafa",
    },
    "&:active": {
      backgroundColor: "#D4D4D4",
    },
    "&[disabled]": {
      backgroundColor: "#F4F4F4",
      opacity: "0.4",
    },
  },
  simple: {
    backgroundColor: "#FFFFFF",
    fontWeight: 400,
    borderRadius: 7,
    color: "#3083FF",
    boxShadow: "0px 11px 20px 0px #ffffff4f",
    transition: "0.3s all ease",

    "&:hover": {
      // backgroundColor: "#78B9D8",
      transition: "0.3s all ease",
      fontWeight: 600,
      fontSize: 16,
    },
    "&:active": {
      // backgroundColor: "#69A0BB",
      transition: "0.3s all ease",
      fontWeight: 600,
    },
    "&[disabled]": {
      // backgroundColor: "#88CFF0",
      opacity: "0.4",
    },
  },
  delete: {
    backgroundColor: "#f07070",
    color: "white",
    borderColor: "#f07070",
    marginLeft: 10,


    "&:hover": {
      backgroundColor: "#f39e9e",
    },
    "&:active": {
      backgroundColor: "#f55a5a",
    },
    "&[disabled]": {
      backgroundColor: "#deb8b8",
      opacity: "0.4",
    },
  },
};
const styleOptionsSize = {
  small: {
    padding: "10px 26px",
    fontFamily: `"Inter", sans-serif`,
    // fontFamily: `'Sora', sans-serif `,
    fontSize: "15px",
    minWidth: "140px",
  },
  middle: {
    padding: "12px 34px",
    fontFamily: `"Inter", sans-serif`,
    // fontFamily: `'Sora', sans-serif `,
    fontSize: "16px",
    minWidth: "180px",
  },
  middle_add: {
    padding: "14px 22px",
    fontFamily: `"Inter", sans-serif`,
    // fontFamily: `'Sora', sans-serif `,
    fontSize: "16px",
  },
  large: {
    padding: "12px 34px",
    fontFamily: `"Inter", sans-serif`,
    // fontFamily: `'Sora', sans-serif `,
    fontSize: "16px",
    minWidth: "180px",
  },
};

export const ButtonStyled = styled("button")<{
  $fullwidth: boolean;
  $variant: "primary" | "secondary" | "simple" | "gray" | "add" | "transparent";
  $size: "small" | "middle" | "large" | "middle_add";
  $typeButton: "delete" | "none";
}>(({ theme, $variant, $fullwidth, $size, $typeButton }) => ({
  border: "none",
  padding: "12px 32px",
  whiteSpace: "nowrap",
  fontFamily: `"Inter", sans-serif`,
  // fontFamily: `'Sora', sans-serif `,
  fontWeight: 600,
  fontSize: "16px",
  textAlign: "center",
  borderRadius: "3px",
  cursor: "pointer",
  transition: "0.3s all ease",
  width: $fullwidth ? "100%" : "auto",

  ...(styleOptions[$variant] as React.CSSProperties),
  ...(styleOptionsSize[$size] as React.CSSProperties),
  ...($typeButton === "delete" ? styleOptions[$typeButton] as React.CSSProperties  : {}),
  // ...(styleOptions[$type] as React.CSSProperties),

  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    padding: "12px 20px",
  },
  [theme.breakpoints.down(1550)]: {
    padding: "8px 15px",
    minWidth: "120px",
    borderWidth: "2px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: $size === "middle" ? "12px" : "16px",
  },
}));
