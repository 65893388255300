import React from "react";
import wLetter from "../../../assets/images/icon/w-letter.png";
import { ButtonMain } from "../../../components";
import {
  ImportantBlockWrapper,
  ImportantQuestionWrapper,
  ParagraphWrapper,
  WLetterWrapperDesktop,
  WLetterWrapperMobile,
} from "../ui/styles/importantInfo.styled";
import { START_SCREEN } from "../../../utils/constants/start-screen";

export const ImportantInfoBlock = (): React.ReactElement => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <ImportantBlockWrapper>
      <ImportantQuestionWrapper>
        {START_SCREEN.W_TITLE.map((word: string) => {
          return <div key={word}>{word}</div>
        })}
        <WLetterWrapperDesktop>
          <img src={wLetter} style={{ width: 400 }} alt=""/>
        </WLetterWrapperDesktop>
        <WLetterWrapperMobile>
          <img src={wLetter} style={{ width: 180 }} alt=""/>
        </WLetterWrapperMobile>
      </ImportantQuestionWrapper>
      <ParagraphWrapper>
        {START_SCREEN.W_TEXT}
      </ParagraphWrapper>
      <ButtonMain title={START_SCREEN.MONITORING_BTN} variant="primary" size="large" />
    </ImportantBlockWrapper>
  </div>
);

export default ImportantInfoBlock;
