import React, { memo, useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { InputModel } from "../utils/inputs.model";
import {
  InputStyled,
  LabelStyled,
  CaptionStyled,
  FormControlStyled,
  InputWrapStyled,
  EyeContainer,
} from "../utils/styled";

const Input = ({
  isLabel = false,
  disabled = false,
  error = false,
  labelText = "",
  captionText,
  variant = "standard",
  nameInput = "input",
  register,
  type,
  hidePassword,
  setHidePassword,
  wrapperClassName,
  ...props
}: InputModel & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [inputType, setInputType] = useState<"number" | "text" | "email" | "password" | undefined>(type);

  useEffect(() => {
    if (type === 'password') {
      if (hidePassword) {
        setInputType("text");
      } else {
        setInputType(type);
      }
    }
  }, [type, hidePassword])

  return <FormControlStyled variant="standard" className={wrapperClassName ? wrapperClassName : ''}>
    {isLabel && (
      <LabelStyled htmlFor={nameInput} disabled={disabled}>
        {labelText}
      </LabelStyled>
    )}
    <InputWrapStyled>
      <InputStyled
        disabled={disabled}
        $islabel={isLabel}
        $typeSearch="standart"
        
        {...register}

        data-error={error}
        id={nameInput}
        $variant={variant}
        type={inputType}
        // autoComplete="on"
        {...props}
      />
      {type === "password" && setHidePassword && (
        <EyeContainer onClick={() => setHidePassword(!hidePassword)}>
          {hidePassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon /> }
        </EyeContainer>
      )}
    </InputWrapStyled>
    {captionText && (
      <CaptionStyled error={error} disabled={disabled}>
        {captionText}
      </CaptionStyled>
    )}
  </FormControlStyled>
};

export default memo(Input);
