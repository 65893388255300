import { styled } from "@mui/system";
import { COLORS } from "../../../utils/constants/colors";

export const FormGroupStyled = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  margin: 0,
  padding: 0,

  "*": {
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
  },

  input: {
    padding: 0,
    height: "initial",
    width: "initial",
    marginBottom: 0,
    display: "none",
    cursor: "pointer",
  },

  label: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },

  "label:before": {
    content: '""',
    backgroundColor: "transparent",
    border: `1px solid ${COLORS.LIGHT_GREEN}`,
    borderRadius: 3,
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05)",
    padding: "12px",
    display: "inline-block",
    position: "relative",
    verticalAlign: "middle",
    cursor: "pointer",
    marginRight: "10px",
  },

  "input:checked + label:after": {
    content: '""',
    display: "block",
    position: "absolute",
    top: "3px",
    left: "8px",
    width: "8px",
    height: "14px",
    border: `solid ${COLORS.LIGHT_GREEN}`,
    borderWidth: "0 2px 2px 0",
    transform: "rotate(45deg)",
  },
}));
