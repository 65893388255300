import { createAsyncThunk } from "@reduxjs/toolkit";
import domainsService from "./service";

export const getDomains = createAsyncThunk(
  "domains/getList",
  async (data: any) => {
    try {
      if (data.page) {
        const response: any = await domainsService.getDomains({domain_name: data.domain_name ?? '', page: data.page, showAll: data.showAll});
        return response?.data;
      } else {
        const response: any = await domainsService.getDomains({domain_name: data.domain_name ?? '', showAll: data.showAll});
        return response?.data;
      }
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const getDomainInfo = createAsyncThunk(
  "domains/getDomainInfo",
  async (data: string) => {
    try {
      const response = await domainsService.getDomainInfo({domain_name: data});
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const getDomainHistory = createAsyncThunk(
  "domains/getDomainHistory",
  async (data: any) => {
    try {
      const response = await domainsService.getDomainHistory(data);
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const getDomainFullInfo = createAsyncThunk(
  "domains/getDomainFullInfo",
  async (data: string) => {
    try {
      const response = await domainsService.getDomainFullInfo(data);
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const addDomain = createAsyncThunk(
  "domains/addDomain",
  async (data: any) => {
    try {
      const response = await domainsService.addDomain({domain_name: data});
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const addDomains = createAsyncThunk(
  "domains/addDomains",
  async (data: any) => {
    try {
      const response = await domainsService.addDomains(data);
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const addAdditionalEmails = createAsyncThunk(
  "domains/addAdditionalEmails",
  async (data: any) => {
    try {
      const response = await domainsService.addAdditionalEmails(data);
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const getAdditionalEmails = createAsyncThunk(
  "domains/getAdditionalEmails",
  async () => {
    try {
      const response = await domainsService.getAdditionalEmails();
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const deleteDomain = createAsyncThunk(
  "domains/deleteDomain",
  async (data: any) => {
    try {
      const response = await domainsService.deleteDomain(data);
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const editDomainName = createAsyncThunk(
    "domains/editDomainName",
    async (data: any) => {
        try {
            const response = await domainsService.editDomainName(data);
            return response.data;
        } catch (error: unknown) {
            // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
        }
    }
);

export const stopTrackingDomain = createAsyncThunk(
    "domains/stopTrackingDomain",
    async (data: any) => {
        try {
            const response = await domainsService.stopTrackingDomain(data);
            setTimeout(() => {
              window.location.reload();
            }, 3500)
            return response.data;
        } catch (error: unknown) {
            // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
        }
    }
);

export const startTrackingDomain = createAsyncThunk(
  "domains/startTrackingDomain",
  async (data: any) => {
      try {
          const response = await domainsService.startTrackingDomain(data);
          setTimeout(() => {
            window.location.reload();
          }, 3500)
          return response.data;
      } catch (error: unknown) {
          // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
      }
  }
);

export const checkAllDomains = createAsyncThunk(
  "domains/checkAll",
  async () => {
    try {
      const response = await domainsService.checkAllDomains();
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const checkDomain = createAsyncThunk(
  "domains/checkOne",
  async (data: any) => {
    try {
      const response = await domainsService.checkDomain(data);
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const clearDomainInfo = createAsyncThunk(
  "domains/clearDomainInfo",
  () => {
      domainsService.clearDomainInfo();
  }
);

export const exportAllDomains = createAsyncThunk(
  "domains/exportAll",
  async () => {
    try {
      const response = await domainsService.exportAllDomains({responseType: 'blob'});
      return response.data;
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);
