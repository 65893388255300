import { configureStore } from "@reduxjs/toolkit";

import userReducer from "../pages/user-profile/api/slice";
import domainsReducer from "../pages/domain-pages/api/slice";
import notificationsReducer from "../pages/notification-settings/api/slice";
import billingReducer from "../pages/billing/api/slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    domains: domainsReducer,
    notifications: notificationsReducer,
    billing: billingReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [`schedule`],
        ignoredActions: [`schedule/selectDay`],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
