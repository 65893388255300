import { AxiosResponse } from "axios";

import { ResponseModel } from "models/auth/auth.model";

import instance from "../../../service/api";

const getUserInfo = async (): Promise<AxiosResponse<ResponseModel>> =>
  await instance.get(`/api/v1/user`).then(response => response);

const updateUserInfo = async (data: any): Promise<AxiosResponse<ResponseModel>> =>
  await instance.put(`/api/v1/user/${data.id}`, data).then(response => response);

const changePassword = async (data: any): Promise<AxiosResponse<ResponseModel>> =>
  await instance.put(`/api/v1/user/${data.id}`, data).then(response => response);

const deleteUserProfile = async (id: any): Promise<AxiosResponse<ResponseModel>> =>
  await instance.delete(`/api/v1/user/${id}`).then(response => response);

const recoveryPassword = async (data: any): Promise<AxiosResponse<ResponseModel>> =>
  await instance.post(`/api/v1/password/email`, data).then(response => response);

const resetPassword = async (data: any): Promise<AxiosResponse<any>> =>
  await instance.post(`/api/v1/password/reset`, data).then(response => response);

const userService = {
  getUserInfo,
  updateUserInfo,
  changePassword,
  deleteUserProfile,
  recoveryPassword,
  resetPassword,
};

export default userService;
