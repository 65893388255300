import { AxiosResponse } from "axios";

import {
  AuthModel,
  ResponseModel,
} from "models/auth/auth.model";

import instance from "../../../service/api";

const signUp = async ({
  email, first_name, last_name, password, password_confirmation, domain_name, company
}: AuthModel): Promise<AxiosResponse<any>> =>
  await instance
    .post("/api/v1/register", {
      email, first_name, last_name, password, password_confirmation, domain_name, company
    })
    .then(response => response);

const login = async ({
  email,
  password,
// }: Partial<AuthModel>): Promise<AxiosResponse<AuthResponseModel>> =>
}: Partial<AuthModel>): Promise<AxiosResponse<any>> =>
  await instance
    .post("/api/v1/login", {
      email, password
    }
    )
    .then(response => response);

const logout = async (value: string): Promise<AxiosResponse<ResponseModel>> =>
  await instance
    // .post(`/api/v1/logout`, JSON.stringify({api_token: value}))
    // .post(`/api/v1/logout?api_token=${encodeURIComponent(value)}`)
    .post(`/api/v1/logout`)
    .then(response => response);

// const passwordRecovery = async (email: string): Promise<AxiosResponse<ResponseModel>> =>
//   await instance
//     .post(API_URI + QUERY_KEY.PSSWORD_RECOVERY + `email=${encodeURIComponent(email)}`)
//     .then(response => response);

// const passwordRestore = async ({
//   token,
//   password,
//   password_confirm,
// }: Record<string, string>): Promise<AxiosResponse<AuthResponseModel>> => {
//   const searchParams = `token=${token}&password=${password}&password_confirm=${password_confirm}`;

//   return await instance
//     .post(API_URI + QUERY_KEY.PSSWORD_RESTORE + searchParams)
//     .then(response => response);
// };

// const verifUser = async (
//   verifyToken: string,
//   is_auto_login: number
// ): Promise<AxiosResponse<ResponseModel>> =>
//   await instance
//     .post(
//       API_URI +
//         QUERY_KEY.VERIFY_USER +
//         `?verify_token=${verifyToken}&is_auto_login=${is_auto_login}`
//     )
//     .then(response => response);

const authService = {
  signUp,
  login,
  logout,
  // passwordRecovery,
  // passwordRestore,
  // verifUser,
};

export default authService;
