import React, { memo } from "react";

import { FormGroupStyled } from "./styled";

interface Props {
  label: string;
  variant?: "primary" | "secondary" | "simple" | "gray" | "add" | "transparent";
  size?: "small" | "middle" | "large" | "middle_add";
  fullwidth?: boolean;
}

const Checkbox = ({
  label,
  variant = "primary",
  size = "middle",
  fullwidth = false,
  ...props
}: Props): JSX.Element => (
  // <div className="form-group">
  //   <input type="checkbox" id="checkbox" />
  //   <label htmlFor="checkbox">{label}</label>
  // </div>
  <FormGroupStyled>
    <input type="checkbox" id="checkbox" />
    <label htmlFor="checkbox">{label}</label>
  </FormGroupStyled>
);

export default memo(Checkbox);
