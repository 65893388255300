import { styled } from "@mui/system";

export const DomainsContainer = styled("div")(({ theme }) => ({
  padding: 50,
  minHeight: 400,

  [theme.breakpoints.down(1550)]: {
    padding: 20,
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "40px",

  [theme.breakpoints.down("md")]: {
  },
}));
