import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import {
  Box,
  CircularProgress,
  Collapse,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { IconWrapper } from "../ui/styles/domainsList.styled";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { COLORS } from "../../../../utils/constants/colors";
import { ReactComponent as CopyIcon } from "../../../../assets/images/icon/copy.svg";
import { getFormattedDateTime } from "../../../../utils/helpers";
import { CopyContainer, CopyWrapper, KeyWrapper, TabContentWrapper } from "../ui/styles/styled";

const Row = ({row}: any) => {
  const [keyShow, setKeyShow] = useState<boolean | undefined>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [copiedEncoded, setCopiedEncoded] = useState<boolean>(false);
  const [copiedDecoded, setCopiedDecoded] = useState<boolean>(false);
  const hostname = 'warning';
  const progressLoading = false;

  const onHandleChangeTab = (ev: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const unsecuredCopyToClipboard = (text: any) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};

  const copyKey = (keyName: string) => {
    let key_ = keyName === "encodedKey" ? row?.ssl_encoded : row?.ssl_decoded;

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(key_);
      setTimeout(() => {
        if (keyName === "encodedKey") {
          setCopiedEncoded(true);
          setCopiedDecoded(false);
        } else {
          setCopiedEncoded(false);
          setCopiedDecoded(true);
        }
      }, 200);
    } else {
      unsecuredCopyToClipboard(key_);
      setTimeout(() => {
        if (keyName === "encodedKey") {
          setCopiedEncoded(true);
          setCopiedDecoded(false);
        } else {
          setCopiedEncoded(false);
          setCopiedDecoded(true);
        }
      }, 200);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return <><TableRow key={row.firstSeenDate}>
              <TableCell style={{ borderBottom: "none" }}>{row.firstSeenDate}</TableCell>
              <TableCell style={{ borderBottom: "none" }}>{row.issuer}</TableCell>
              <TableCell style={{ borderBottom: "none" }}>{row.validFromDate}</TableCell>
              <TableCell style={{ borderBottom: "none" }}>{row.validToDate}</TableCell>
              <TableCell style={{ borderBottom: "none" }} align="center">
                {progressLoading ? (
                  <CircularProgress />
                ) : (
                  <IconWrapper $color={row.hostname}>
                    {row.hostname === "warning" ? (
                      <PriorityHighRoundedIcon />
                    ) : row.hostname === "error" ? (
                      <CloseRoundedIcon />
                    ) : (
                      <DoneRoundedIcon />
                    )}
                  </IconWrapper>
                )}
              </TableCell>
              <TableCell
                style={{
                  borderBottom: "none",
                  cursor: "pointer",
                  color: COLORS.MAIN_BLUE,
                  width: "118px",
                }}
                align="center"
                onClick={() => setKeyShow(!keyShow)}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <span>{keyShow ? "Hide" : "Show"}</span>
                  <ArrowDownwardRoundedIcon
                    style={{ transform: keyShow ? "rotate(180deg)" : "initial", width: 18 }}
                  />
                </div>
              </TableCell>
            </TableRow>

            {keyShow && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Collapse in={keyShow} timeout="auto" unmountOnExit>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={selectedTab}
                onChange={onHandleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Decoded" {...a11yProps(0)} />
                <Tab label="Encoded" {...a11yProps(1)} />
              </Tabs>
            </Box>
            {selectedTab === 0 && (
              <TabContentWrapper>
                <KeyWrapper><pre>{`${row?.decoded ?? ''}`}</pre></KeyWrapper>
                <CopyContainer>
                  <CopyWrapper onClick={() => copyKey("decodedKey")}>
                    <CopyIcon />
                    {copiedDecoded ? "Copied!" : "Copy"}
                  </CopyWrapper>
                </CopyContainer>
              </TabContentWrapper>
            )}
            {selectedTab === 1 && (
              <TabContentWrapper>
                <KeyWrapper><pre>{row?.encoded ?? ''}</pre></KeyWrapper>
                <CopyContainer>
                  <CopyWrapper onClick={() => copyKey("encodedKey")}>
                    <CopyIcon />
                    {copiedEncoded ? "Copied!" : "Copy"}
                  </CopyWrapper>
                </CopyContainer>
              </TabContentWrapper>
            )}
          </Box>
          </Collapse>
          </TableCell>
          </TableRow>
        )}
            </>
}

export const DomainsList = ({
  setKeyShow,
  keyShow,
  domainInfo,
  progressLoading,
  hostname,
  domainChecks,
  currentPage,
  onChangePage,
}: {
  setKeyShow: React.Dispatch<React.SetStateAction<boolean>>;
  keyShow: boolean;
  progressLoading: boolean;
  domainInfo: any;
  domainChecks: any;
  hostname: "error" | "warning" | "success";
  currentPage: any;
  onChangePage: any;
}): React.ReactElement => {

  const [rows_, setRows] = React.useState<any>([]);
  function createData(
    firstSeenDate: string,
    issuer: string,
    validFromDate: string,
    validToDate: string,
    hostname: "error" | "warning" | "success",
    show: string,
    decoded: string | null,
    encoded: string | null,
  ) {
    return { firstSeenDate, issuer, validFromDate, validToDate, hostname, show, decoded, encoded };
  }

  const rows: any = [
    // createData(
    //   getFormattedDateTime(domainInfo.first_seen) ?? "-",
    //   domainInfo.issuer ?? "-",
    //   domainInfo.issued_at,
    //   domainInfo.expiration,
    //   "success",
    //   "Show"
    // ),
  ];

  useEffect(() => {
    if (domainChecks?.data?.length > 0) {
      domainChecks?.data?.map((item: any) => {
        let hostname: "error" | "warning" | "success" = "error";
        if (item.expiration_days  && item.expiration_days > 5) {
          hostname = "success";
        } else if ((item.expiration_days <= 5 && item.expiration_days >= 0) || !item.expiration_days ) {
          hostname = "warning";
        } else {
          hostname = "error";
        }
        return rows.push(createData(
          getFormattedDateTime(item.first_seen) ?? "-",
          item.issuer,
          item.issued_at,
          item.expiration,
          hostname,
          'Show',
          item.ssl_decoded,
          item.ssl_encoded,
        ))
      })
      
      setRows(rows);
    }
  }, [domainChecks])

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 700 }}>Date First Seen</TableCell>
            <TableCell style={{ fontWeight: 700 }}>Issuer</TableCell>
            <TableCell style={{ fontWeight: 700 }}>Valid From</TableCell>
            <TableCell style={{ fontWeight: 700 }}>Valid To</TableCell>
            <TableCell style={{ fontWeight: 700 }} align="center">
              Hostname
            </TableCell>
            <TableCell style={{ fontWeight: 700 }} align="center">
              Show
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows_.map((row: any, i: number) => (
             <Row row={row} key={`${row.firstSeenDate}-${i}`}/>
          ))}
        </TableBody>
      </Table>
      <Pagination count={Math.ceil(domainChecks.total / 5)} variant="outlined" style={{marginTop: 20}} page={currentPage} onChange={onChangePage} />
    </TableContainer>
  );
};

export default DomainsList;
