import React, { useEffect, useState } from "react";

import { ReactComponent as DomainsIcon } from "../../../assets/images/icon/domains.svg";
import { ReactComponent as NotificationsIcon } from "../../../assets/images/icon/notifications.svg";
import { ReactComponent as BillingIcon } from "../../../assets/images/icon/billing.svg";
import { ReactComponent as EditProfile } from "../../../assets/images/icon/edit.svg";
import { ReactComponent as LogOut } from "../../../assets/images/icon/sign-out.svg";

import {
  DecorativeNavElement,
  NavlinkWrapper,
  SidebarContainerStyled,
} from "../utils/styles/sidebar.styled";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../pages/router/routes";
import { useLocation } from "react-router-dom";

interface ILink {
  label: string;
  id: string;
  icon: React.ReactElement;
}

interface Props {
  openedAllMenu?: boolean;
  setOpenedAllMenu?: any;
}

export const Sidebar = ({ openedAllMenu, setOpenedAllMenu }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const history = useLocation();

  const [selectedNavLink, setSelectedNavLink] = useState<string>("");

  const navLinks: Array<ILink> = [
    { label: "Domains", id: "domains", icon: <DomainsIcon /> },
    { label: "Notifications", id: "notifications", icon: <NotificationsIcon /> },
    { label: "Billing", id: "billing", icon: <BillingIcon /> },
  ];

  const userNavLinks: Array<ILink> = [
    { label: "Edit Account", id: "profile", icon: <EditProfile /> },
    { label: "Sign Out", id: "logout", icon: <LogOut /> },
  ];

  const navigateToNavLink = (linkId: string) => {
    if (linkId !== selectedNavLink) {
      setSelectedNavLink(linkId);
    }
    setOpenedAllMenu && setOpenedAllMenu(!openedAllMenu);
  };

  useEffect(() => {
    if (selectedNavLink === "notifications") {
      navigate(ROUTES.NOTIFICATIONS)
    } else if (selectedNavLink === "billing") {
      navigate(ROUTES.BILLING)
    } else if (selectedNavLink === "domains") {
      navigate(ROUTES.DASHBOARD)
    } else if (selectedNavLink === "profile") {
      navigate(ROUTES.PROFILE)
    }
  }, [selectedNavLink])

  useEffect(() => {
    if (history) {
      setSelectedNavLink(history?.pathname?.replace(/[^\w ]/g, ''))
    }
  }, [history])

  const isSelected = (linkId: string) => {
    if (linkId === "domains") {
      if (selectedNavLink.includes("domains")) {
        return true;
      } else {
        return false
      }
    } else {
      if (selectedNavLink === linkId) {
        return true;
      }
    }
  }

  return (
    <SidebarContainerStyled className={`${openedAllMenu ? "active" : ""}`}>
      {navLinks.map((link: ILink) => {
        return (
          <NavlinkWrapper
            key={link.id}
            className={`${isSelected(link.id) ? "navlinkActive" : ""}`}
            onClick={() => {
              navigateToNavLink(link.id);
            }}
          >
            {link.icon}
            {link.label}
            <DecorativeNavElement className="decorativeNavElement" />
          </NavlinkWrapper>
        );
      })}

      {userNavLinks.map((link: ILink, i: number) => {
        return (
          <NavlinkWrapper
            key={link.id}
            className={`${isSelected(link.id) ? "navlinkActive" : ""} mobileLink mobileLink-${i}`}
            onClick={() => {
              navigateToNavLink(link.id);
            }}
          >
            {link.icon}
            {link.label}
            {i === 0 && <hr />}
            <DecorativeNavElement className="decorativeNavElement" />
          </NavlinkWrapper>
        );
      })}
    </SidebarContainerStyled>
  );
};

export default React.memo(Sidebar);
