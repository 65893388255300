import { styled } from "@mui/system";
import { COLORS } from "utils/constants/colors";

export const ExpireContainerStyled = styled("div")(({ theme }) => ({
  width: 650,
  backgroundColor: "white",
  border: `4px solid ${COLORS.MAIN_GREEN}`,
  borderRadius: 4,
  marginBottom: 10,
  boxSizing: "border-box",

  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

export const ExpireTitleStyled = styled("div")(({ theme }) => ({
  padding: "16px 35px",
  fontSize: 28,
  fontWeight: 800,

  [theme.breakpoints.down("sm")]: {
    fontSize: 20,
    padding: "16px 15px",
  },
}));

export const ExpireDomainInfoStyled = styled("div")(({ theme }) => ({
  padding: "16px 35px",

  [theme.breakpoints.down("sm")]: {
    padding: "16px 15px",
  },
}));

export const ExpireInfoRowStyled = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: 20,
  width: "100%",

  ":nth-last-child(1)": {
    marginBottom: 0,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const ExpireDashedContainerStyled = styled("div")(({ theme }) => ({
  display: "flex",
  minWidth: "350px",
  marginRight: 10,
}));

export const ExpireDashedOptionStyled = styled("div")(({ theme }) => ({
  whiteSpace: "nowrap",
  marginRight: 10,
  color: COLORS.DARK_GRAY,
  fontSize: 16,
  fontWeight: 400,
}));

export const ExpireDashedLineStyled = styled("div")(({ theme }) => ({
  width: "100%",
  borderBottom: `1px dashed ${COLORS.LIGHT_GRAY}`,
  marginBottom: 4,

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const ExpireCompanyStyled = styled("div")(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  backgroundColor: COLORS.MAIN_GREEN,
  display: "flex",
  alignItems: "center",
  padding: "12px 35px",

  "span": {
    fontSize: 24,
    fontWeight: 700,
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: 20,
    padding: "16px 15px",
  },
}));
