import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ButtonMain } from "../../../components";
import { useForm } from "react-hook-form";
import { Modal } from "@mui/material";
import { ModalWrapper } from "../../../pages/user-profile/ui/styles/modal.styled";
import { ButtonWrapper } from "../../../pages/domain-pages/domains-list/ui/styles/styled";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { COLORS } from "../../../utils/constants/colors";
import Input from "../../../components/inputs/input/Input";
import axios from "axios";
import { getAuthToken } from "utils/helpers";
import { toast } from "react-toastify";
import { DOMAIN_URI } from "utils/constants/api";

const BillingModal = ({
  openModal,
  setOpenModal,
  title,
}: {
  openModal: boolean;
  setOpenModal: any;
  title: string;
}): React.ReactElement => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>("");
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (openModal) {
      reset();
      setError("");
      setSuccess(false);
      setLoading(false);
    }
  }, [openModal, reset]);

  const onSubmit = async (data: any) => {
    if (!elements || !stripe) {
      return;
    }
    setLoading(true);
    setError("");
    setSuccess(false);

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("Card element not found");
      setLoading(false);
      return;
    }

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: data.name,
          email: data.email,
          phone: data.phone,
          address: {
            line1: data.address,
          },
        },
      });

      if (error) {
        setError(error.message);
        setLoading(false);
        setOpenModal(false);
        // return;
      }

      if (paymentMethod) {
        const response = await axios.post('/api/v1/subscription', {
          payment_method_id: paymentMethod.id,
        }, {
          baseURL: DOMAIN_URI,
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${getAuthToken()}`,
          }
        });

        if (response.data.error) {
          setOpenModal(false);
          setError(response.data.error);
          setLoading(false);
        } else {
          setOpenModal(false);
          setSuccess(true);
          setLoading(false);
        }
      }
    } catch (error) {
      toast.error('An unexpected error occurred while creating the subscription. Please try again later.')
      setOpenModal(false);
      console.error('Error creating subscription:', error);
      // setError(error ?? 'Something went wrong');
      setLoading(false);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 15, border: `1px solid ${COLORS.MAIN_BLUE}`, padding: '12px 15px' }}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "17.3px",
                    fontStyle: 'italic',
                    color: COLORS.MAIN_BLUE,
                    height: '44px !important',
                    padding: '2px 16px 2px 16px !important',
                    border: '1px solid #224AFF !important',
                  },
                },
                billingDetails: {
                  name: "cardholderName",
                },
              } as any}
            />
          </div>

          <Input
            type="text"
            nameInput="name"
            register={{
              ...register("name", { required: true }),
            }}
            isLabel={true}
            labelText="Cardholder Name:"
            className="firstInput"
            wrapperClassName="maxWidthInput marginInput"
            style={{ marginBottom: 15 }}
          />

          <Input
            type="text"
            nameInput="email"
            register={{
              ...register("email", { required: true }),
            }}
            isLabel={true}
            labelText="Email:"
            className="firstInput"
            wrapperClassName="maxWidthInput marginInput"
            style={{ marginBottom: 15 }}
          />

          <Input
            type="text"
            nameInput="phone"
            register={{
              ...register("phone", { required: true }),
            }}
            isLabel={true}
            labelText="Phone:"
            className="firstInput"
            wrapperClassName="maxWidthInput marginInput"
            style={{ marginBottom: 15 }}
          />

          <Input
            type="text"
            nameInput="address"
            register={{
              ...register("address", { required: true }),
            }}
            isLabel={true}
            labelText="Address:"
            className="firstInput"
            wrapperClassName="maxWidthInput marginInput"
          />

          <ButtonWrapper>
            <ButtonMain
              title="Cancel"
              variant="secondary"
              size="small"
              onClick={() => setOpenModal(false)}
              style={{ marginRight: 10 }}
            />
            <ButtonMain
              title={title}
              variant="primary"
              size="small"
              type="submit"
              disabled={!stripe || loading}
            />
          </ButtonWrapper>

          {error && <div style={{ color: 'red' }}>{error}</div>}
          {success && <div style={{ color: 'green' }}>Subscription created successfully!</div>}
        </form>
      </ModalWrapper>
    </Modal>
  );
};

export default BillingModal;
