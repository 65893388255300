import { styled } from "@mui/system";
import { COLORS } from "../../../../utils/constants/colors";

export const NavbarContainer = styled("div")(({ theme }) => ({
  height: "100%",
  overflowY: 'scroll',
  overflowX: 'hidden',

  [theme.breakpoints.down(1300)]: {
    overflowX: 'scroll',
  },
  [theme.breakpoints.down("sm")]: {
    background: 'linear-gradient(144deg, rgba(255,209,239,1) 0%, rgba(245,250,195,1) 94%)',
  },
}));

export const BodyWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
}));

export const SignUpWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
}));

export const ContentContainer = styled("div")(({ theme }) => ({
  // height: "100%",
  // overflowY: "auto",
  // scrollbarGutter: "stable",
  // height: "min-content",
  width: "100%",
  maxWidth: "100%",
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  // justifyContent: "space-between",
  background: COLORS.MAIN_GRADIENT,
  padding: "40px 60px",
  minHeight: "100vh",
  height: "max-content",
  marginTop: '80.5px',
  marginLeft: '300px',
  position: 'relative',
  // display: 'block',
  backgroundColor: 'yellow',
  // border: '10px solid red',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.down("sm")]: {
    padding: 15,
    maxWidth: "-webkit-fill-available",
  },

  "&::-webkit-scrollbar": {
    width: "0px !important",
  },
}));

export const ContentWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: '100%',
  backgroundColor: "white",
  borderRadius: 4,
  height: "min-content",
}));

