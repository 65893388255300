import { createTheme } from "@mui/material/styles";
import { ExtendedThemeOptions } from "./theme-types";

export const COLORS = {
  LIGHT_BLUE: "#88CFF0",
  OCEAN_BLUE: "#7CBCDB",
  DARK: "rgba(29, 79, 102, 0.32)",
  ERROR: "#F02E1B",
  LIGHT_GRAY: "#989898",
  WHITE: "#FFFFFF",
  MILK: "rgba(255, 255, 255, 0.92)",
  TRANSPARENT_GRAY: "rgba(0, 0, 0, 0.48)",
  DARK_GRAY: "#082634",
  PURPLE: "#bb86fc",
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 394,
      sm: 451,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),

    inter_300_88px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "300",
      fontSize: "88px",
      lineHeight: "93px",
    },
    inter_400_16px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "20px",
    },
    inter_500_12px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "16px",
    },
    inter_500_20px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "23px",
    },
    inter_500_36px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "500",
      fontSize: "36px",
      lineHeight: "40px",
    },
    inter_600_24px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "28px",
    },
    inter_700_12px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "18px",
    },
    inter_700_16px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "20px",
    },
    inter_700_52px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "700",
      fontSize: "52px",
      lineHeight: "54px",
    },
    inter_700_124px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "700",
      fontSize: "124px",
      lineHeight: "130px",
    },
    inter_900_20px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "900",
      fontSize: "20px",
      lineHeight: "22px",
    },
    inter_900_36px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "900",
      fontSize: "36px",
      lineHeight: "40px",
    },
    inter_900_42px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "900",
      fontSize: "42px",
      lineHeight: "44px",
    },
    inter_900_72px: {
      fontFamily: `'Inter', sans-serif `,
      fontWeight: "900",
      fontSize: "72px",
      lineHeight: "76px",
    },
  },
} as ExtendedThemeOptions);

export default theme;
