import { styled } from "@mui/system";
import { ExtendedThemeOptions } from "../../../../assets/theme-types";

export const FooterWrapper = styled("div")(({ theme }) => ({
  padding: "30px 100px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  ...(theme.typography as ExtendedThemeOptions["typography"]).inter_400_16px,

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap-reverse",
    padding: "5px 10px",
  },

  "&.noPadding": {
    marginTop: 35,
    padding: "0px !important",
  }
}));

export const FooterNavItem = styled("div")<{$name: string}>(({ theme, $name }) => ({
  paddingRight: "10px",
  borderRight: "1px solid black",
  paddingLeft: "10px",
  cursor: "pointer",
  whiteSpace: "nowrap",

  [theme.breakpoints.down("sm")]: {
    paddingRight: $name === "sign-in" ? "0px" : "10px",
    borderRight: $name === "sign-in" ? "none" : "1px solid black",
  },

  [theme.breakpoints.down("xs")]: {
    paddingRight: $name === "sign-up" ? "0px" : "10px",
    borderRight: $name === "sign-up" ? "none" : "1px solid black",
  },
}));

export const NavFooterMenu = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    justifyContent: "center",
    marginBottom: 10,
    width: "100%",
  },
}));

export const FooterCopyright = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "inline-block",
    margin: "0 auto",
  },
}));
