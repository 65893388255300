import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../../utils/hooks/global";
import {getDomains, checkAllDomains, exportAllDomains,} from "../../api/async";
import { getAuthToken, getUserId, createDomainData } from "../../../../utils/helpers";
import { API_V1, DOMAIN_URI } from "../../../../utils/constants/api";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const useDomainsList = () => {
    const { register, handleSubmit } = useForm();
    const dispatch = useAppDispatch();
    const domainsStore = useAppSelector(store => store.domains);
    const location = useLocation();
  
    const [showChild, setShowChild] = useState<boolean>(false);
    const [domains, setDomains] = useState<[]>([]);
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [domainsInProgress, setDomainsInProgress] = useState<[]>([]);
    const [domainsForChecking, setDomainsForChecking] = useState<[]>([]);
    const [quantityInProgress, setQuantityInProgress] = useState<any>(null);

    const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    };
  
    useEffect(() => {
      if (
        location.pathname.includes("/domains/add-domain") ||
        location.pathname.includes("/domains/add-domains") ||
        location.pathname.includes("/domains/domain") ||
        location.pathname.includes("/profile")
      ) {
        setShowChild(true);
      } else {
        setShowChild(false);
      }
  
      if (location?.state?.neededRefreshPage) {
        window.location.reload();
      }
    }, [location]);

    // useEffect(() => {
    //   const unlisten = history.listen((location) => {
    //     // Если URL не содержит хэш /domains, прекращаем слушать изменения маршрута
    //     if (!location.hash.includes('/domains')) {
    //       unlisten();
    //     }
    //   });
  
    //   return () => {
    //     unlisten();
    //   };
    // }, [history]);
  
    useEffect(() => {
      const showAllDomains = localStorage.getItem('showAll');

      if (showAllDomains === 'show') {
        setShowAll(true)
      } else if (showAllDomains === 'hide') {
        setShowAll(false);
      }
      const isPageRefreshed = localStorage.getItem('isPageRefreshed');

      if (isPageRefreshed) {
        localStorage.removeItem('isPageRefreshed')
        toast.success("Domains have been successfully checked", {
          autoClose: false,
        });
      }
      dispatch(getDomains({domain_name: "", page, showAll: showAllDomains === 'show'}));
    }, []);
  
    useEffect(() => {
      handleConnect(domainsForChecking);
    }, [domainsForChecking]);
  
    useEffect(() => {
      setLoading(domainsStore.isFetching);
  
      if (domainsStore?.domainsList?.length >= 0) {
        localStorage.setItem('domainsCount', domainsStore?.totalCount);
        setDomainsForChecking(
          domainsStore.domainsList
            .filter((domainInfo: any) => domainInfo.is_check_queued)
            .map((domain: any) => domain.id)
        );
        const domainsList = domainsStore.domainsList.map((domain: any) => {
          let hostname: "error" | "warning" | "success" = "error";
          if (domain.expiration_days > 5) {
            hostname = "success";
          } else if (domain.expiration_days <= 5 && domain.expiration_days >= 0) {
            hostname = "warning";
          } else {
            hostname = "error";
          }
  
          return createDomainData(
            domain.name,
            domain.expiration_days ?? "-",
            domain.issuer ?? "-",
            domain.expiration,
            domain.checked_at,
            hostname,
            domain.id,
            domain.is_tracking,
          );
        });
  
        setDomains(domainsList);
      }
    }, [domainsStore]);
  
    const onHandleSearch = (data: any) => {
      dispatch(getDomains({domain_name: data.search_domain, page}));
    };

    useEffect(() => {
      dispatch(getDomains({domain_name: "", page, showAll }));
    }, [page, showAll])
  
    const onHandleCheckAllDomains = () => {
      dispatch(checkAllDomains()).then(res => {
        if (res.type === "domains/checkAll/fulfilled") {
          dispatch(getDomains(""));
        }
      });
    };
  
    const onHandleExportAll = () => {
      dispatch(exportAllDomains());
    };
  
    const handleConnect = (domainsForCheck: any) => {
      const headers: any = { headers: { Authorization: `Bearer ${getAuthToken()}` } };
      const url = new URL(
        `${DOMAIN_URI}${API_V1}domains-checking-status/${getUserId()}?domains_ids=${domainsForCheck.toString()}`
      );
  
      const eventSource: any = new EventSource(url, headers);
  
      eventSource.onmessage = (event: any) => {
        const newData = JSON.parse(event.data);
        if (!newData.length) {
          eventSource.close();
        }
        // setDomainsInProgress(newData.domains ?? []);
        if (newData.quantity_in_progress === 0 && quantityInProgress !== 0) {
          window.location.reload();
        }
        // setQuantityInProgress(newData.quantity_in_progress ?? null);
      };
      eventSource.onerror = (event: any) => {
        console.error("EventSource error::::::", event);
        eventSource.close();
      };
    };

    useEffect(() => {
      if (domainsStore.checkingInProcess === 'no') {
        localStorage.setItem('isPageRefreshed', 'true');
        window.location.reload();
      }
    }, [domainsStore.checkingInProcess])

    const onHandleClick = handleSubmit(onHandleSearch);

    const showOrHideUntrackedDomains = () => {
      const showAllDomains = localStorage.getItem('showAll');

      if (showAllDomains && showAllDomains === 'show') {
        localStorage.setItem('showAll', 'hide');
      } else if ((showAllDomains && showAllDomains === 'hide') || !showAllDomains) {
        localStorage.setItem('showAll', 'show');
      }

      setShowAll(!showAll)
    }

  return {
    showChild,
    onHandleClick,
    register,
    onHandleCheckAllDomains,
    domains,
    quantityInProgress,
    loading,
    domainsInProgress,
    onHandleExportAll,
    onChangePage,
    page,
    totalCount: domainsStore.totalCount,
    checkingInProgress: domainsStore.checkingInProcess,
    showOrHideUntrackedDomains,
    showAll,
  };
};