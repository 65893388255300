import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@emotion/react";
import 'react-toastify/dist/ReactToastify.css';

import Router from "./pages/router/Router";
import theme from "./assets/theme";
import { store } from "./store/store";

import "./assets/styles/index.scss";
import "react-toastify/dist/ReactToastify.css";

export const App = (): React.ReactElement => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <ToastContainer autoClose={4500} hideProgressBar={true}/>
    </ThemeProvider>
  </Provider>
);

export default App;
