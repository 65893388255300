import React from "react";

import { ButtonMain } from "../../../components";
import { DomainsContainer, ButtonWrapper, PrevValuesListWrapper, InputValueWrapper  } from "./ui/styles/styled";
import InputDomains from "../../../components/inputs/input-domains_/InputDomains";
import { COLORS } from "../../../utils/constants/colors";
import { useDomainsCreate } from "./utils/hook";
import { TitleWrapper, TitleWrapperStyled } from "../../../components/titles/utils/styled";
import DeleteIcon from '@mui/icons-material/Delete';

export const Domains = (): React.ReactElement => {
  const {
    register,
    loading,
    navigate,
    onDeletePrevValue,
    setValuesList,
    valuesList,
    setDomainError,
    domainError,
    onHandleAddDomains,

  } = useDomainsCreate();

  return (
    <DomainsContainer>
    <TitleWrapperStyled>Track More Domains</TitleWrapperStyled>
    <TitleWrapper style={{ marginTop: 20 }}>Domains</TitleWrapper>
    <InputDomains
      isLabel={false}
      labelText="DOMAINS (Press Enter or Space for next domain):"
      nameInput="search"
      type="search"
      variant="search"
      typeSearch="absolute"
      register={{ ...register("search") }}
      setFocusedDomainInput={() => null}
      setValuesList={setValuesList}
      valuesList={valuesList}
      setEmailError={setDomainError}
      emailError={domainError}
      regex={/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
    />
      {domainError && <div style={{
          marginTop: 5,
          fontWeight: 'bold',
          color: '#f07070'
      }}>
          Invalid domain format
      </div>}
    <div style={{ color: COLORS.MAIN_GRAY, fontStyle: "italic", marginTop: 10 }}>
      Please note that your account has a limit of 2 domains, and you're currently tracking 0.
      Your request will not be fulfilled should it contain domains that will take you over that
      limit.
    </div>

    <PrevValuesListWrapper>
          {valuesList && valuesList.map((prevDomain: string) => {
            return <InputValueWrapper key={prevDomain}>{prevDomain} <DeleteIcon onClick={() => onDeletePrevValue(prevDomain)}/></InputValueWrapper>;
          })}
    </PrevValuesListWrapper>

    <ButtonWrapper>
      <ButtonMain variant="secondary" title="Back" size="small" onClick={() => navigate(-1)} />
      <ButtonMain variant="primary" title="Add Domains" size="small" onClick={onHandleAddDomains} disabled={loading}/>
    </ButtonWrapper>
  </DomainsContainer>
  );
};

export default React.memo(Domains);
