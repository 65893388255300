import { ButtonMain } from "../../../../components";
import Input from "../../../../components/inputs/input/Input";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  FormContainerStyled,
  FormTitleStyled,
  InputsWrapperStyled,
} from "../utils/styles/form.styled";

export const PasswordResetForm = ({
  handleSubmitToSignUp,
}: {
  handleSubmitToSignUp: any;
}): React.ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  }: any = useForm();
  const [hidePassword, setHidePassword] = useState<boolean>(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(false);

  return (
    <FormContainerStyled className="formContainer" onSubmit={handleSubmit(handleSubmitToSignUp)}>
      <FormTitleStyled style={{ textAlign: "center", fontWeight: 800, fontSize: 24 }}>
        Set New Password
      </FormTitleStyled>

      <InputsWrapperStyled>
        <Input
          type="password"
          nameInput="password"
          register={{
            ...register("password"),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="Password:"
          className="firstInput"
          hidePassword={hidePassword}
          setHidePassword={setHidePassword}
        />
        <Input
          type="password"
          nameInput="password_confirm"
          register={{
            ...register("password_confirm", {
              validate: (val: string) => {
                if (watch("password") !== val) {
                  return "Your passwords do no match";
                }
              },
            }),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="Confirm Password:"
          hidePassword={hideConfirmPassword}
          setHidePassword={setHideConfirmPassword}
        />
      </InputsWrapperStyled>

      <ButtonMain title={"SET NEW PASSWORD"} onClick={() => null} variant="primary" />
    </FormContainerStyled>
  );
};

export default React.memo(PasswordResetForm);
