import { styled } from "@mui/system";
// import { ReactComponent as Logo } from "assets/images/main-logo.svg";
import { ReactComponent as Logo } from "../../assets/images/main-logo.svg";

const styleOptionsSize = {
  small: {
    width: "200px",
  },
  middle: {
    padding: "12px 32px",
  },
  large: {
    padding: "12px 32px",
  },
} as any;

export const LogoStyled = styled(Logo)<{
  $size: "small" | "middle" | "large";
}>(({ theme, $size }) => ({
  width: "800px",

  ...(styleOptionsSize[$size] as React.CSSProperties),

  [theme.breakpoints.down("lg")]: {
  },
  [theme.breakpoints.down("md")]: {
    width: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    width: $size === "small" ? "180px": "300px",
  },
}));
