import { styled } from "@mui/system";
import { ExtendedThemeOptions } from "assets/theme-types";
import { COLORS } from "../../../../utils/constants/colors";

export const HeaderContainerStyled = styled("div")(({ theme }) => ({
  boxSizing: 'border-box',
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 40,
  height: '60px',
  borderBottom: "1px solid #80808030",
  position: 'fixed',
  backgroundColor: '#fafffa',
  width: '100%',
  zIndex: 100,

  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    borderBottom: "none",
    height: "70px",
    padding: "0px 20px",

    "&.openedHeaderMenu": {
      backgroundColor: "white",
      borderBottom: "1px solid #8080802e",
    }
  },
}));

export const UserNavContainerStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",

  [theme.breakpoints.down("sm")]: {
    display: "none",
    "&.showUserData": {
      display: "flex",
    }
  },
}));

export const UserLogoStyled = styled("div")(({ theme }) => ({
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  backgroundColor: COLORS.MAIN_BLUE,
  marginRight: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  fontWeight: 600,
  textTransform: "uppercase",

  [theme.breakpoints.down("sm")]: {
  },
}));

export const UserInfoStyled = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  [theme.breakpoints.down("sm")]: {
  },
}));

export const UserEmailStyled = styled("span")(({ theme }) => ({
  fontWeight: 600,
  fontSize: 15,
  marginBottom: 5,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "path": {
    fill: COLORS.MAIN_BLUE
  },
  [theme.breakpoints.down("sm")]: {
    "svg": {
      display: "none",
    }
  },
}));

export const UserPlanStyled = styled("span")(({ theme }) => ({
  color: COLORS.MAIN_BLUE,
  fontWeight: 600,
  fontSize: 12,
  textTransform: "uppercase",

  [theme.breakpoints.down("sm")]: {
  },
}));

export const UserNavMenu = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "absolute",
  backgroundColor: "white",
  top: "50px",
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",

  [theme.breakpoints.down("sm")]: {
  },
}));

export const UserNavElement = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "0.6s all ease",
  fontWeight: 600,
  padding: 20,
  whiteSpace: 'nowrap',
  // "&:first-of-type": {
  //   marginBottom: 10,
  // },
  color: COLORS.MAIN_GRAY,

  "svg": {
    marginRight: 10,
  },
  "path": {
    fill: COLORS.MAIN_GRAY,
  },

  "&:hover": {
    color: COLORS.MAIN_BLUE,
    transition: "0.6s all ease",
    "path": {
      fill: COLORS.MAIN_BLUE,
      transition: "0.6s all ease",
    },
  },

  [theme.breakpoints.down("sm")]: {
  },
}));

export const BurgerIconMenu = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

export const LogoWrapper = styled("div")(({ theme }) => ({
  display: "block",
  marginLeft: '2.5vw',
  [theme.breakpoints.down("sm")]: {
    "&.hiddenLogo": {
      display: "none",
    }
  },
}));
