import React, { memo, useEffect, useState } from "react";
import { START_SCREEN } from "../../../utils/constants/start-screen";

import { InputSearchModel } from "../utils/inputs.model";
import { CaptionStyled, FormControlStyled, ButtonStyled } from "../utils/styled";

import {
  InputWrapperStyled,
  InputWrapSearchStyled,
  InputContainerStyled,
  LabelStyled,
  ArrowBtnStyled,
  InputValueWrapper,
  PrevValuesListWrapper,
  TextareaStyled,
} from "./styled";

const Textarea = ({
  isLabel = false,
  disabled = false,
  error = false,
  type,
  labelText = "",
  captionText,
  variant,
  nameInput = "input",
  register,
  setFocusedDomainInput,
  typeSearch = "standart",
  placeholder = "Search",
  defaultValue,
  id,
  ...props
}: InputSearchModel & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>("");

  const onHandleChange = (ev: any) => {
    setInputValue(ev.target.value);
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue.toString());
    }
  }, [defaultValue])

  return (
    <>
      <InputContainerStyled>
        <LabelStyled>{labelText}</LabelStyled>
          <TextareaStyled {...register} onChange={onHandleChange} value={inputValue} id={id}/>
      </InputContainerStyled>
    </>
  );
};

export default memo(Textarea);
