import { styled } from "@mui/system";
import { COLORS } from "../../../../utils/constants/colors";

export const DomainsContainer = styled("div")(({ theme }) => ({
  padding: 50,
  // paddingRight: 0,
  position: "relative",

  [theme.breakpoints.down(1550)]: {
    // marginTop: 0,
    padding: 20,
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",

  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    justifyContent: "center",
  },
}));

export const FormWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    justifyContent: "center",
  },
}));

export const TitleContainer = styled("div")(({ theme }) => ({
  fontSize: 24,
  fontWeight: 800,
  marginBottom: 20,

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
  },
}));

export const AvatarWrapper = styled("div")(({ theme }) => ({
  width: 170,
  height: 170,
  marginBottom: 20,
  background: COLORS.MAIN_GRADIENT,
  border: `1px solid ${COLORS.MAIN_BLUE}`,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",

  "svg": {
    color: COLORS.MAIN_BLUE,
    width: 30,
    height: 30,
  },
  [theme.breakpoints.down("sm")]: {
    width: 100,
    height: 100,
  },
}));

export const AvatarContainer = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    minWidth: 200,
  },
}));
