import React from "react";
import {
  PackageBody,
  PackageHeader,
  PackagePoint,
  PackagePriceContainer,
  PriceText,
  PriceTitle,
  PricingContainer,
  PricingWrapper,
} from "../ui/styles/pricing.styled";
import { ReactComponent as CheckIcon } from "../../../assets/images/icon/check-mark.svg";
import { ButtonMain } from "../../../components";
import { START_SCREEN } from "../../../utils/constants/start-screen";

export const PricingBlock = (): React.ReactElement => {
  const Package = ({
    title,
    price,
    includeList,
    index,
  }: {
    title: string;
    price: number;
    includeList: string[];
    index: number;
  }) => {
    return (
      <PackagePriceContainer style={{ color: "white" }} $index={index}>
        <PackageHeader>{title}</PackageHeader>
        <PackageBody>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 30,
              }}
            >
              <PriceText>${price}</PriceText>{" "}
              <span style={{ color: "white", opacity: 0.6 }}>per month</span>
            </div>
            <div>
              {includeList.map((item: string) => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "unset", marginBottom: 15, fontSize: 20 }}
                  >
                    <CheckIcon width="20" height="20" style={{ flex: 1 }} />
                    <PackagePoint>{item}</PackagePoint>
                  </div>
                );
              })}
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <ButtonMain title={START_SCREEN.START_BTN} variant="simple" />
          </div>
        </PackageBody>
      </PackagePriceContainer>
    );
  };

  return (
    <PricingContainer id="pricing">
      <PricingWrapper>
        <PriceTitle>{START_SCREEN.PRICE_TITLE}</PriceTitle>
        <p style={{ textAlign: "center", fontSize: "35px", marginBottom: 0, marginTop: 10 }}>
          {START_SCREEN.PRICE_TEXT_1}
        </p>
        <p style={{ textAlign: "center", fontSize: "35px", marginTop: 0 }}>
          {START_SCREEN.PRICE_TEXT_2}
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Package
            title={START_SCREEN.PACKAGE_NAME_1}
            price={0}
            includeList={[...START_SCREEN.PACKAGE_1_INCLUDES]}
            index={1}
          />
          <Package
            title={START_SCREEN.PACKAGE_NAME_2}
            price={15}
            includeList={[...START_SCREEN.PACKAGE_2_INCLUDES]}
            index={2}
          />
        </div>
      </PricingWrapper>
    </PricingContainer>
  );
};

export default PricingBlock;
