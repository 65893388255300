import { Navbar } from "../../components";
import React, { memo } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { ROUTES } from "./routes";

export const PrivateRoutes = memo(({ token }: { token: boolean }) => {
  return token ? (
    <Navbar>
      <Outlet />
    </Navbar>
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  )
});
