import { styled } from "@mui/system";

export const ButtonsContainerStyled = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: 40,

  [theme.breakpoints.down("md")]: {
    padding: 20,
  },
}));

export const ButtonWrapperStyled = styled("div")(() => ({
  marginRight: 10,
}));
