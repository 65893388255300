import { styled } from "@mui/system";

export const DomainsContainer = styled("div")(({ theme }) => ({
  padding: 50,

  [theme.breakpoints.down(1550)]: {
    // marginTop: 0,
    padding: 20,
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "20px",

  [theme.breakpoints.down("md")]: {
    // marginTop: 0,
  },
}));
