import React from "react";
import decorativeHr from "../../../assets/images/icon/decorative-unions.png";
import { DecorativeLineWrapper } from "../ui/styles/styled";

export const DecorativeLine = (): React.ReactElement => (
  <DecorativeLineWrapper>
    <img src={decorativeHr} style={{ width: "100%" }} alt=""/>
  </DecorativeLineWrapper>
);

export default DecorativeLine;
