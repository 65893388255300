import { styled } from "@mui/system";
import { ExtendedThemeOptions } from "../../../../assets/theme-types";

export const DontForgetContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const DontForgetWrapperStyled = styled("div")(({ theme }) => ({
  width: "900px",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  marginTop: 70,
  // fontSize: "36px",
  // fontWeight: "900",

  ...(theme.typography as ExtendedThemeOptions["typography"]).inter_900_36px,

  [theme.breakpoints.down("md")]: {
    marginTop: 20,
    display: "block !important",
    textAlign: "center",
    maxWidth: "80%",
  },

  [theme.breakpoints.down("sm")]: {
    ...(theme.typography as ExtendedThemeOptions["typography"]).inter_900_20px,
    marginTop: 0,
  },
}));

export const RenewTextStyled = styled("div")(({ theme }) => ({
  position: "relative",

  [theme.breakpoints.down("md")]: {
    width: "fit-content",
    margin: "0 auto",
  },
}));

export const RenewDecorLineStyled = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "15px",
  backgroundColor: "#F689D0",
  top: 25,
  zIndex: -1,

  [theme.breakpoints.down("md")]: {
    top: 22,
    height: "10px",
  },

  [theme.breakpoints.down("sm")]: {
    top: 13,
    height: "8px",
  },
}));

export const FlexContainer = styled("div")(() => ({
  display: "flex",
}));

export const DesktopDontForget = styled("div")(({ theme }) => ({
  display: "block",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const MobileDontForget = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));
