import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../utils/hooks/global";
import { changePassword, deleteUserProfile, getUserInfo, updateUserInfo } from "../api/async";
import { toast } from "react-toastify";

export const useUserProfile = () => {
  const { register, handleSubmit, watch } = useForm();
  const { register: registerForm1, handleSubmit: handleSubmitForm1, watch: watchForm1 } = useForm();
  const dispatch = useAppDispatch();
  const userStore = useAppSelector(store => store.user);

  const [userInfo, setUserInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  useEffect(() => {
    setLoading(userStore.isFetching);

    if (userStore.isUpdated || userStore.isDeleted) {
      setTimeout(() => {
        window.location.reload();
      }, 2500)
    }

    if (userStore.userInfo) {
      setUserInfo(userStore.userInfo);
    }
  }, [userStore])

  const onHandleUpdateUserInfo = (data: any) => {
    dispatch(updateUserInfo({
      id: userInfo.id,
      company: data.company,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
    }))
  }

  const onHandleChangePassword = (data: any) => {
    if (data.password_new && data.password_confirm && data.password_new !== data.password_confirm) {
      toast.error('Please make sure your password and password confirmation match exactly and try again.')
    } else if (data.password_new && data.password_confirm && data.password_new === data.password_confirm) {
      dispatch(changePassword({
        id: userInfo.id,
        first_name: userInfo.user_profile.first_name,
        last_name: userInfo.user_profile.last_name,
        email: userInfo.email,
        password: data.password_new ?? null,
        password_confirmation: data.password_confirm ?? null,
      }))
    }
  }

  const onHandleDeleteUser = () => {
    dispatch(deleteUserProfile(userInfo.id));
  }

  return {
    register,
    registerForm1,
    watch,
    watchForm1,
    handleSubmitForm1,
    handleSubmit,
    // onPasswordChange,
    loading,
    openModal,
    setOpenModal,
    onHandleDeleteUser,
    userInfo,
    onHandleUpdateUserInfo,
    onHandleChangePassword,
  };
};