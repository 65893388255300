import React, { useState } from "react";
import { SpinnerContainer, Spinner } from "./styled";

export const SpinnerElement = ({ isLoading }: { isLoading: boolean }): JSX.Element => {
  const [openedAllMenu, setOpenedAllMenu] = useState<boolean>(false);

  return (
      <SpinnerContainer $isActive={isLoading}>
        <Spinner />
      </SpinnerContainer>
  );
};

export default React.memo(SpinnerElement);
