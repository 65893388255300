import { styled } from "@mui/system";
import { COLORS } from "../../../../utils/constants/colors";

export const NumericalValue = styled("span")<{$color: "error" | "warning" | "success"}>(({ theme, $color }) => ({
    display: "inline-block",
    padding: "3px 6px",
    borderRadius: 3,
    color: "white",
    fontSize: 14,
    fontWeight: 600,
    minWidth: 50,
    backgroundColor: $color === "warning" ? "#E4B60F" : $color === "error" ? "#E23939" : "#19DD21",
}));

export const IconWrapper = styled("span")<{$color: "error" | "warning" | "success", $size: "small" | "medium"}>(({ theme, $color, $size }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "4px",
  width: $size === "small" ? 20 : 25,
  height: $size === "small" ? 20 : 25,
  borderRadius: 3,
  color: "white",
  fontSize: 14,
  fontWeight: 600,
  backgroundColor: $color === "warning" ? "#E4B60F" : $color === "error" ? "#E23939" : "#19DD21",
  transform: $color === "warning" ? "rotate(180deg)" : "",

  "svg": {
    textAlign: "center",
    verticalAlign: "middle",
    width: 24,
    height: 24
  }
}));

export const DomainName = styled("span")(({ theme }) => ({
  color: COLORS.MAIN_BLUE,
}));

export const BlueHeadTitle = styled("span")(({ theme }) => ({
  color: COLORS.MAIN_BLUE,
  fontWeight: 700,
  fontSize: 16,
}));

export const BlackHeadTitle = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
}));