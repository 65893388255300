import { createSlice } from "@reduxjs/toolkit";
import FileSaver from 'file-saver';
import { RootState } from "../../../store/store";

import {
  getDomains,
  getDomainInfo,
  checkAllDomains,
  getDomainFullInfo,
  stopTrackingDomain,
  addDomain,
  deleteDomain,
  addDomains,
  getAdditionalEmails,
  exportAllDomains,
  checkDomain,
  clearDomainInfo,
  getSubscriptionInfo,
  getPaymentsHistory,
} from "./async";

const initialState: any = {
  isFetching: false,
  isChecked: true,
  checkingInProcess: null,
  domainsList: [],
  paymentsHistory: [],
  domainInfo: {},
  isTracked: true,
  isAddedDomain: false,
  isDeletedDomain: false,
  additionalEmails: [],
  notes: null,
  isChecking: false,
};

const domainsSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {},
  extraReducers: {
    [getDomainInfo.pending.type as unknown as string]: state => {
      state.isFetching = true;
    },
    [getDomainInfo.fulfilled.type as unknown as string]: (state, action) => {
      state.isFetching = false;
      state.domainInfo = action.payload.resource[0];
    },
    [getDomainInfo.rejected.type as unknown as string]: state => {
      state.isFetching = false;
    },
    [getSubscriptionInfo.pending.type as unknown as string]: state => {
      state.isFetching = true;
    },
    [getSubscriptionInfo.fulfilled.type as unknown as string]: (state, action) => {
      state.isFetching = false;
      state.subscriptionInfo = action.payload.resource;
    },
    [getSubscriptionInfo.rejected.type as unknown as string]: state => {
      state.isFetching = false;
    },
    [getPaymentsHistory.pending.type as unknown as string]: state => {
      state.isFetching = true;
    },
    [getPaymentsHistory.fulfilled.type as unknown as string]: (state, action) => {
      state.isFetching = false;
      state.paymentsHistory = action.payload.resource;
    },
    [getPaymentsHistory.rejected.type as unknown as string]: state => {
      state.isFetching = false;
    },
    [getDomainFullInfo.pending.type as unknown as string]: state => {
      state.isFetching = true;
    },
    [getDomainFullInfo.fulfilled.type as unknown as string]: (state, action) => {
      state.isFetching = false;
      state.domainInfo = action.payload.resource;
    },
    [getDomainFullInfo.rejected.type as unknown as string]: state => {
      state.isFetching = false;
    },
    [getDomains.pending.type as unknown as string]: state => {
      state.isFetching = true;
    },
    [getDomains.fulfilled.type as unknown as string]: (state, action) => {
      state.isFetching = false;
      state.domainsList = action.payload.resource.data;
    },
    [getDomains.rejected.type as unknown as string]: state => {
      state.isFetching = false;
    },
    [checkAllDomains.pending.type as unknown as string]: state => {
      state.isChecked = false;
      state.checkingInProcess = "yes";
    },
    [checkAllDomains.fulfilled.type as unknown as string]: (state, action) => {
      state.isChecked = true;
      state.checkingInProcess = "no";
    },
    [checkAllDomains.rejected.type as unknown as string]: state => {
      state.isChecked = true;
      state.checkingInProcess = "no";
    },
    [exportAllDomains.pending.type as unknown as string]: state => {
    },
    [exportAllDomains.fulfilled.type as unknown as string]: (state, action) => {
      const info = new Blob([action.payload], {type: 'application/csv'});
      const fileURL = window.URL.createObjectURL(info);
      FileSaver.saveAs(fileURL, "Domains.csv");
    },
    [exportAllDomains.rejected.type as unknown as string]: state => {
    },
    [stopTrackingDomain.fulfilled.type as unknown as string]: (state, action) => {
      state.isTracked = false;
    },
    [stopTrackingDomain.rejected.type as unknown as string]: state => {
      state.isTracked = true;
    },
    [addDomain.pending.type as unknown as string]: state => {
      state.isAddedDomain = false;
    },
    [addDomain.fulfilled.type as unknown as string]: (state, action) => {
      state.isAddedDomain = true;
    },
    [addDomain.rejected.type as unknown as string]: state => {
      state.isAddedDomain = false;
    },
    [deleteDomain.pending.type as unknown as string]: state => {
      state.isDeletedDomain = false;
    },
    [deleteDomain.fulfilled.type as unknown as string]: (state, action) => {
      state.isDeletedDomain = true;
    },
    [deleteDomain.rejected.type as unknown as string]: state => {
      state.isDeletedDomain = false;
    },
    [addDomains.pending.type as unknown as string]: state => {
      state.isAddedDomain = false;
    },
    [addDomains.fulfilled.type as unknown as string]: (state, action) => {
      state.isAddedDomain = true;
    },
    [addDomains.rejected.type as unknown as string]: state => {
      state.isAddedDomain = false;
    },
    [getAdditionalEmails.pending.type as unknown as string]: state => {
      state.isFetching = true;
    },
    [getAdditionalEmails.fulfilled.type as unknown as string]: (state, action) => {
      state.isFetching = false;
      state.notes = action?.payload?.resource?.notification_settings?.notes ?? null;
      state.additionalEmails = action?.payload?.resource?.notification_settings?.additional_emails
        ? JSON.parse(action.payload.resource.notification_settings.additional_emails)
        : [];
    },
    [getAdditionalEmails.rejected.type as unknown as string]: state => {
      state.isFetching = false;
    },
    [checkDomain.pending.type as unknown as string]: state => {
      state.isChecking = true;
      state.checkingInProcess = "yes";
    },
    [checkDomain.fulfilled.type as unknown as string]: (state, action) => {
      state.isChecking = false;
      state.checkingInProcess = "no";
    },
    [checkDomain.rejected.type as unknown as string]: state => {
      state.isChecking = false;
      state.checkingInProcess = "no";
    },
    [clearDomainInfo.pending.type as unknown as string]: state => {
      state.domainInfo = {};
    },
  },
});

export const domainsSelector = (state: RootState): RootState["domains"] => state.domains;

const { reducer } = domainsSlice;

export default reducer;
