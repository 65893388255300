import { ButtonMain, Checkbox } from "../../../../components";
import Input from "../../../../components/inputs/input/Input";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../router/routes";
import { SIGN_IN, SIGN_UP } from "../../../../utils/constants/auth";

import {
  ForgotPasswordText,
  FormContainerStyled,
  FormTitleStyled,
  InputsWrapperStyled,
  RememberMeWrapperStyled,
} from "../utils/styles/form.styled";

export const LoginForm = ({
  handleSubmitToSignUp,
  isFetching,
}: any): React.ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  }: any = useForm();
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState<boolean>(false);

  return (
    <FormContainerStyled className="formContainer" onSubmit={handleSubmit(handleSubmitToSignUp)}>
      <FormTitleStyled style={{ textAlign: "center", fontWeight: 800, fontSize: 24 }}>
        {SIGN_IN.TITLE}
      </FormTitleStyled>

      <Input
        type="text"
        nameInput="email"
        register={{
          ...register("email", {
            pattern: "\b[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}\b",
          }),
          required: true,
        }}
        isLabel={true}
        labelText="Email:"
        // error={errors?.password ? true : false}
        // captionText={(errors.password?.message?.toString() as string) || ""}
      />

      <Input
        type="password"
        nameInput="password"
        register={{
          ...register("password"),
          required: true,
          minLength: 8,
        }}
        isLabel={true}
        labelText="Password:"
        className="firstInput"
        hidePassword={hidePassword}
        setHidePassword={setHidePassword}
      />

      <RememberMeWrapperStyled>
        <Checkbox label="Remember Me"/>
        <ForgotPasswordText onClick={() => navigate(ROUTES.RECOVERY_PASSWORD)}>Forgot password?</ForgotPasswordText>
      </RememberMeWrapperStyled>

      <ButtonMain title={SIGN_IN.BUTTON} onClick={() => null} variant="primary" disabled={isFetching} />
    </FormContainerStyled>
  );
};

export default React.memo(LoginForm);
