import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../../utils/hooks/global";
import { history } from "../../../../utils/helpers";
import { ROUTES } from "./../../../../pages/router/routes";
import { addDomains } from "../../api/async";

export const useDomainsCreate = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const domainsStore = useAppSelector(store => store.domains);

  const [valuesList, setValuesList] = useState<Array<string>>([]);
  const [domainError, setDomainError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const onDeletePrevValue = (prevValue: string) => {
    const valuesList_ = valuesList.filter((value: string) => value !== prevValue);
    setValuesList(valuesList_);
  }

  const onHandleAddDomains = () => {
    setLoading(true);
    dispatch(addDomains({
      domains: JSON.stringify(valuesList.map(domain => {
        return {
          "domain_name": domain
        }
      }))
    }));
  }

  useEffect(() => {
    if (domainsStore.isAddedDomain) {
      setTimeout(() => {
        history.push(ROUTES.DASHBOARD, { replace: true });
        window.location.reload();
      }, 2000)
    }
  }, [domainsStore])

  return {
    register,
    loading,
    navigate,
    onDeletePrevValue,
    domainsStore,
    setValuesList,
    valuesList,
    setDomainError,
    domainError,
    onHandleAddDomains,
  };
};