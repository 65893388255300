import { styled } from "@mui/system";

import { InputWrapStyled } from "../utils/styled";
import { ReactComponent as ArrowBtn } from "../../../assets/images/icon/arrow-btn.svg";
import { ExtendedThemeOptions } from "../../../assets/theme-types";
import { COLORS } from "../../../utils/constants/colors";

export const InputWrapSearchStyled = styled(InputWrapStyled)({
  minWidth: "44px",
  height: "44px",
  position: "relative",
});

export const InputContainerStyled = styled("div")(({ theme }) => ({
  width: "750px",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap-reverse",
    padding: "5px 10px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "85%",
  },
}))

export const InputWrapperStyled = styled("div")(({ theme }) =>({
  border: "1px solid #224aff",
  display: "flex",
  alignItems: "center",
  padding: "25px 35px",
  fontSize: "22px",
  color: "#224aff",
  fontStyle: "italic",
  position: "relative",

  [theme.breakpoints.down("sm")]: {
    padding: "15px 24px",
    fontSize: "16px",
  },
}));

export const LabelStyled = styled("span")(({ theme }) => ({
  color: COLORS.MAIN_PINK,
  alignSelf: "center",
  marginBottom: 7,
  textTransform: "uppercase",

  ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_16px,

  [theme.breakpoints.down("md")]: {
    ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_12px,
  },
}))

export const ArrowBtnStyled = styled(ArrowBtn)(({ theme }) => ({
  width: 30,
  height: 30,
  position: "absolute",
  right: "25px",
  cursor: "pointer",

  [theme.breakpoints.down("sm")]: {
    width: 20,
    height: 20,
  },
}));


export const InputStyled = styled("input")(({ theme }) => ({
  border: "none",
  fontSize: "20px",
  padding: "0px",
  color: "#224aff",
  fontStyle: "italic",
  width: "100%",
  marginLeft: 5,
  backgroundColor: "#ffffff00",

  "&:focus, &:active": {
    border: "none",
    outline: "none",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    marginLeft: 2,
  },
}));
