import React, { memo } from "react";

import { LogoStyled } from "./styled";

interface Props {
  size?: "small" | "middle" | "large";
  onClick?: any;
}

const MainLogo = ({ size = "middle", onClick }: Props): JSX.Element => (
  <div onClick={() => onClick ? onClick() : null} style={{cursor: 'pointer'}}>
    <LogoStyled $size={size} />
  </div>
);

export default memo(MainLogo);
