import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { ResponseErrorModel } from "../../../models/auth/auth.model";
import notificationsService from "./service";

const errorMessage = (error: AxiosError<ResponseErrorModel>): string =>
  error?.response?.data?.errors?.["email" || "display_name" || "phone" || "password"]?.toString() ||
  error?.response?.data?.message ||
  error?.toString();

export const getNotifications = createAsyncThunk(
  "user/getNotifications",
  async () => {
    try {
      const response = await notificationsService.getNotifications();
      const { status, data } = response;
      // const user: any = userData?.resource;

      // if (response.status === 200) {
      //   toast.success("Sign Up succesfull");
      // }

      if (status === 200) {
        // localStorage.setItem("token", JSON.stringify(user.token));
        // localStorage.setItem("id", JSON.stringify(user.user.id));
        // localStorage.setItem("email", JSON.stringify(user.user.email));
        // toast.success("Sign Up succesfull!");
      }

      // return response.data.data;
      return { data: data?.resource ?? data };
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const setNotifTypes = createAsyncThunk(
  "user/setNotifTypes",
  async ({is_encrypt, is_change_alerts} : {is_encrypt: boolean, is_change_alerts: boolean}) => {
    try {
      const response = await notificationsService.setNotifTypes({is_encrypt, is_change_alerts});
      const { status, data } = response;


      if (status === 200) {
        toast.success("The notification settings have been successfully updated.");
      }

      // return response.data.data;
      return { data: data?.resource ?? data };
    } catch (error: unknown) {
      toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const setNotifByEmail = createAsyncThunk(
  "user/setNotifByEmail",
  async ({is_notify_email} : {is_notify_email: boolean}) => {
    try {
      const response = await notificationsService.setNotifByEmail({is_notify_email});
      const { status, data } = response;


      if (status === 200) {
        toast.success("The notification settings have been successfully updated.");
      }

      // return response.data.data;
      return { data: data?.resource ?? data };
    } catch (error: unknown) {
      toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const setNotifSchedule = createAsyncThunk(
  "user/setNotifSchedule",
  async ({is_one_day_before_expiry,
    is_three_days_before_expiry,
    is_seven_days_before_expiry,
    is_fourteen_days_before_expiry,
    is_thirty_days_before_expiry,
    is_sixty_days_before_expiry} : {is_one_day_before_expiry: boolean;
      is_three_days_before_expiry: boolean;
      is_seven_days_before_expiry: boolean;
      is_fourteen_days_before_expiry: boolean;
      is_thirty_days_before_expiry: boolean;
      is_sixty_days_before_expiry: boolean;}) => {
    try {
      const response = await notificationsService.setNotifSchedule({is_one_day_before_expiry,
        is_three_days_before_expiry,
        is_seven_days_before_expiry,
        is_fourteen_days_before_expiry,
        is_thirty_days_before_expiry,
        is_sixty_days_before_expiry});
      const { status, data } = response;


      if (status === 200) {
        toast.success("The notification settings have been successfully updated.");
      }

      // return response.data.data;
      return { data: data?.resource ?? data };
    } catch (error: unknown) {
      toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

// export const setNotifBySlack = createAsyncThunk(
//   "user/setNotifByEmail",
//   async () => {
//     try {
//       const response = await notificationsService.setNotifBySlack();
//       const { status, data } = response;


//       if (status === 200) {
//         toast.success("The notification settings have been successfully updated.");
//       }

//       // return response.data.data;
//       return { data: data?.resource ?? data };
//     } catch (error: unknown) {
//       toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
//     }
//   }
// );

// export const setNotifByMicrosoft = createAsyncThunk(
//   "user/setNotifByEmail",
//   async () => {
//     try {
//       const response = await notificationsService.setNotifByMicrosoft();
//       const { status, data } = response;


//       if (status === 200) {
//         toast.success("The notification settings have been successfully updated.");
//       }

//       // return response.data.data;
//       return { data: data?.resource ?? data };
//     } catch (error: unknown) {
//       toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
//     }
//   }
// );
