import React, { useState } from "react";
import {
  ActionsWrapperStyled,
  ProfileFormWrapper,
} from "../ui/styles/actions.styled";
import { useNavigate } from "react-router";
import Input from "../../../components/inputs/input/Input";

export const PasswordForm = ({userInfo, register, watch}: {userInfo: any, register: any, watch: any}): React.ReactElement => {
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState<boolean>(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(false);
  const [hideCurrentPassword, setHideCurrentPassword] = useState<boolean>(false);

  return (
    <ProfileFormWrapper>
      <ActionsWrapperStyled>
      <Input
          type="password"
          nameInput="password_new"
          register={{
            ...register("password_new"),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="New Password:"
          className="firstInput"
          wrapperClassName="maxWidthInput marginInput"
          hidePassword={hidePassword}
          setHidePassword={setHidePassword}
          autoComplete="new-password"
        />
        <Input
          type="password"
          nameInput="password_confirm"
          register={{
            ...register("password_confirm", {
              validate: (val: string) => {
                if (watch('password_new') !== val) {
                  return "Your passwords do no match";
                }
              },
            }),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="New Password CONFIRMATION:"
          wrapperClassName="maxWidthInput"
          hidePassword={hideConfirmPassword}
          setHidePassword={setHideConfirmPassword}
          autoComplete="new-password"
        />
      {/* <Input
          type="password"
          nameInput="new_password"
          register={{
            ...register("new_password"),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="Current Password:"
          className="firstInput"
          wrapperClassName="maxWidthInput"
          hidePassword={hideCurrentPassword}
          setHidePassword={setHideCurrentPassword}
        /> */}
      </ActionsWrapperStyled>
    </ProfileFormWrapper>
  );
};

export default PasswordForm;
