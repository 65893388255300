import { styled } from "@mui/system";
import { LinearProgress, linearProgressClasses } from "@mui/material";

export const DomainsContainer = styled("div")(({ theme }) => ({
  padding: 50,

  [theme.breakpoints.down(1550)]: {
    // marginTop: 0,
    padding: 20,
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "20px",

  [theme.breakpoints.down("md")]: {
    // marginTop: 0,
  },
}));

export const ProgressWrapper = styled("div")(({ theme }) => ({
  width: "60%",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const IndicatorResult = styled("div")(({ theme }) => ({
  textAlign: "right", 
  marginTop: 15, 
  position: "absolute", 
  right: -30,
  top: 0,
  // right: "-5%",
  backgroundColor: '#17dd21',
  color: 'white',
  padding: 10,
  borderRadius: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const IndicatorResultInner = styled("div")(({ theme }) => ({
  position: "absolute", 
  width: 15,
  height: 15,
  backgroundColor: '#17dd21',
  transform: 'rotate(45deg)',
  bottom: 30,
  zIndex: -10,
}))

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  maxWidth: "100%",

  ".MuiLinearProgress-bar": {
    "background-color": "#17dd21 !important",
  },

  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));
