import React from "react";
import { Box } from "@mui/system";
import { Modal, Tab, Tabs } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import InputDomains from "../../../components/inputs/input-domains_/InputDomains";
import { ButtonMain, Spinner, SubTitle, Title , Textarea, Input} from "../../../components";
import { ReactComponent as CopyIcon } from "../../../assets/images/icon/copy.svg";
import { 
  DomainsContainer,
  ButtonWrapper,
  TabContentWrapper,
  CopyWrapper,
  CopyContainer,
  KeyWrapper,
  PrevValuesListWrapper,
  InputValueWrapper,
 } from "./ui/styles/styled";
import { COLORS } from "../../../utils/constants/colors";
import { useDomainDetail } from "./utils/hook";
import { TitleContainer } from "../../../components/titles/utils/styled";
import DomainsList from "./ui/DomainsList";
import { ROUTES } from "../../router/routes";
import { TitleWithOptions } from "../../../components/titles";
import { ModalWrapper } from "../../../components/modal/styled";

export const DomainDetail = (): React.ReactElement => {
  const {
    register,
    loading,
    navigate,
    handleSubmit,
    onHandleEditDomainName,
    onHandleDeleteDomain,
    onHandleCheckAllDomains,
    onDeletePrevValue,
    onHandleTracking,
    onHandleAddEmails,
    copyKey,
    onHandleChangeTab,
    a11yProps,
    domainInfo,
    keyShow,
    setKeyShow,
    location,
    progressLoading,
    selectedTab,
    copiedDecoded,
    copiedEncoded,
    domainsStore,
    setOpenModal,
    setValuesList,
    valuesList,
    setEmailError,
    emailError,
    openModal,
    domainName,
    notes,
    domainChecks,
    onChangePage,
    page,
  } = useDomainDetail();

  return (
<>
      <Spinner isLoading={loading} />
      <DomainsContainer>
        <TitleWithOptions daysCount={domainInfo?.expiration_days ?? null} name={domainName} titleOptions={[
            {option: "delete", eventHandler: () => setOpenModal({
            opened: true,
            title: "Are you sure that you want to delete your domain?",
            backTitle: "Back",
            confirmTitle: "Confirm",
            type: "delete"
          }), title: "Delete domain"},
          {
            option: "editTitle",
            eventHandler: () => setOpenModal(({
              opened: true,
              title: "Change the name of your domain",
              backTitle: "Back",
              confirmTitle: "Save changes",
              type: "editName"
            })),
              title: "",
          }]}/>
        <TitleContainer>
          SSL Certificate History{" "}
          <ButtonMain
            title="Check Now"
            variant="secondary"
            size="small"
            onClick={onHandleCheckAllDomains}
            disabled={domainsStore.isChecking || !domainsStore.domainInfo.is_tracking}
          />
        </TitleContainer>

        <p style={{ color: "#212121" }}>
          Every time we see a new SSL certificate being served by your website you'll see a
          certificate entered into the table below.
        </p>

        {domainInfo ? (
          <DomainsList
            keyShow={keyShow}
            setKeyShow={setKeyShow}
            domainInfo={domainInfo}
            domainChecks={domainChecks}
            hostname={location?.state?.hostname}
            progressLoading={progressLoading}
            currentPage={page}
            onChangePage={onChangePage}
          />
        ) : null}

        {keyShow && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={selectedTab}
                onChange={onHandleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Decoded" {...a11yProps(0)} />
                <Tab label="Encoded" {...a11yProps(1)} />
              </Tabs>
            </Box>
            {selectedTab === 0 && (
              <TabContentWrapper>
                <KeyWrapper><pre>{`${domainInfo?.ssl_decoded ?? ''}`}</pre></KeyWrapper>
                {/* <KeyWrapper>{`${domainInfo?.ssl_decoded}`}</KeyWrapper> */}
                <CopyContainer>
                  <CopyWrapper onClick={() => copyKey("decodedKey")}>
                    <CopyIcon />
                    {copiedDecoded ? "Copied!" : "Copy"}
                  </CopyWrapper>
                </CopyContainer>
              </TabContentWrapper>
            )}
            {selectedTab === 1 && (
              <TabContentWrapper>
                <KeyWrapper><pre>{domainInfo?.ssl_encoded ?? ''}</pre></KeyWrapper>
                {/* <KeyWrapper>{domainInfo?.ssl_encoded}</KeyWrapper> */}
                <CopyContainer>
                  <CopyWrapper onClick={() => copyKey("encodedKey")}>
                    <CopyIcon />
                    {copiedEncoded ? "Copied!" : "Copy"}
                  </CopyWrapper>
                </CopyContainer>
              </TabContentWrapper>
            )}
          </Box>
        )}

        <ButtonWrapper>
          <ButtonMain title="Back" variant="secondary" size="small" onClick={() => navigate(-1)} />
          <ButtonMain
            title={domainsStore.domainInfo.is_tracking ? "Stop Tracking" : "Start Tracking"}
            variant="primary"
            size="small"
            onClick={() => domainsStore.domainInfo.is_tracking ? setOpenModal({
              opened: true,
              title: "Are you sure that you want to stop tracking?",
              backTitle: "Back",
              confirmTitle: "Confirm",
              type: "tracking"
            }) : onHandleTracking()}
          />
        </ButtonWrapper>

        <TitleContainer style={{ marginTop: 60 }}>Notification Options</TitleContainer>

        <InputDomains
          isLabel={false}
          labelText="Notification Emails (Press Enter or Space for next email):"
          nameInput="search"
          type="search"
          variant="search"
          typeSearch="absolute"
          register={{ ...register("search") }}
          setFocusedDomainInput={() => null}
          setValuesList={setValuesList}
          valuesList={valuesList}
          setEmailError={setEmailError}
          emailError={emailError}
          regex={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/}
        />

        {emailError && <div style={{
          marginTop: 5,
          fontWeight: 'bold',
          color: '#f07070'
        }}>
          Invalid email format
        </div>}

        <div style={{ color: COLORS.MAIN_GRAY, fontStyle: "italic", marginTop: 10 }}>
          Enter one or more email addresses to receive notifications about this domain. These are in
          addition to your notification emails setup for{" "}
          <span
            style={{ cursor: "pointer", color: COLORS.MAIN_BLUE }}
            onClick={() => navigate(ROUTES.DASHBOARD)}
          >
            all domains
          </span>
          .
        </div>

        <PrevValuesListWrapper>
          {valuesList &&
            valuesList.map((prevDomain: string) => {
              return (
                <InputValueWrapper key={prevDomain}>
                  {prevDomain} <DeleteIcon onClick={() => onDeletePrevValue(prevDomain)} />
                </InputValueWrapper>
              );
            })}
        </PrevValuesListWrapper>

        <div style={{ marginTop: 30 }}>
          <Textarea
            isLabel={false}
            labelText="Notes:"
            nameInput="notes"
            type="search"
            variant="search"
            typeSearch="absolute"
            register={{ ...register("notes") }}
            setFocusedDomainInput={() => null}
            style={{ marginTop: 30 }}
            defaultValue={notes}
            id="domain_notes"
          />
        </div>

        <div style={{ color: COLORS.MAIN_GRAY, fontStyle: "italic", marginTop: 10 }}>
          Add a note to the domain to help identify the domain, provide instructions on renewing or
          remind your future self of something important. This note will be sent out with any
          notification emails.
        </div>

        <ButtonMain
          title="Save"
          variant="primary"
          size="small"
          style={{ marginTop: 20 }}
          onClick={handleSubmit(onHandleAddEmails)}
        />

        <Modal
          open={openModal.opened}
          onClose={() => setOpenModal({
    opened: false,
    title: "",
    backTitle: "",
    confirmTitle: "",
            type: "",
  })}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalWrapper>
            <h2 style={{ fontSize: 30, textAlign: "center", marginBottom: openModal?.type === "editName" ? 20 : 60 }}>
              {openModal.title}
            </h2>

            {openModal?.type === "editName" && <Input
              type="text"
              nameInput="domain_title"
              register={{
                ...register("domain_title", {
                }),
                required: true,
              }}
              isLabel={true}
              labelText="Domain name:"
              wrapperClassName="maxWidthInput marginInput"
              defaultValue={domainName}
          />}

            <ButtonWrapper>
              <ButtonMain
                title={openModal.backTitle}
                variant="secondary"
                size="middle"
                onClick={() => setOpenModal({
    opened: false,
    title: "",
    backTitle: "",
    confirmTitle: "",
                  type: "",
  })}
                style={{ marginRight: 10 }}
              />
              <ButtonMain
                title={openModal.confirmTitle}
                variant="primary"
                size="middle"
                onClick={() => {
                  openModal?.type === "tracking" ? onHandleTracking() : openModal?.type === "delete" ? onHandleDeleteDomain() : handleSubmit(onHandleEditDomainName)()
                  setOpenModal({
    opened: false,
    title: "",
    backTitle: "",
    confirmTitle: "",
                    type: "",
  });
                }}
              />
            </ButtonWrapper>
          </ModalWrapper>
        </Modal>
      </DomainsContainer>
    </>
  );
};

export default React.memo(DomainDetail);
