import { styled } from "@mui/system";
import { ExtendedThemeOptions } from "../../../../assets/theme-types";

export const PricingWrapper = styled("div")(({ theme }) => ({
  width: 800,

  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

export const PackageBody = styled("div")<{$variant: "white" | "standart"}>(({ theme, $variant }) => ({
  padding: "20px 50px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  // maxHeight: "340px",
  // height: "340px",
  minHeight: "240px",
  backgroundColor: $variant === "white" ? "white" : "initial",
  border: $variant === "white" ? `1px solid #3083FF` : "initial",
  borderTop: $variant === "white" ? "none" : "initial",

  [theme.breakpoints.down("md")]: {
    padding: "18px 25px",
    height: "250px",
    // maxHeight: "100%",
    // height: "370px",
    minHeight: "auto",
  },

  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
}));

export const PriceText = styled("span")(({ theme }) => ({
  ...(theme.typography as ExtendedThemeOptions["typography"]).inter_900_72px,

  [theme.breakpoints.down("md")]: {
    ...(theme.typography as ExtendedThemeOptions["typography"]).inter_900_42px,
  },
}));

export const PackagePoint = styled("span")(({ theme }) => ({
  flex: 8,
  marginTop: -3,
  marginLeft: 10,
  ...(theme.typography as ExtendedThemeOptions["typography"]).inter_500_20px,

  [theme.breakpoints.down("md")]: {
    marginTop: 0,
    ...(theme.typography as ExtendedThemeOptions["typography"]).inter_500_12px,
  },
}));

export const PriceTitle = styled("h1")(({ theme }) => ({
  textAlign: "center",
  fontSize: "80px",
  margin: 0,

  [theme.breakpoints.down("md")]: {
    fontSize: 60,
  },
}));

export const PackagePriceContainer = styled("div")<{ $index: number }>(({ theme, $index }) => ({
  // backgroundColor: "#084AAE",
  borderRadius: "7px",
  flex: 1,
  marginRight: $index === 1 ? 20 : 0,

  [theme.breakpoints.down("md")]: {
    marginRight: 0,
    marginBottom: 15,
  },

  [theme.breakpoints.down("sm")]: {
    minWidth: "200px",
    width: "200px",
  },
}));

export const PricingContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: 100,
}));

export const PackageHeader = styled("div")(({ theme }) => ({
  backgroundColor: "#3083FF",
  borderRadius: "7px 7px 0px 0px",
  padding: "20px",
  color: "white",
  display: "flex",
  justifyContent: "center",

  ...(theme.typography as ExtendedThemeOptions["typography"]).inter_900_36px,

  [theme.breakpoints.down("sm")]: {
    ...(theme.typography as ExtendedThemeOptions["typography"]).inter_900_20px,
  },
}));

export const PackageWrapper = styled("div")(({ theme }) => ({
  // [theme.breakpoints.down("md")]: {
  //   marginRight: 10,
  // },
}));
