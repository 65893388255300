import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { LogoMain } from "../../../components";
import { LoginForm } from "./components/LoginForm";
import { SignUpContainerStyled, SignUpWrapperStyled } from "./utils/styles/styled";
import { SIGN_IN } from "../../../utils/constants/auth";
import { COLORS } from "../../../utils/constants/colors";
import { login } from "../api/async";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/global";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../pages/router/routes";

export const SignIn = (): React.ReactElement => {
  const { register, handleSubmit } = useForm();
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isLoggedIn) {
      window.location.reload();
    }
  }, [user])

  const onSignUp = (data: any) => {
    dispatch(
      login({
        email: data.email,
        password: data.password,
      })
    );
  };

  const goToSignUp = () => {
    navigate(ROUTES.SIGN_UP);
  };

  return (
    <SignUpContainerStyled>
      <SignUpWrapperStyled>
        <div style={{ marginBottom: 10, cursor: "pointer" }} onClick={() => navigate(ROUTES.HOME)}>
          <LogoMain size="small" />
        </div>
        <LoginForm handleSubmitToSignUp={onSignUp} isFetching={user.isFetching} />
        <div style={{ marginTop: 20 }}>
          {SIGN_IN.NOT_REGISTERED}{" "}
          <span style={{ cursor: "pointer", color: COLORS.MAIN_BLUE }} onClick={goToSignUp}>
            {SIGN_IN.CREATE_ACCOUNT}
          </span>
        </div>
      </SignUpWrapperStyled>
    </SignUpContainerStyled>
  );
};

export default React.memo(SignIn);
