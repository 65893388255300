import { styled } from "@mui/system";

export const DomainsContainer = styled("div")(({ theme }) => ({
  padding: 50,

  [theme.breakpoints.down(1550)]: {
    // marginTop: 0,
    padding: 20,
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "20px",

  [theme.breakpoints.down("md")]: {
    // marginTop: 0,
  },
}));

export const TemplatesWrapper = styled("div")(({ theme }) => ({
  // display: "flex",
  // flexDirection: "column",
  // flexWrap: "wrap",
  maxHeight: 810,
  marginTop: 30,
  // display: 'grid',
  // gridTemplateColumns: 'repeat(2, 1fr)',
  // // gridTemplateRows: 'auto auto auto',
  // gridAutoRows: 'minmax(80px, auto)',
  // gridGap: '50px 10px',

  [theme.breakpoints.down(1550)]: {
    maxHeight: "initial",
  },
}));
