import { useEffect, useState } from "react";
import { getSubscriptionInfo, getPaymentsHistory, deleteSubscription } from "../api/async";
import { useAppDispatch, useAppSelector } from "utils/hooks/global";
import { getUserInfo } from "pages/user-profile/api/async";

export const useBilling = () => {
  const dispatch = useAppDispatch();
  const billingStore = useAppSelector(store => store.billing);
  const userStore = useAppSelector(store => store.user);
  const [domainsSize, setDomainsSize] = useState<number>(0);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModal_, setOpenModal_] = useState<boolean>(false);
  const [addCardMode, setAddCardMode] = useState<'subscribe' | 'changeCard'>('subscribe');
  const [pastPayments, setPastPayments] = useState([]);
  const [cardInfo, setCardInfo] = useState<any>(null);

  const MAX_DOMAINS_SIZE = 50;

  useEffect(() => {
    setProgressValue((domainsSize*100)/billingStore?.subscriptionInfo?.quantity_tracked_domains)
  }, [domainsSize, billingStore?.subscriptionInfo])

  useEffect(() => {
    setPastPayments(billingStore.paymentsHistory);
  }, [billingStore])

  useEffect(() => {
    if (userStore?.userInfo?.pm_type) {
      setCardInfo({
        last4: userStore.userInfo.pm_last_four,
        type: userStore.userInfo.pm_type
      })
    }
  }, [userStore.userInfo])

  useEffect(() => {
  }, [progressValue])

    useEffect(() => {
        if (!openModal) {
            setAddCardMode('subscribe');
        }
    }, [openModal]);

    useEffect(() => {
      dispatch(getSubscriptionInfo());
        if (localStorage.getItem('domainsCount') !== null) {
            const size = localStorage.getItem('domainsCount');
            size && setDomainsSize(+size);
        }

        dispatch(getPaymentsHistory());
        dispatch(getUserInfo())
    }, []);

    const cancelSubscription = () => {
      dispatch(deleteSubscription());
      // window.location.reload();
    };

    const handlePaymentClick = () => {
      // const userId = localStorage.getItem('id');
      // const paymentUrl = `https://buy.stripe.com/test_5kA2ap3UifSs5cQ9AB?&client_reference_id=${userId}`;
      // window.open(paymentUrl, '_blank');
      setOpenModal(true);
    };

  return {
    domainsSize,
    MAX_DOMAINS_SIZE,
    progressValue,
    setOpenModal,
    setOpenModal_,
    openModal,
    openModal_,
    addCardMode,
    setAddCardMode,
    handlePaymentClick,
    cancelSubscription,
    pastPayments,
    cardInfo,
    isFetching: billingStore.isFetching,
    billingInfo: billingStore?.subscriptionInfo
  };
};