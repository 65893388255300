import { styled } from "@mui/system";
import EllipseOne from "../../../../assets/images/icon/ellipse-1.svg";
import EllipseOneMobile from "../../../../assets/images/icon/ellipse-1-mobile.svg";
import EllipseTwo from "../../../../assets/images/icon/ellipse-2.svg";
import EllipseTwoMobile from "../../../../assets/images/icon/ellipse-2-mobile.svg";
import EllipseThree from "../../../../assets/images/icon/ellipse-3.svg";
import EllipseThreeMobile from "../../../../assets/images/icon/ellipse-3-mobile.svg";
import EllipseFour from "../../../../assets/images/icon/ellipse-4.svg";
import EllipseFourMobile from "../../../../assets/images/icon/ellipse-4-mobile.svg";
import EllipseFive from "../../../../assets/images/icon/ellipse-5.svg";
import EllipseFiveMobile from "../../../../assets/images/icon/ellipse-5-mobile.svg";

export const LogoWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 50,

  [theme.breakpoints.down("md")]: {
    marginTop: 0,
  },
}));

export const DecorativeLineWrapper = styled("div")(({ theme }) => ({
  marginTop: "100px",
  marginBottom: "100px",

  [theme.breakpoints.down("md")]: {
    marginTop: "50px",
    marginBottom: "50px",
  },
}));

export const InputWrapperStyled = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

export const StartScreenContainerStyled = styled("div")(() => ({}));

export const BackgroundOneWrapper = styled("div")(({ theme }) => ({
  background: `url(${EllipseOne}) no-repeat 50% 50%`,
  backgroundPosition: "right top",

  [theme.breakpoints.down("md")]: {
    background: `url(${EllipseOneMobile}) no-repeat 50% 50%`,
    backgroundPosition: "right top",
  },
}));

export const BackgroundTwoWrapper = styled("div")(({ theme }) => ({
  background: `url(${EllipseTwo}) no-repeat`,
  backgroundPosition: "left",
  backgroundPositionX: "0%",
  backgroundSize: "400px 400px",

  [theme.breakpoints.down("md")]: {
    background: `url(${EllipseTwoMobile}) no-repeat`,
    backgroundPosition: "left",
    backgroundPositionX: "0%",
    backgroundSize: "250px 250px",
  },

  [theme.breakpoints.down("sm")]: {
    backgroundSize: "180px 180px",
  },
}));

export const BackgroundThreeWrapper = styled("div")(({ theme }) => ({
  background: `url(${EllipseThree}) no-repeat 50% 50%`,
  backgroundPosition: "right",
  backgroundSize: "700px 700px",

  [theme.breakpoints.down("md")]: {
    background: `url(${EllipseThreeMobile}) no-repeat 50% 50%`,
    backgroundPosition: "right",
  },
}));

export const BackgroundFourWrapper = styled("div")(({ theme }) => ({
  background: `url(${EllipseFour}) no-repeat 50% 50%`,
  backgroundPosition: "left",
  backgroundPositionX: "0%",
  backgroundSize: "600px 600px",

  [theme.breakpoints.down("md")]: {
    background: `url(${EllipseFourMobile}) no-repeat`,
  },
}));

export const BackgroundFiveWrapper = styled("div")(({ theme }) => ({
  background: `url(${EllipseFive}) no-repeat 50% 50%`,
  backgroundPosition: "right bottom",
  backgroundSize: "700px 700px",

  [theme.breakpoints.down("md")]: {
    background: `url(${EllipseFiveMobile}) no-repeat 50% 50%`,
    backgroundPosition: "right bottom",
  },
}));
