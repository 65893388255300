export const COLORS = {
    MAIN_BLUE: "#224AFF",
    LIGHT_BLUE: "#224affcf",
    DARK_BLUE: "#0a36fe",

    MAIN_GREEN: "#7CEE36",
    LIGHT_GREEN: "#19DD21",

    MAIN_GRAY: "#999a9c",
    DARK_GRAY: "#1F1F1F",
    LIGHT_GRAY: "#BCBCBC",
    LIGHT_GRAY_2: "#F9FAFF",

    MAIN_PINK: "#F789D0",
    MAIN_ORANGE: "#E4B60F",
    MAIN_GRADIENT: "linear-gradient(144deg, rgba(255,209,239,1) 0%, rgba(245,250,195,1) 94%)",
}