import { styled } from "@mui/system";
import { COLORS } from "../../../utils/constants/colors";

export const Title1 = styled("div")(({ theme }) => ({
  fontSize: 35,
  fontWeight: 600,

  ".brTitle": {
    display: "none",
  },

  "span": {
    color: COLORS.MAIN_GRAY,
    fontSize: 20,
  },

  [theme.breakpoints.down(1550)]: {
    padding: 0,
    fontSize: 30,
    "span": {
      fontSize: 15,
      display: "inline-block",
    },
  },

  [theme.breakpoints.down("sm")]: {
    ".brTitle": {
      display: "inline",
    },
  },
}));

export const Title2 = styled("div")(({ theme }) => ({
  fontSize: 24,
  fontWeight: 800,
  marginBottom: 20,

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
  },
}));

export const TitleWrapperStyled = styled("div")(({ theme }) => ({
  fontSize: 35,
  fontWeight: 600,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  ".brTitle": {
    display: "none",
  },

  "span": {
    color: COLORS.MAIN_GRAY,
    fontSize: 20,
  },

  [theme.breakpoints.down(1550)]: {
    padding: 0,
    fontSize: 30,
    "span": {
      fontSize: 15,
    },
  },

  [theme.breakpoints.down("sm")]: {
    ".brTitle": {
      display: "inline",
    },
  },
}));

export const TitleContainer = styled("div")(({ theme }) => ({
  fontSize: 24,
  fontWeight: 800,
  marginBottom: 20,
  marginTop: 30,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {},

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 20,

    "button": {
      marginTop: 10,
    }
  },
}));

export const TitleWrapper = styled("div")(({ theme }) => ({
  fontSize: 24,
  fontWeight: 800,
  marginBottom: 16.5,

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
  },
}));

