import React from "react";
import { COLORS } from "../../../utils/constants/colors";
import { TitleWrapperStyled } from "../ui/styles/title.styled";

export const Title = (): React.ReactElement => {
  return (
    <TitleWrapperStyled>Billing Center <br className="brTitle"/><span style={{color: COLORS.MAIN_GRAY}}>Manage your payments and subscription</span></TitleWrapperStyled>
  );
};

export default Title;
