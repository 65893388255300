import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../utils/hooks/global";
import { ROUTES } from "./../../../pages/router/routes";
import { useNavigate } from "react-router-dom";
import { getDomainInfo } from "../api/async";

export const useStartPage = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const domainStore = useAppSelector((store) => store.domains);

  const [focusedDomainInput, setFocusedDomainInput] = useState<boolean>(false);
  const [domainName, setDomainName] = useState<any>(null);

  const onHandleGetDomainInfo = (data: any) => {
    setDomainName(data.domain_name);
    dispatch(getDomainInfo(data.domain_name));
  }

  useEffect(() => {
    if (domainStore.domainInfo?.domain) {
      navigate(ROUTES.SIGN_UP, {
        state: {
          domainInfo: {
            ...domainStore.domainInfo,
            domainName,
          }
        }
      })
    }
  }, [domainStore])

  return {
    register,
    handleSubmit,
    focusedDomainInput,
    setFocusedDomainInput,
    onHandleGetDomainInfo,
  };
};