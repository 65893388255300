import React, { memo } from "react";
import { START_SCREEN } from "../../../utils/constants/start-screen";

import { InputSearchModel } from "../utils/inputs.model";
import { CaptionStyled, FormControlStyled, ButtonStyled } from "../utils/styled";

import {
  InputWrapperStyled,
  InputWrapSearchStyled,
  InputStyled,
  InputContainerStyled,
  LabelStyled,
  ArrowBtnStyled,
} from "./styled";

const InputDomain = ({
  isLabel = false,
  disabled = false,
  error = false,
  type,
  labelText = "",
  captionText,
  variant,
  nameInput = "input",
  register,
  setFocusedDomainInput,
  typeSearch = "standart",
  placeholder = "Search",
  onHandleSubmit,
  ...props
}: InputSearchModel & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => (
  <InputContainerStyled>
    <LabelStyled>{START_SCREEN.DOMAIN_LABEL}</LabelStyled>
    <InputWrapperStyled>
      <div>{START_SCREEN.DOMAIN_PREFIX}</div>
      <InputStyled onFocus={() => setFocusedDomainInput(true)} id={nameInput} {...register}/>
      <ArrowBtnStyled onClick={onHandleSubmit}/>
    </InputWrapperStyled>
  </InputContainerStyled>
);

export default memo(InputDomain);
