import { styled } from "@mui/system";
import { ReactComponent as SpinnerIcon } from "assets/images/spinner.svg";
import { COLORS } from "utils/constants/colors";

export const SpinnerContainer = styled("div", {
  shouldForwardProp: prop => prop !== "$isActive",
})<{
  $isActive: boolean;
}>(({ $isActive }) => ({
  opacity: $isActive ? "1" : "0",
  pointerEvents: $isActive ? "initial" : "none",
  zIndex: $isActive ? "150" : "-1",
  transition: "0.2s linear",
  position: "fixed",
    top: "0",
    left: "0",
  width: "100%",
  height: "100%",
  background: "#00000091",
}));

export const Spinner = styled(SpinnerIcon)(() => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
}));
