import React from "react";
import Typed from "react-typed";
import { DesktopTyped, MobileTyped, TypedTextStyled, TypedTextWrapper } from "../ui/styles/animatedText.styled";
import 'react-typed/dist/animatedCursor.css';
import { START_SCREEN } from "../../../utils/constants/start-screen";

export const AnimatedText = ({
  focusedInput,
}: {focusedInput: boolean}): React.ReactElement => {
  return <>
    <DesktopTyped>
      <TypedTextStyled>
        <TypedTextWrapper>
          <Typed
            strings={[...START_SCREEN.TYPED_PHRASES]}
            typeSpeed={40}
            backSpeed={10}
            loop
            // smartBackspace={false}
            // cursorChar={cursorIcon}
            className="typedContainer"
            showCursor={false}
            // stopped={focusedInput}
          />
        </TypedTextWrapper>
      </TypedTextStyled>
    </DesktopTyped>

    <MobileTyped>
      <TypedTextStyled>
        <TypedTextWrapper>
          <Typed
            strings={[...START_SCREEN.TYPED_PHRASES]}
            typeSpeed={40}
            backSpeed={10}
            loop
            smartBackspace
          />
        </TypedTextWrapper>
      </TypedTextStyled>
    </MobileTyped>
  </>
};

export default React.memo(AnimatedText);
