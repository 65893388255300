import { useEffect, useState } from "react";

export const useCheckbox = ({
  defaultChecked = false,
  onChange,
  id,
}: {defaultChecked: boolean; onChange: any, id: any}) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (defaultChecked) setChecked(defaultChecked)
  }, [defaultChecked])

  const onHandleChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(id, newChecked);
  };

  return {
    onHandleChange,
    checked,
  };
};