import { styled } from "@mui/system";
import { COLORS } from "../../../../../utils/constants/colors";

export const DomainsContainer = styled("div")(({ theme }) => ({
  padding: 50,
  minHeight: 400,

  [theme.breakpoints.down(1550)]: {
    padding: 20,
  },
}));

export const PrevValuesListWrapper = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,

    "div": {
      marginBottom: 5,
    }
  },
}));

export const InputValueWrapper = styled("div")(({ theme }) => ({
  backgroundColor: COLORS.MAIN_BLUE,
  color: "white",
  padding: "5px 10px",
  marginRight: 5,
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
  },
}))

export const ButtonWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "40px",

  [theme.breakpoints.down("md")]: {
  },
}));
