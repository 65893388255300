import { styled } from "@mui/system";
import { ReactComponent as HLetter } from "../../../../assets/images/icon/h-letter.svg";
import { ExtendedThemeOptions } from "../../../../assets/theme-types";

export const HelpInfoQuestionWrapper = styled("div")(({ theme }) => ({
  textTransform: "uppercase",
  ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_124px,

  [theme.breakpoints.down("md")]: {
    ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_52px,
  },
}));

export const ParagraphWrapper = styled("div")(({ theme }) => ({
  fontSize: "36px",
  maxWidth: "75%",
  marginTop: 40,
  marginBottom: 40,
  lineHeight: "52px",

  [theme.breakpoints.down("md")]: {
    fontSize: "23px",
    lineHeight: "32px",
    maxWidth: "95%",
  },
}));

export const HLetterIcon = styled(HLetter)(({ theme }) => ({
  position: "absolute",
  width: 300,
  left: 0,
  top: -60,

  [theme.breakpoints.down("md")]: {
    width: 250,
    top: -30,
  },
}));

export const HLetterWrapperDesktop = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: -60,

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const HLetterWrapperMobile = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down("md")]: {
    display: "block",
    position: "absolute",
    left: 0,
    top: -30,
  },
}));

export const HelpInfoBlockWrapper = styled("div")(({ theme }) => ({
  maxWidth: "90%",
  paddingLeft: "20%",
  paddingRight: "20%",
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
  alignItems: "flex-end",

  [theme.breakpoints.down("lg")]: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },

  [theme.breakpoints.down("md")]: {
    paddingLeft: "4%",
    paddingRight: "4%",
  },
}));

export const HelpInfoBlockContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 150,

  [theme.breakpoints.down("md")]: {
    marginTop: 70,
  },
}));