import React from "react";
import {
  ActionsWrapperStyled,
  ButtonsWrapperStyled,
  MarginInputWrapperStyled,
  MarginWrapperStyled,
} from "../ui/styles/actions.styled";
import { useNavigate } from "react-router";
import { ButtonMain, InputSearch } from "../../../../components";
import { ROUTES } from "../../../../pages/router/routes";

export const HelperActions = ({
  onHandleClick,
  register,
  onCheckAllDomains,
  domainsSize,
  quantityInProgress,
  checkingInProgress,
}: {
  onHandleClick: any;
  register: any;
  onCheckAllDomains: any;
  domainsSize: number;
  quantityInProgress: any;
  checkingInProgress: any
}): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <ActionsWrapperStyled>
      <MarginInputWrapperStyled>
        <InputSearch
          isLabel={false}
          nameInput="search_domain"
          type="search"
          variant="search"
          typeSearch="absolute"
          register={{ ...register("search_domain") }}
          setFocusedDomainInput={() => null}
          onHandleSubmit={onHandleClick}
          className="inputSearch"
        />
      </MarginInputWrapperStyled>

      <ButtonsWrapperStyled>
        <MarginWrapperStyled>
          <ButtonMain
            title={"Add Domain"}
            variant="secondary"
            size="middle"
            onClick={() => navigate(ROUTES.ADD_DOMAIN)}
          />
        </MarginWrapperStyled>
        <MarginWrapperStyled>
          <ButtonMain
            title={"Upgrade Required"}
            variant="secondary"
            size="middle"
            onClick={() => navigate(ROUTES.BILLING)}
          />
        </MarginWrapperStyled>
        {domainsSize === 0 ? <ButtonMain
          title={"Track Another Domain"}
          variant="primary"
          size="middle"
          onClick={() => navigate(ROUTES.ADD_DOMAINS)}
        /> : <ButtonMain
        title={"Check All Certificates"}
        variant="primary"
        size="middle"
        onClick={() => onCheckAllDomains()}
        disabled={checkingInProgress === 'yes' || (quantityInProgress !== 0 && typeof quantityInProgress == "number")}
      />}
      </ButtonsWrapperStyled>
    </ActionsWrapperStyled>
  );
};

export default HelperActions;
