import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { LogoMain } from "../../../components";
import { PasswordRecoveryForm } from "./components/PasswordRecoveryForm";
import { SignUpContainerStyled, SignUpWrapperStyled } from "./utils/styles/styled";
import { recoveryPassword } from "../api/async";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/global";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "../../../pages/router/routes";
import { toast } from "react-toastify";

export const RecoveryPassword = (): React.ReactElement => {
  const { register, handleSubmit } = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((store) => store.user);
  const [domainInfo, setDomainInfo] = useState<any>(null);

  const onRecoveryPassword = (data: any) => {
    dispatch(
      recoveryPassword({
        email: data.email,
      })
    );
  };

  useEffect(() => {
    if (location?.state?.domainInfo) {
      setDomainInfo(location.state.domainInfo);
    }
  }, [location]);

  useEffect(() => {
    if (user.isRecovered && user.recoveredToken) {
      toast.success("Instructions have been sent to the email address!");
      setTimeout(() => {
        navigate(ROUTES.LOGIN);
      }, 3000)
    }
  }, [user])

  return (
    <SignUpContainerStyled>
      <SignUpWrapperStyled>
        <div style={{ marginBottom: 10, cursor: "pointer" }} onClick={() => navigate(ROUTES.HOME)}>
          <LogoMain size="small" />
        </div>
        <PasswordRecoveryForm handleSubmitToSignUp={onRecoveryPassword} />
        {/* <div style={{ marginTop: 20 }}>
          <span style={{ cursor: "pointer", color: COLORS.MAIN_BLUE }} onClick={goToSignIn}>
            {SIGN_UP.SIGN_IN}
          </span>
        </div> */}
      </SignUpWrapperStyled>
    </SignUpContainerStyled>
  );
};

export default React.memo(RecoveryPassword);
