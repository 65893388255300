import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";

import { DOMAIN_URI } from "utils/constants/api";
import { getItemFromLocalStorage } from "../utils/helpers";
import history from "utils/helpers/history";
import { ROUTES } from "../pages/router/routes";
import { Token } from "models/api/api.model";
import { ResponseErrorModel } from "models/auth/auth.model";

const instance = axios.create({
  baseURL: DOMAIN_URI,
});

instance.interceptors.request.use(async (config: any) => {
  const defaultHeaders: Record<string, string> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    // "App-Key": APP_KEY,
  };

  const token: Token = await getItemFromLocalStorage("token");
  if (token) {
    defaultHeaders["Authorization"] = `Bearer ${token}`;
  }

  return {
    ...config,
    headers: {
      ...defaultHeaders,
      ...config.headers,
    },
  };
});

const errorMessage = (error: AxiosError<ResponseErrorModel>): string =>
  Object.values(error?.response?.data?.errors || {}).toString() ||
  error?.response?.data?.message.toString() ||
  error?.toString();

instance.interceptors.response.use(
  // (response: AxiosResponse) => response,
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    console.log(error, 'error___')
    if (error.response?.data) {
      // checkIsErrorUnauthenticated(errorMessage(error as AxiosError<ResponseErrorModel>)) &&
      //   toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
      toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }

    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      history.push(ROUTES.LOGIN, { replace: true });
    }

    return Promise.reject({ ...error });
  }
);

export default instance;
