import React from "react";

import { ButtonMain, Spinner, SubTitle, Title } from "../../components";
import { DomainsContainer, ButtonWrapper, FormWrapper } from "./ui/styles/styled";
import { PasswordForm } from "./ui/PasswordForm";
import { ProfileForm } from "./ui/ProfileForm";
import { useUserProfile } from "./utils/hook";
import { TitleContainer } from "../../components/titles/utils/styled";
import { Modal } from "@mui/material";
import { ModalWrapper } from "../../components/modal/styled";

export const Domains = (): React.ReactElement => {
  const {
    register,
    registerForm1,
    watch,
    watchForm1,
    handleSubmit,
    handleSubmitForm1,
    loading,
    openModal,
    setOpenModal,
    onHandleDeleteUser,
    userInfo,
    onHandleUpdateUserInfo,
    onHandleChangePassword,
  } = useUserProfile();

  return (
    <>
    <Spinner isLoading={loading} />
    <DomainsContainer>
      <Title title={"Edit Your Account"}/>
      <SubTitle style={{ marginTop: 30 }} title={"General Information"} />
      <FormWrapper>
        {userInfo ? <ProfileForm userInfo={userInfo} register={register} watch={watch}/> : null}
      </FormWrapper>
      <ButtonMain variant="primary" title="Update" size="small" onClick={handleSubmit(onHandleUpdateUserInfo)} />
      <div style={{marginTop: 70, marginBottom: 70}}>
      <TitleContainer style={{ marginTop: 30 }}>Password Settings</TitleContainer>
      <FormWrapper>
        {userInfo ? <PasswordForm userInfo={userInfo} register={registerForm1} watch={watchForm1}/> : null}
      </FormWrapper>
      <ButtonMain variant="primary" title="Change Password" size="small" onClick={handleSubmitForm1(onHandleChangePassword)} />
      </div>
      <TitleContainer style={{ marginTop: 30 }}>Delete your SSLMemo Account</TitleContainer>
      <ButtonMain
        variant="primary"
        title="Delete My Account"
        size="small"
        onClick={() => setOpenModal(true)}
      />
      {/* <Modal openModal={openModal} setOpenModal={setOpenModal} /> */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapper>
          <h2 style={{ fontSize: 30, textAlign: "center", marginBottom: 60 }}>
            Are you sure that you want to delete your account?
          </h2>
          <ButtonWrapper>
            <ButtonMain
              title="Back"
              variant="secondary"
              size="small"
              onClick={() => setOpenModal(false)}
              style={{ marginRight: 10 }}
            />
            <ButtonMain
              title="Confirm"
              variant="primary"
              size="small"
              onClick={() => {
                onHandleDeleteUser();
                setOpenModal(false);
              }}
            />
          </ButtonWrapper>
        </ModalWrapper>
      </Modal>
    </DomainsContainer>
    </>
  );
};

export default React.memo(Domains);
