import { createSlice } from "@reduxjs/toolkit";
import { getItemFromLocalStorage } from "../../../utils/helpers";
import { RootState } from "store/store";
import { getNotifications, setNotifByEmail, setNotifSchedule, setNotifTypes } from "./async";

const token = getItemFromLocalStorage("token");

const initialState: any = {
  isLoggedIn: !!token && !!token.access_token,
  isFetching: false,
  isUpdated: false,
  isDeleted: false,
  userInfo: null,
  isRecovered: false,
  notificationSettings: null,
};

const notificationsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [getNotifications.pending.type]: state => {
      state.isFetching = true;
    },
    [getNotifications.fulfilled.type]: (state, action) => {
      state.notificationSettings = action.payload.data.notification_settings;
      state.isFetching = false;
    },
    [getNotifications.rejected.type]: state => {
      state.isFetching = false;
    },
    [setNotifTypes.fulfilled.type]: (state, action) => {
      state.notificationSettings = action.payload.data.notification_settings;
      state.isFetching = false;
    },
    [setNotifByEmail.fulfilled.type]: (state, action) => {
      state.notificationSettings = action.payload.data.notification_settings;
      state.isFetching = false;
    },
    [setNotifSchedule.fulfilled.type]: (state, action) => {
      state.notificationSettings = action.payload.data.notification_settings;
      state.isFetching = false;
    },
  }
});

export const userSelector = (state: RootState): RootState["user"] => state.user;

const { reducer } = notificationsSlice;

export default reducer;
