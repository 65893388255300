import React from "react";
import { Outlet } from "react-router-dom";

import { ButtonMain, Title } from "components";

import { DomainsContainer, ButtonWrapper } from "./ui/styles/styled";
import HelperActions from "./ui/HelperActions";
import { DomainsList } from "./ui/DomainsList";
import { Spinner } from "components";
import { useDomainsList } from "./utils/hook";
import { DOMAINS_LIST } from "./utils/constants";
import { Pagination } from "@mui/material";

export const Domains = (): React.ReactElement => {
  const {
    showChild,
    onHandleClick,
    register,
    onHandleCheckAllDomains,
    domains,
    quantityInProgress,
    loading,
    domainsInProgress,
    onHandleExportAll,
    onChangePage,
    page,
    totalCount,
    checkingInProgress,
    showOrHideUntrackedDomains,
    showAll,
  } = useDomainsList();

  return (
    <>
      {!showChild ? (
        <DomainsContainer>
          <Title title={DOMAINS_LIST.TITLE} subtitle={DOMAINS_LIST.SUBTITLE} />
          <HelperActions
            onHandleClick={onHandleClick}
            register={register}
            onCheckAllDomains={onHandleCheckAllDomains}
            domainsSize={domains.length}
            quantityInProgress={quantityInProgress}
            checkingInProgress={checkingInProgress}
          />
          <Spinner isLoading={loading} />
          <DomainsList domains={domains} domainsInProgress={domainsInProgress} currentPage={page} onChangePage={onChangePage} totalCount={totalCount} checkingInProgress={checkingInProgress} showOrHideUntrackedDomains={showOrHideUntrackedDomains} showAll={showAll} />
          <ButtonWrapper style={{justifyContent: 'space-between', alignItems: 'center'}}>
            <Pagination count={Math.ceil(totalCount / 10)} variant="outlined" page={page} onChange={onChangePage} />
            <ButtonMain variant="add" title={DOMAINS_LIST.BTN_EXPORT} size="small" onClick={onHandleExportAll} />
          </ButtonWrapper>
        </DomainsContainer>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default React.memo(Domains);
