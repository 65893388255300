import {styled, keyframes, css, CSSObject, DefaultTheme} from "@mui/system";

import { InputWrapStyled } from "../utils/styled";
import { ReactComponent as ArrowBtn } from "../../../assets/images/icon/arrow-btn.svg";
import { ExtendedThemeOptions } from "../../../assets/theme-types";
import { COLORS } from "../../../utils/constants/colors";

export const InputWrapSearchStyled = styled(InputWrapStyled)({
  minWidth: "44px",
  height: "44px",
  position: "relative",
});

export const InputContainerStyled = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap-reverse",
    padding: "5px 10px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0px",
  },
}))

export const InputValueWrapper = styled("div")(({ theme }) => ({
  backgroundColor: COLORS.MAIN_BLUE,
  color: "white",
  padding: "5px 10px",
  marginRight: 5,
  fontWeight: 600,

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
  },
}))

export const PrevValuesListWrapper = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}))

// export const InputWrapperStyled = styled("div")(({ theme, $error: boolean }) =>({
//   border: "1px solid #224aff",
//   display: "flex",
//   alignItems: "center",
//   padding: "10px 20px",
//   height: "50px",
//   boxSizing: "border-box",
//   fontSize: "16px",
//   color: "#224aff",
//   fontStyle: "italic",
//   position: "relative",
//
//   $error
//
//   [theme.breakpoints.down("sm")]: {
//     padding: "15px 24px",
//     fontSize: "16px",
//   },
// }));

// export const InputWrapperStyled = styled("div")(({ theme, $error }): {$error: boolean} => ({
//   border: "1px solid #224aff",
//   display: "flex",
//   alignItems: "center",
//   padding: "10px 20px",
//   height: "50px",
//   boxSizing: "border-box",
//   fontSize: "16px",
//   color: "#224aff",
//   fontStyle: "italic",
//   position: "relative",
//   animation: $error ? `${borderColorAnimation} 3s ease-in-out` : false,
//
// [theme.breakpoints.down("sm")]: {
//   padding: "15px 24px",
//       fontSize: "16px",
// },
// }));

const borderColorAnimation = keyframes`
  0%, 70% {
    border-color: #224aff;
    border-width: 1px;
  }
  10% {
    border-color: #f07070;
    border-width: 2px;
  }
`;

// export const InputWrapperStyled = styled("div")<InputWrapperProps>(({ theme, $error }) => ({
//   border: "1px solid #224aff",
//   display: "flex",
//   alignItems: "center",
//   padding: "10px 20px",
//   height: "50px",
//   boxSizing: "border-box",
//   fontSize: "16px",
//   color: "#224aff",
//   fontStyle: "italic",
//   position: "relative",
//   animation: $error
//       ? css`
//         ${borderColorAnimation} 10s ease-in-out
//       `
//       : "none",
//
//   [theme.breakpoints.down("sm")]: {
//     padding: "15px 24px",
//     fontSize: "16px",
//   },
// }));

// interface InputWrapperProps {
//   isError: boolean;
// }


interface InputWrapperProps {
  "data-is-error": boolean;
}

// export const InputWrapperStyled = styled("div")<InputWrapperProps>(({ theme, isError }) => ({
//   border: "1px solid #224aff",
//   display: "flex",
//   alignItems: "center",
//   padding: "10px 20px",
//   height: "50px",
//   boxSizing: "border-box",
//   fontSize: "16px",
//   color: "#224aff",
//   fontStyle: "italic",
//   position: "relative",
//   animation: isError
//       ? css`
//         ${borderColorAnimation} 10s ease-in-out
//       `
//       : "none",
//   [theme.breakpoints.down("sm")]: {
//     padding: "15px 24px",
//     fontSize: "16px",
//   },
// }));

// export const InputWrapperStyled = styled("div", {
//   shouldForwardProp: prop => prop !== "isError",
// })<{
//   isError: boolean;
// }>(({ isError, theme }) => ({
//   border: "1px solid #224aff",
//   display: "flex",
//   alignItems: "center",
//   padding: "10px 20px",
//   height: "50px",
//   boxSizing: "border-box",
//   fontSize: "16px",
//   color: "#224aff",
//   fontStyle: "italic",
//   position: "relative",
//   animation: isError
//       ? css`
//         ${borderColorAnimation} 10s ease-in-out
//       `
//       : "none",
//   [theme.breakpoints.down("sm")]: {
//     padding: "15px 24px",
//     fontSize: "16px",
//   },
// }));

export const InputWrapperStyled = styled("div", {
  shouldForwardProp: (prop) => prop !== "isError",
})<{
  isError: boolean;
}>`
  ${({ theme, isError }) => `
    border: 1px solid #224aff;
    display: flex;
    align-items: center;
    padding: ${theme.spacing(1)};
    height: 50px;
    box-sizing: border-box;
    font-size: 16px;
    color: #224aff;
    font-style: italic;
    position: relative;
    animation: ${isError
    ? css`
          ${borderColorAnimation} 10s ease-in-out
        `
    : "none"};

    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(2)};
      font-size: 16px;
    }
  `}
`;

export const LabelStyled = styled("span")(({ theme }) => ({
  color: COLORS.MAIN_PINK,
  marginBottom: 7,
  textTransform: "uppercase",
  fontWeight: 700,

  // ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_16px,

  [theme.breakpoints.down("md")]: {
    ...(theme.typography as ExtendedThemeOptions["typography"]).inter_700_12px,
  },
}))

export const ArrowBtnStyled = styled(ArrowBtn)(({ theme }) => ({
  width: 30,
  height: 30,
  position: "absolute",
  right: "25px",
  cursor: "pointer",

  [theme.breakpoints.down("sm")]: {
    width: 20,
    height: 20,
  },
}));


export const InputStyled = styled("input")(({ theme }) => ({
  border: "none",
  fontSize: "20px",
  padding: "0px",
  color: "#224aff",
  fontStyle: "italic",
  width: "100%",
  marginLeft: 5,
  backgroundColor: "#ffffff00",

  "&:focus, &:active": {
    border: "none",
    outline: "none",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    marginLeft: 2,
  },
}));
