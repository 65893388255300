import React from "react";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../pages/router/routes";
import { START_SCREEN } from "../../../utils/constants/start-screen";
import {
  FooterCopyright,
  FooterNavItem,
  FooterWrapper,
  NavFooterMenu,
} from "../ui/styles/footer.styled";
import twitterIcon from "../../../assets/images/icon/twitter.svg";

export const Footer = (): React.ReactElement => {
  const navigate = useNavigate();
  return <div>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <FooterWrapper>
        <FooterCopyright>
          {START_SCREEN.COPYRIGHT.YEAR}{" "}
          <span style={{ color: "#224AFF" }}>{START_SCREEN.COPYRIGHT.COMPANY}</span>{" "}
          {START_SCREEN.COPYRIGHT.RESERVED}
        </FooterCopyright>

        <NavFooterMenu>
          <FooterNavItem $name="">Terms of Use</FooterNavItem>
          <FooterNavItem $name="">Privacy Policy</FooterNavItem>
          <FooterNavItem $name="sign-up" onClick={() => navigate(ROUTES.SIGN_UP)}>Sign Up</FooterNavItem>
          <FooterNavItem $name="sign-in" onClick={() => navigate(ROUTES.LOGIN)}>Sign In</FooterNavItem>
          <div style={{ paddingLeft: "10px", cursor: "pointer" }}><img src={twitterIcon} width={20}/></div>
        </NavFooterMenu>
      </FooterWrapper>
    </div>
  </div>
};

export default Footer;
