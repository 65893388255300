import { useEffect } from "react";

import { useAppSelector, useAppDispatch } from "../../../utils/hooks/global";
import { getNotifications } from "../api/async";

export const useNotificationSettings = () => {
  const dispatch = useAppDispatch();
  const { notificationSettings, isFetching } = useAppSelector(store => store.notifications);

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  return {
    notificationSettings,
    isFetching,
  };
};