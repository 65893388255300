import { styled } from "@mui/system";

export const TemplateContainer = styled("div")(({ theme }) => ({
  marginBottom: 55,
  // width: "50%",
  position: 'relative',

  // [theme.breakpoints.down("md")]: {
  //   // margin: 0,
  //   width: "100%",
  //   "&.orderOne": {
  //     order: 1
  //   }
  // },
}));

export const TitleContainer = styled("div")(({ theme }) => ({
  fontSize: 24,
  fontWeight: 800,
  marginBottom: 16.5,

  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
  },
}));

export const SelectionWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
  },

  [theme.breakpoints.down("sm")]: {
  },
}));

export const ButtonWrapper = styled("div")(({ theme }) => ({
  marginTop: 30,

  [theme.breakpoints.down("md")]: {
    marginTop: 15,
  },

  [theme.breakpoints.down("sm")]: {
  },
}));
