import React from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import {
  BlueHeadTitle,
  DomainName,
  IconWrapper,
} from "../ui/styles/domainsList.styled";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

export const PaymentsHistory = ({billingInfo, payments}: any): React.ReactElement => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  function createData(
    name: string,
    numericalValue: string | number,
    issuer: string | null,
    expiryDate: string,
    chackedDate: string,
    hostname: "error" | "warning" | "success"
  ) {
    return { name, numericalValue, issuer, expiryDate, chackedDate, hostname };
  }

  const rows = payments;

  return (
    <TableContainer className="domainsList">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <BlueHeadTitle>Plan</BlueHeadTitle>
            </TableCell>
            <TableCell>
              <BlueHeadTitle>Payment Date</BlueHeadTitle>
            </TableCell>
            <TableCell>
              <BlueHeadTitle>Order number</BlueHeadTitle>
            </TableCell>
            <TableCell align="center">
              <BlueHeadTitle>Payment Amount</BlueHeadTitle>
            </TableCell>
            <TableCell align="right">
              <BlueHeadTitle>Status</BlueHeadTitle>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 ? rows.map((row: any) => (
            <TableRow key={row.plan} style={{cursor: "pointer"}}>
              <TableCell>
                <DomainName>{row.plan}</DomainName>
              </TableCell>
              <TableCell>{row.payment_date}</TableCell>
              <TableCell>{row.order_number}</TableCell>
              <TableCell align="center">{row.payment_amount}</TableCell>
              <TableCell align="right">
                <IconWrapper $color={row.hostname} $size={'small'}>
                  {row.status ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
                </IconWrapper>
              </TableCell>
            </TableRow>
          )) : <div style={{color: '#224aff5c', marginTop: 10, marginBottom: 10}}>Your payment history will appear here.</div>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentsHistory;
