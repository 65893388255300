import React, { memo } from "react";
import { START_SCREEN } from "../../../utils/constants/start-screen";

import { InputSearchModel } from "../utils/inputs.model";
import { CaptionStyled, FormControlStyled, ButtonStyled } from "../utils/styled";

import {
  InputWrapperStyled,
  InputWrapSearchStyled,
  InputStyled,
  InputContainerStyled,
  LabelStyled,
  SearchBtnStyled,
} from "./styled";

const InputSearch = ({
  isLabel = false,
  disabled = false,
  error = false,
  type,
  labelText = "",
  captionText,
  variant,
  nameInput = "input",
  register,
  setFocusedDomainInput,
  typeSearch = "standart",
  placeholder = "Search",
  onHandleSubmit,
  className,
  ...props
}: InputSearchModel & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => (
  <InputContainerStyled className={className}>
    <InputWrapperStyled>
      <InputStyled onFocus={() => setFocusedDomainInput(true)} id={nameInput} {...register} onKeyPress={(event) => {
        if (event.key === "Enter") {
          onHandleSubmit();
        }
      }} onKeyUp={(event) => {
        if (event.key === "Enter" || (event.target as HTMLInputElement)?.value === "") {
          onHandleSubmit();
        }
      }}/>
      <SearchBtnStyled onClick={onHandleSubmit}/>
    </InputWrapperStyled>
  </InputContainerStyled>
);

export default memo(InputSearch);
