import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useAppSelector, useAppDispatch } from "../../../../utils/hooks/global";
import { history } from "../../../../utils/helpers";
import { ROUTES } from "./../../../../pages/router/routes";
import { addAdditionalEmails, checkDomain, clearDomainInfo, editDomainName, getAdditionalEmails, getDomainFullInfo, getDomainHistory, stopTrackingDomain, startTrackingDomain } from "../../api/async";
import { getUserInfo } from "pages/user-profile/api/async";
import { deleteDomain } from "../../api/async";
import { toast } from "react-toastify";

export const useDomainDetail = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const domainsStore = useAppSelector(store => store.domains);
  const userStore = useAppSelector(store => store.user);

  const [keyShow, setKeyShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [valuesList, setValuesList] = useState<Array<string>>([]);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [copiedEncoded, setCopiedEncoded] = useState<boolean>(false);
  const [copiedDecoded, setCopiedDecoded] = useState<boolean>(false);
  const [domainInfo, setDomainInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [progressLoading, setProgressLoading] = useState<boolean>(false);
  // const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<any>({
    opened: false,
    title: "",
    backTitle: "",
    confirmTitle: "",
  });
  const [notificationId, setNotificationId] = useState<any>(null);
  const [notes, setNotes] = useState<any>(null);
  const [domainName, setDomainName] = useState<any>(null);

  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(getDomainHistory({id: location?.state?.domainId, page}))
  }, [page])

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getAdditionalEmails());

    return () => {
      setDomainInfo(null);
      dispatch(clearDomainInfo());
    }
  }, []);

  useEffect(() => {
  }, [notes]);

  useEffect(() => {
    setLoading(userStore.isFetching);

    if (userStore.userInfo && userStore?.userInfo?.notification_settings?.id) {
      setNotificationId(userStore.userInfo.notification_settings.id);
    }
  }, [userStore]);

  useEffect(() => {
    if (location?.state?.domainId) {
      dispatch(getDomainFullInfo(location?.state?.domainId));
      dispatch(getDomainHistory({id: location?.state?.domainId, page}))
    }
  }, [location]);

  useEffect(() => {
    setLoading(domainsStore.isFetching);
    setProgressLoading(!domainsStore.isChecked);

    if (domainsStore.checkingInProcess === "no") {
      window.location.reload();
    }

    if (domainsStore.domainInfo) {
      setDomainInfo(domainsStore.domainInfo);
      setDomainName(domainsStore.domainInfo.name);
    }

    // if (domainsStore.domainInfo && domainsStore.domainInfo?.alternative_emails) {
    //   setValuesList(JSON.parse(domainsStore.domainInfo?.alternative_emails));
    // }

    setNotes(domainsStore.domainInfo?.notes);
  }, [domainsStore]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const onHandleChangeTab = (ev: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const unsecuredCopyToClipboard = (text: any) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};

  const copyKey = (keyName: string) => {
    let key_ = keyName === "encodedKey" ? domainInfo?.ssl_encoded : domainInfo?.ssl_decoded;

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(key_);
      setTimeout(() => {
        if (keyName === "encodedKey") {
          setCopiedEncoded(true);
          setCopiedDecoded(false);
        } else {
          setCopiedEncoded(false);
          setCopiedDecoded(true);
        }
      }, 200);
    } else {
      unsecuredCopyToClipboard(key_);
      setTimeout(() => {
        if (keyName === "encodedKey") {
          setCopiedEncoded(true);
          setCopiedDecoded(false);
        } else {
          setCopiedEncoded(false);
          setCopiedDecoded(true);
        }
      }, 200);
    }
  };

  const onDeletePrevValue = (prevValue: string) => {
    const valuesList_ = valuesList.filter((value: string) => value !== prevValue);
    setValuesList(valuesList_);
  };

  const onHandleTracking = () => {
    if (domainsStore?.domainInfo?.is_tracking) {
      dispatch(stopTrackingDomain(domainInfo.id));
    } else {
      dispatch(startTrackingDomain(domainInfo.id));
    }
  };

  const onHandleCheckAllDomains = () => {
    dispatch(checkDomain({id: domainInfo.id}));
  };

  const onHandleAddEmails = (data: any) => {
    const textareaInputElement = document.getElementById("domain_notes");
    const notesCurrentValue = textareaInputElement?.textContent;

    dispatch(
      addAdditionalEmails({
        alternative_emails: JSON.stringify(valuesList),
        id: location?.state?.domainId,
        domain_name: domainName,
        notes: notesCurrentValue,
      })
    );

    setTimeout(() => {
      setValuesList([]);
    }, 2000)
  };

  const onHandleDeleteDomain = () => {
    dispatch(deleteDomain({id: location?.state?.domainId}));
  }

  useEffect(() => {
    if (domainsStore.isDeletedDomain) {
      setTimeout(() => {
        history.push(ROUTES.DASHBOARD, { replace: true });
        window.location.reload();
      }, 2000)
    }
  }, [domainsStore])

  const onHandleEditDomainName = (data: any) => {
    if (data.domain_title?.length > 0) {
      dispatch(editDomainName({id: location?.state?.domainId, title: data.domain_title}));
      setDomainName(data.domain_title);
      toast.success("Domain name was successfully changed!")
      setTimeout(() => {
        navigate(ROUTES.DASHBOARD, {
          state: {
            neededRefreshPage: true,
          }
        });
      }, 2000)
    }
  }

  return {
    register,
    loading,
    navigate,
    handleSubmit,
    onHandleEditDomainName,
    onHandleDeleteDomain,
    onHandleCheckAllDomains,
    onDeletePrevValue,
    onHandleTracking,
    onHandleAddEmails,
    copyKey,
    onHandleChangeTab,
    a11yProps,
    domainInfo,
    keyShow,
    setKeyShow,
    location,
    progressLoading,
    selectedTab,
    copiedDecoded,
    copiedEncoded,
    domainsStore,
    setOpenModal,
    setValuesList,
    valuesList,
    setEmailError,
    emailError,
    openModal,
    domainName,
    notes,
    domainChecks: domainsStore.domainChecks,
    page, onChangePage,
  };
};