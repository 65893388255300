import React, { useEffect } from "react";

import { ButtonMain, Spinner } from "../../components";

import { DomainsContainer, BorderLinearProgress, ProgressWrapper, IndicatorResult, IndicatorResultInner } from "./ui/styles/styled";
import { Title } from "./ui/Title";
import { TitleContainer } from "../../pages/notification-settings/ui/styles/selections.styled";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { IconWrapper } from "./ui/styles/domainsList.styled";
import {
  PackageBody,
  PackageHeader,
  PackagePoint,
  PackagePriceContainer,
  PriceText,
} from "./ui/styles/pricing.styled";
import { COLORS } from "../../utils/constants/colors";
import { ReactComponent as CheckIcon } from "../../assets/images/icon/check-mark-blue.svg";
import BillingModal from "./ui/BillingModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import editIcon from "../../assets/images/icon/edit.svg";
import {ModalWrapper} from "../../pages/user-profile/ui/styles/modal.styled";
import {ButtonWrapper} from "../../pages/user-profile/ui/styles/styled";
import {Modal} from "@mui/material";
import PaymentsHistory from "./ui/PaymentsHistory";
import { useBilling } from "./utils/hook";

//publish key
const stripePromise = loadStripe(
'pk_test_51OX11EGoHCb3bSkt624VqeMZ9P3svWutqfqhQmOamjqwfEqjaZW9CofgWdiKedIlm8pXxw9NkouD70WOYns3enWn00lOpwxOwf'
);

export const Billing = (): React.ReactElement => {
  const {
    domainsSize,
    MAX_DOMAINS_SIZE,
    progressValue,
    setOpenModal,
    setOpenModal_,
    openModal,
    openModal_,
    addCardMode,
    setAddCardMode,
    isFetching,
    billingInfo,
    handlePaymentClick,
    pastPayments,
    cancelSubscription,
    cardInfo,
  } = useBilling();

  const isUnlimited = billingInfo?.name === 'unlimited' || billingInfo?.name === 'pro';

  return (
    <DomainsContainer>
      <Spinner isLoading={isFetching} />
      <Title />
      <TitleContainer>Usage</TitleContainer>
      <p>
        You are currently tracking <span style={{ fontWeight: 700 }}>{domainsSize} domain</span>. Your plan
        allows you to track up to <span style={{ fontWeight: 700 }}>{billingInfo?.quantity_tracked_domains ?? 3} domains</span>.
      </p>

      <ProgressWrapper>
        <BorderLinearProgress variant="determinate" value={progressValue} />
        <div style={{width: "100%", transform: `translateX(-${100-progressValue}%)`, position: "relative"}}>
            {domainsSize > 0 && <IndicatorResult> 
              <IndicatorResultInner />
              <span style={{fontWeight: 'bold'}}>{domainsSize}</span>&nbsp;
              <span>of</span>&nbsp;
              <span style={{fontWeight: 'bold'}}> {billingInfo?.quantity_tracked_domains ?? 3}</span>
              {/* <IndicatorResultInner /> */}
            </IndicatorResult>}
        </div>
      </ProgressWrapper>
      <div style={{ marginTop: 80, display: "flex", alignItems: "center" }}>
        <IconWrapper $size="small" $color={isUnlimited ? "success" : "error"} style={{ marginRight: 10 }}>
          {isUnlimited ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
        </IconWrapper>{" "}
        <span style={{ fontSize: 14, fontWeight: 600 }}>{isUnlimited ? 'Your plan includes Slack Integration' : `Your plan does not include Slack Integration`}</span>
      </div>
      <div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
        <IconWrapper $size="small" $color={(billingInfo?.name === 'trial' || !billingInfo) ? "error" : "success"} style={{ marginRight: 10 }}>
        {isUnlimited ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
        </IconWrapper>{" "}
        <span style={{ fontSize: 14, fontWeight: 600 }}>
          {(billingInfo?.name === 'trial' || !billingInfo) ? `Your plan does not include Microsoft Teams Integration`
          : 'Your plan includes Microsoft Teams Integration'}
        </span>
      </div>
      <div style={{ marginBottom: 40, marginTop: 20, display: "flex", alignItems: "center" }}>
        <IconWrapper $size="small" $color={isUnlimited ? "success" : "error"} style={{ marginRight: 10 }}>
          {isUnlimited ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
        </IconWrapper>{" "}
        <span style={{ fontSize: 14, fontWeight: 600 }}>
          {isUnlimited ? `Your plan includes Additional Email Notification Addresses`
          : `Your plan does not include Additional Email Notification Addresses`}
        </span>
      </div>

      {(billingInfo?.name === 'trial' || !billingInfo) && <><TitleContainer>Plans and Pricing</TitleContainer>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginBottom: 40,
        }}
      >
        <Package
          title={"PRO"}
          price={true}
          includeList={[
            "Monitor up to 20 SSL Certificates Expiration or Changes",
            "Email / Slack notifications",
          ]}
          index={1}
          isButton={true}
          openModal={setOpenModal}
        />
        <Package title={"NOTES"} index={2} maxDomains={billingInfo?.quantity_tracked_domains}/>
      </div></>}

      {isUnlimited && <><TitleContainer>Subscription Info</TitleContainer>
        <div>
          <div className="billingItem">Your Plan: <div>$15 / month</div></div>
          <div className="billingItem">Next Transaction Date: <div>{mockData.next_payment_at}</div></div>
          <div className="billingItem billingItemLast">Payment Method: 
            <div>
              {cardInfo ? `${cardInfo.type} | Card ending in ${cardInfo.last4} ` : 'Add payment method'}
              <img 
              onClick={() => {
                setAddCardMode('changeCard');
                setOpenModal(true);
              }} 
              src={editIcon} 
              style={{position: 'absolute',marginLeft: 10, cursor: 'pointer'}}/>
            </div>
          </div>
        </div>

        <div style={{color: "#f07070", fontSize: 17, cursor: "pointer", textDecoration: "underline", marginBottom: 40}} onClick={() => setOpenModal_(true)}>
            Cancel subscription
        </div></>}

        <TitleContainer>Payments History</TitleContainer>

        <PaymentsHistory billingInfo={billingInfo} payments={pastPayments} />

      <Elements stripe={stripePromise}>
      <BillingModal setOpenModal={setOpenModal} openModal={openModal} title={addCardMode === 'subscribe' ? 'Add Payment Method & Subscribe' : 'Change Payment Method'}/>
      </Elements>

        <Modal
            open={openModal_}
            onClose={() => setOpenModal_(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalWrapper>
                <h2 style={{ fontSize: 30, textAlign: "center", marginBottom: 60 }}>
                    Are you sure that you want to unsubscribe?
                </h2>
                <ButtonWrapper>
                    <ButtonMain
                        title="Back"
                        variant="secondary"
                        size="small"
                        onClick={() => setOpenModal_(false)}
                        style={{ marginRight: 10 }}
                    />
                    <ButtonMain
                        title="Confirm"
                        variant="primary"
                        size="small"
                        onClick={() => {
                          cancelSubscription();
                            setOpenModal_(false);
                        }}
                    />
                </ButtonWrapper>
            </ModalWrapper>
        </Modal>
    </DomainsContainer>
  );
};

const Package = ({
  title,
  price,
  includeList,
  index,
  isButton,
  openModal,
  maxDomains,
}: {
  title: string;
  price?: boolean;
  includeList?: string[];
  index: number;
  isButton?: boolean;
  openModal?: any;
  maxDomains?: any;
}) => {
  const { handlePaymentClick } = useBilling();

  return (
    <PackagePriceContainer style={{ color: "white" }} $index={index}>
      <PackageHeader>{title}</PackageHeader>
      <PackageBody
        $variant={includeList ? "standart" : "white"}
        style={{
          backgroundColor: "#F6F6F6",
          color: "black",
          justifyContent: includeList ? "space-between" : "initial",
        }}
      >
        {includeList && (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {price && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingRight: 20,
                }}
              >
                <PriceText style={{ color: COLORS.MAIN_BLUE }}>$15</PriceText>{" "}
                <span style={{ color: COLORS.DARK_GRAY, opacity: 0.6 }}>per month</span>
              </div>
            )}
            {includeList && (
              <div style={{ borderLeft: `1px solid ${COLORS.LIGHT_GRAY}`, paddingLeft: 10 }}>
                {includeList.map((item: string) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "unset",
                        marginBottom: 15,
                        fontSize: 20,
                      }}
                    >
                      <CheckIcon width="20" height="20" style={{ flex: 1 }} />
                      <PackagePoint>{item}</PackagePoint>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {isButton && (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <ButtonMain title={"Subscribe"} variant="primary" onClick={() => openModal(true)}/>
          </div>
        )}

        {!price && (
          <>
            <p style={{ lineHeight: "27px" }}>
              Have more than <span style={{ fontWeight: 700 }}>500 domains</span> to monitor?
              Enterprise needs?{" "}
              <span style={{ fontWeight: 700, color: COLORS.MAIN_BLUE, cursor: "pointer" }}>
                Contact Us
              </span>{" "}
              to discuss a custom plan.
            </p>
            <p style={{ lineHeight: "27px" }}>
              Or you can continue on the free plan that lets you track{" "}
              <span style={{ fontWeight: 700 }}>{maxDomains} domains</span>.
            </p>
          </>
        )}
      </PackageBody>
    </PackagePriceContainer>
  );
};

export default React.memo(Billing);

const mockData: any = {
    next_payment_at: "2024-02-24",
    cardInfo: {
        "id": "card_1OcZoHEpspFkSLETSfm8zpho",
        "object": "card",
        "brand": "Visa",
        "country": "US",
        "exp_month": 11,
        "exp_year": 2026,
        "last4": "4242",
        "cardType": null,
        "dynamic_last4": null,
        "name": "test dev",
        "default": true,
        "fingerprint": "YHeJKFcEMmKx5Obr"
    }}
