import React from "react";

import { SIGN_UP } from "utils/constants/auth";
import verifyIcon from "assets/images/icon/verify-company.png";
import checkIcon from "assets/images/icon/check-mark.png";
import {
  ExpireCompanyStyled,
  ExpireContainerStyled,
  ExpireDashedContainerStyled,
  ExpireDashedLineStyled,
  ExpireDashedOptionStyled,
  ExpireDomainInfoStyled,
  ExpireInfoRowStyled,
  ExpireTitleStyled,
} from "../utils/styles/domainInfo.styled";

export const DomainInfo = ({domainInfo}: {domainInfo: any}): React.ReactElement => {
  return (
    <ExpireContainerStyled>
      <ExpireTitleStyled>Certificate Expires In {domainInfo.expires_in} Days</ExpireTitleStyled>
      <ExpireCompanyStyled>
        <img src={verifyIcon} alt="verify company" width={20} style={{marginRight: 10}}/>
        <span>{domainInfo.domainName}</span>
      </ExpireCompanyStyled>
      <ExpireDomainInfoStyled>
        {[1, 2, 3, 4, 5].map((item: number) => {
          return (
            <ExpireInfoRowStyled key={`opt-${item}`}>
              <ExpireDashedContainerStyled>
                <ExpireDashedOptionStyled>{SIGN_UP[`OPT_${item}`]}</ExpireDashedOptionStyled>
                <ExpireDashedLineStyled />
              </ExpireDashedContainerStyled>

              <div style={{display: "flex", alignItems: "center"}}>
              <img src={checkIcon} alt="check info" width={20} style={{marginRight: 5}}/>
                <span style={{fontSize: 16, fontWeight: 900, whiteSpace: "nowrap",}}>{
                  item === 1 ? domainInfo.issued_at_formatted
                  : item === 2 ? domainInfo.expiration_formatted
                  : item === 3 ? domainInfo.issuer
                  : item === 4 ? 'Yes'
                  : '443'
                }</span>
              </div>
            </ExpireInfoRowStyled>
          );
        })}
      </ExpireDomainInfoStyled>
    </ExpireContainerStyled>
  );
};

export default React.memo(DomainInfo);
