import { styled } from "@mui/system";

export const ActionsWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 40,
  marginBottom: 60,

  [theme.breakpoints.down(1550)]: {
    flexWrap: "wrap",
    marginBottom: 40,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
    marginTop: 10,
    marginBottom: 10,
  },
}));

export const ButtonsWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "space-between",
    marginTop: "10px",
    flexWrap: "nowrap",
  },
}));

export const MarginWrapperStyled = styled("div")(({ theme }) => ({
  marginRight: 10,

  [theme.breakpoints.down("md")]: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export const MarginInputWrapperStyled = styled("div")(({ theme }) => ({
  marginRight: 10,

  [theme.breakpoints.down(1550)]: {
    // flex: 1,
    width: "100%",
    marginRight: 0,
    marginBottom: 10,

    ".inputSearch": {
      width: "initial",
      padding: 0,
    },
  },

  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
    flex: 1,
  },
}));
