import React from "react";
import hLetter from "../../../assets/images/icon/h-letter.png";
import { ButtonMain } from "../../../components";
import {
  HelpInfoBlockContainer,
  HelpInfoBlockWrapper,
  HelpInfoQuestionWrapper,
  HLetterWrapperDesktop,
  HLetterWrapperMobile,
  ParagraphWrapper,
} from "../ui/styles/helpInfo.styled";
import { START_SCREEN } from "../../../utils/constants/start-screen";

export const HelpInfoBlock = (): React.ReactElement => (
  <HelpInfoBlockContainer>
    <HelpInfoBlockWrapper>
      <div style={{ position: "relative", width: "100%" }}>
        <HelpInfoQuestionWrapper>
        {START_SCREEN.H_TITLE.map((word: string) => {
          return <div key={word}>{word}</div>
        })}
        </HelpInfoQuestionWrapper>
        <HLetterWrapperDesktop>
          <img src={hLetter} style={{ width: 350 }} alt=""/>
        </HLetterWrapperDesktop>
        <HLetterWrapperMobile>
          <img src={hLetter} style={{ width: 200 }} alt=""/>
        </HLetterWrapperMobile>
      </div>
      <ParagraphWrapper>
        {START_SCREEN.H_TEXT}
      </ParagraphWrapper>
      <ButtonMain title={START_SCREEN.NOTIFS_BTN} variant="primary" size="large" />
    </HelpInfoBlockWrapper>
  </HelpInfoBlockContainer>
);

export default HelpInfoBlock;
