import { ButtonMain } from "../../../../components";
import Input from "../../../../components/inputs/input/Input";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  FormContainerStyled,
  FormTitleStyled,
} from "../utils/styles/form.styled";

export const PasswordRecoveryForm = ({
  handleSubmitToSignUp,
}: {
  handleSubmitToSignUp: any;
}): React.ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  }: any = useForm();
  const [hidePassword, setHidePassword] = useState<boolean>(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(false);

  return (
    <FormContainerStyled className="formContainer" onSubmit={handleSubmit(handleSubmitToSignUp)}>
      <FormTitleStyled style={{ textAlign: "center", fontWeight: 800, fontSize: 24 }}>
        Enter the email you used during registration to receive password reset instructions.
      </FormTitleStyled>

      <Input
        type="text"
        nameInput="email"
        register={{
          ...register("email", {
            required: true,
            pattern: "\b[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}\b",
          }),
        }}
        isLabel={true}
        labelText="Email:"
        // error={errors?.password ? true : false}
        // captionText={(errors.password?.message?.toString() as string) || ""}
      />

      <ButtonMain title={"SEND RESET INSTRUCTIONS"} onClick={() => null} variant="primary" />
    </FormContainerStyled>
  );
};

export default React.memo(PasswordRecoveryForm);
