import { styled } from "@mui/system";
import { COLORS } from "../../../../utils/constants/colors";

export const TitleWrapperStyled = styled("div")(({ theme }) => ({
  fontSize: 35,
  fontWeight: 600,
  marginBottom: 30,

  ".brTitle": {
    display: "none",
  },

  "span": {
    color: COLORS.MAIN_GRAY,
    fontSize: 20,
  },

  [theme.breakpoints.down(1550)]: {
    // padding: 20,
    fontSize: 30,

    "span": {
      fontSize: 15,
      display: "inline-block",
    },
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: 25,
    padding: 0,
    "span": {
      fontSize: 15,
    },
    ".brTitle": {
      display: "inline",
    },
  },
}));
