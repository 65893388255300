import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { AuthModel, ResponseErrorModel } from "models/auth/auth.model";
import authService from "../../auth-pages/api/service";
import userService from "./service";

const errorMessage = (error: AxiosError<ResponseErrorModel>): string =>
  error?.response?.data?.errors?.["email" || "display_name" || "phone" || "password"]?.toString() ||
  error?.response?.data?.message ||
  error?.toString();

export const signUp_ = createAsyncThunk(
  "user/signUp",
  async ({
    email, first_name, last_name, password, password_confirmation, domain_name, company
  }: AuthModel) => {
    try {
      const response = await authService.signUp({ email, first_name, last_name, password, password_confirmation, domain_name, company });
      const { status, data: userData } = response;
      const user: any = userData?.resource;

      if (status === 200) {
        localStorage.setItem("token", JSON.stringify(user.token));
        localStorage.setItem("id", JSON.stringify(user.user.id));
        localStorage.setItem("email", JSON.stringify(user.user.email));
        toast.success("Sign Up succesfull!");
      }

      // return response.data.data;
      return { user };
    } catch (error: unknown) {
      // toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const login = createAsyncThunk(
  "user/login",
  async (
    { email, password }: Partial<AuthModel>,
    thunkApi
  ) => {
    try {
      return authService.login({ email, password }).then(data => {
        const { status, data: userData } = data;

        // let user: UserAuthModel | null = userData?.data?.resource;
        let user: any = userData?.resource;

        if (status === 200) {
          localStorage.setItem("token", JSON.stringify(user.token));
          localStorage.setItem("id", JSON.stringify(user.user.id));
          localStorage.setItem("email", JSON.stringify(user.user.email));
          toast.success("Login succesfull");
        }

        if (status === 201) {
          // openModal();
          thunkApi.rejectWithValue(data.data.message);
          user = null;
        }

        return { user };
      });
    } catch (error: unknown) {
      toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async ({
  api_token
}: {api_token: string}) => {
  try {
    const response = await authService.logout(api_token);
    if (response.status === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("email");
      localStorage.removeItem("id");
    }
  } catch (error: unknown) {
    toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
  }
});

export const getUserInfo = createAsyncThunk("user/getInfo", async () => {
  try {
    const response = await userService.getUserInfo();
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: unknown) {
    toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
  }
});

export const updateUserInfo = createAsyncThunk("user/updateInfo", async (data: any) => {
  try {
    const response = await userService.updateUserInfo(data);
    if (response.status === 200) {
      toast.success('User Info has been successfully updated!');
      return response.data;
    }
  } catch (error: unknown) {
    toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
  }
});

export const changePassword = createAsyncThunk("user/updateInfo", async (data: any) => {
  try {
    const response = await userService.changePassword(data);
    if (response.status === 200) {
      toast.success('Password has been successfully changed!');
      return response.data;
    }
  } catch (error: unknown) {
    toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
  }
});

export const deleteUserProfile = createAsyncThunk("user/deleteProfile", async (id: any) => {
  try {
    const response = await userService.deleteUserProfile(id);
    if (response.status === 200) {
      toast.success('User Info was successfully deleted!');
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("email");
      localStorage.removeItem("id");
    }
  } catch (error: unknown) {
    toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
  }
});

export const recoveryPassword = createAsyncThunk("auth/recoveryPassword", async (data: any) => {
  try {
    const response = await userService.recoveryPassword(data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: unknown) {
    toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
  }
});

export const resetPassword = createAsyncThunk("auth/resetPassword", async (data: any) => {
  try {
    const response = await userService.resetPassword(data);
    if (response.status === 200) {
      localStorage.setItem("token", JSON.stringify(response.data.resource.token));
      localStorage.setItem("id", JSON.stringify(response.data.resource.user.id));
      localStorage.setItem("email", JSON.stringify(response.data.resource.user.email));
      return response.data;
    }
  } catch (error: unknown) {
    toast.error(errorMessage(error as AxiosError<ResponseErrorModel>));
  }
});
