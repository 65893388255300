import React from "react";
import {
  DesktopDontForget,
  DontForgetContainer,
  DontForgetWrapperStyled,
  FlexContainer,
  MobileDontForget,
  RenewDecorLineStyled,
  RenewTextStyled,
} from "../ui/styles/dontForget.styled";

export const DontForgetBlock = (): React.ReactElement => (
  <>
    <DesktopDontForget>
      <DontForgetContainer>
        <DontForgetWrapperStyled>
          <FlexContainer>
            Don't forget to&nbsp;
            <RenewTextStyled>
              renew your SSL certificiate<RenewDecorLineStyled></RenewDecorLineStyled>
            </RenewTextStyled>
          </FlexContainer>
          <RenewTextStyled>This tool will help you to:</RenewTextStyled>
        </DontForgetWrapperStyled>
      </DontForgetContainer>
    </DesktopDontForget>

    <MobileDontForget>
      <DontForgetContainer>
        <DontForgetWrapperStyled>
          <div>
            Don't forget to
            <RenewTextStyled>
              renew your SSL<RenewDecorLineStyled></RenewDecorLineStyled>
            </RenewTextStyled>
          </div>
          <RenewTextStyled>
            certificiates<RenewDecorLineStyled></RenewDecorLineStyled>
          </RenewTextStyled>
          <RenewTextStyled>This tool will help you to:</RenewTextStyled>
        </DontForgetWrapperStyled>
      </DontForgetContainer>
    </MobileDontForget>
  </>
);

export default DontForgetBlock;
