import { styled } from "@mui/system";
import { ExtendedThemeOptions } from "../../../../assets/theme-types";
import { COLORS } from "../../../../utils/constants/colors";

export const SidebarContainerStyled = styled("div")(({ theme }) => ({
  padding: 60,
  height: 'calc(100% - 80px)',
  position: 'fixed',
  backgroundColor: '#fafffa',
  borderRight: '0.5px solid #80808033',
  zIndex: 100,
  top: '80.5px',
  // flex: 3,
  width: '180px',
  minWidth: '180px',
  left: 0,
  display: 'inline-block',

  [theme.breakpoints.down("sm")]: {
    display: "none",

    "&.active": {
      display: "block",
      width: "100%",
      position: "absolute",
      backgroundColor: "white",
      height: "100%",
      zIndex: 10,
    }
  },
}));

export const DecorativeNavElement = styled("div")(({ theme }) => ({
  height: '8vh',
  position: "absolute",
  backgroundColor: COLORS.MAIN_BLUE,
  width: "800px",
  right: "130px",
  borderRadius: "20vh",
  zIndex: -1,
  opacity: 0,

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },

  // [theme.breakpoints.up(1500)]: {
  //   right: "16vh",
  // },

  // [theme.breakpoints.up(1700)]: {
  //   right: "17vh",
  // },

  // [theme.breakpoints.up(2000)]: {
  //   right: "13vh",
  // },
}));

export const NavlinkWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: 'space-between',
  marginBottom: 55,
  position: "relative",
  cursor: "pointer",
  color: '#8daded',
  fontWeight: "600",

  "&.mobileLink": {
    display: "none",
  },

  // "svg": {
  //   marginRight: '7vh',
  // },

  "svg": {
    marginRight: '10px',
  },

  "path": {
    fill: "#8daded",
  },

  "&.navlinkActive": {
    color: COLORS.MAIN_BLUE,
    transition: "0.6s all ease",
    ".decorativeNavElement": {
      transition: "0.6s all ease",
      opacity: 1,
    },
    "path": {
      fill: "white",
      transition: "1.4s all ease",
    }
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: 25,
    "svg": {
      width: 40,
      height: 40,
      marginRight: 20,
    },
    "&.mobileLink": {
      display: "flex",
    },

    "hr": {
      border: "none",
      borderTop: "1px solid #c4c4c457",
      width: "600px",
      height: "4px",
      position: "absolute",
      top: "-80px",
      left: "-100px",
    },

    "&.mobileLink-0": {
      marginTop: 130,
    },

    "&.navlinkActive": {
      "path": {
        fill: COLORS.MAIN_BLUE,
      }
    }
  },
}));
