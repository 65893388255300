import { styled } from "@mui/system";

export const ActionsWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 20,
  
  ".maxWidthInput": {
    flex: 1,

    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: 20,
    },
  },


  ".marginInput": {
    marginRight: 20,

    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  }, 

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    marginTop: 10,
    marginBottom: 0,
    flexDirection: "column",
  },
}));

export const ProfileFormWrapper = styled("div")(({ theme }) => ({
  flex: 2,
  ".marginBottomInput": {
    marginBottom: 20,
  },

  [theme.breakpoints.down("sm")]: {
  },
}));

export const ButtonsWrapperStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    justifyContent: "space-between",
    marginTop: "10px",
  },
}));

export const MarginWrapperStyled = styled("div")(({ theme }) => ({
  marginRight: 10,
}));

export const MarginInputWrapperStyled = styled("div")(({ theme }) => ({
  marginRight: 10,

  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
    flex: 1,
  },
}));
