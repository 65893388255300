import { ButtonMain } from "components";
import Input from "components/inputs/input/Input";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { SIGN_UP } from "utils/constants/auth";

import {
  FormContainerStyled,
  FormTitleStyled,
  InputsWrapperStyled,
} from "../utils/styles/form.styled";

export const RegisterForm = ({handleSubmitToSignUp, isFetching}: any): React.ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  }: any = useForm();
  const [hidePassword, setHidePassword] = useState<boolean>(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(false);

  return (
    <FormContainerStyled className="formContainer" onSubmit={handleSubmit(handleSubmitToSignUp)}>
      <FormTitleStyled style={{ textAlign: "center", fontWeight: 800, fontSize: 24 }}>
        {SIGN_UP.TITLE}
      </FormTitleStyled>
      <InputsWrapperStyled>
        <Input
          type="text"
          nameInput="first_name"
          register={{
            ...register("first_name"),
            required: true,
          }}
          isLabel={true}
          labelText="First Name:"
          // error={errors?.password ? true : false}
          // captionText={(errors.password?.message?.toString() as string) || ""}
          className="firstInput"
        />
        <Input
          type="text"
          nameInput="last_name"
          register={{
            ...register("last_name"),
            required: true,
          }}
          isLabel={true}
          labelText="Last Name:"
          // error={errors?.password ? true : false}
          // captionText={(errors.password?.message?.toString() as string) || ""}
        />
      </InputsWrapperStyled>

      <Input
        type="text"
        nameInput="email"
        register={{
          ...register("email", {
            required: true,
            pattern: "\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b",
          }),
        }}
        isLabel={true}
        labelText="Email:"
        // error={errors?.password ? true : false}
        // captionText={(errors.password?.message?.toString() as string) || ""}
      />

      <InputsWrapperStyled>
        <Input
          type="password"
          nameInput="password"
          register={{
            ...register("password"),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="Password:"
          className="firstInput"
          hidePassword={hidePassword}
          setHidePassword={setHidePassword}
        />
        <Input
          type="password"
          nameInput="password_confirm"
          register={{
            ...register("password_confirm", {
              validate: (val: string) => {
                if (watch('password') !== val) {
                  return "Your passwords do no match";
                }
              },
            }),
            required: true,
            minLength: 8,
          }}
          isLabel={true}
          labelText="Confirm Password:"
          hidePassword={hideConfirmPassword}
          setHidePassword={setHideConfirmPassword}
        />
      </InputsWrapperStyled>

      <ButtonMain title={SIGN_UP.BUTTON} onClick={() => null} variant="primary" disabled={isFetching} />
    </FormContainerStyled>
  );
};

export default React.memo(RegisterForm);
