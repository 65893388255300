import { LogoMain } from "../../../components";
import React, {useEffect, useRef, useState} from "react";
import { getItemFromLocalStorage } from "../../../utils/helpers";
import { ReactComponent as EditProfile } from "../../../assets/images/icon/edit.svg";
import { ReactComponent as LogOut } from "../../../assets/images/icon/sign-out.svg";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as BurgerMenuIcon } from "../../../assets/images/icon/burger-menu.svg";
import { ReactComponent as CloseBurgerMenuIcon } from "../../../assets/images/icon/close-burger-menu.svg";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {
  BurgerIconMenu,
  HeaderContainerStyled,
  LogoWrapper,
  UserEmailStyled,
  UserInfoStyled,
  UserLogoStyled,
  UserNavContainerStyled,
  UserNavElement,
  UserPlanStyled,
} from "../utils/styles/header.styled";
import { useAppDispatch } from "../../../utils/hooks/global";
// import { logout } from "store/user/userAsync";
import { logout } from "../../../pages/user-profile/api/async";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../pages/router/routes";
import { useBilling } from "pages/billing/utils/hook";

interface IHeader {
  setOpenedAllMenu?: any;
  openedAllMenu?: boolean;
}

  export const Header = ({ setOpenedAllMenu, openedAllMenu }: IHeader): JSX.Element => {
  const email = getItemFromLocalStorage("email");
  const token = getItemFromLocalStorage("token");

  const {
    billingInfo,
  } = useBilling();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    anchorEl ? setAnchorEl(null): setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openedNavMenu, setOpenedNavMenu] = useState<boolean>(true);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const onHandleSignOut = () => {
    dispatch(logout({api_token: token})).then(res => window.location.reload())
  }

  const headerRef = useRef(null);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (openedNavMenu) {
        setIsOpened(true);
      }

      if (isOpened) {
        setOpenedNavMenu(false);
        setIsOpened(false);
      }
    };

    if (isOpened) {
      setTimeout(() => {
        setOpenedNavMenu(false);
        setIsOpened(false);
      }, 8000)
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [openedNavMenu, isOpened]);

  return (
    <HeaderContainerStyled className={`${openedAllMenu ? 'openedHeaderMenu' : ''}`} >
      <LogoWrapper className={`${openedAllMenu ? "hiddenLogo" : ""}`}><LogoMain size="small" onClick={() => navigate(ROUTES.DASHBOARD)}/></LogoWrapper>

      <UserNavContainerStyled className={`${openedAllMenu ? "showUserData" : ""}`}>
        <UserLogoStyled>{email ? email[0] : ""}</UserLogoStyled>
        <UserInfoStyled onClick={(ev) => handleClick(ev)}>
          <UserEmailStyled>
            {email}
            <ArrowDropDownIcon/>
          </UserEmailStyled>
          <UserPlanStyled>{(billingInfo?.name === 'unlimited' || billingInfo?.name === 'pro') ? 'PRO PLAN' : 'TRIAL PLAN'}</UserPlanStyled>
          {/* {openedNavMenu && <UserNavMenu>
            <UserNavElement onClick={() => {
              navigate(ROUTES.PROFILE);
              setOpenedNavMenu(false);
            }}>
              <EditProfile />
              <span>Edit Account</span>
            </UserNavElement>
            <UserNavElement onClick={() => onHandleSignOut()}>
              <LogOut />
              <span>Sign Out</span>
            </UserNavElement>
          </UserNavMenu>} */}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
        <UserNavElement onClick={() => {
              navigate(ROUTES.PROFILE);
              setOpenedNavMenu(false);
            }}>
              <EditProfile />
              <span>Edit Account</span>
            </UserNavElement>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <UserNavElement onClick={() => onHandleSignOut()}>
              <LogOut />
              <span>Sign Out</span>
            </UserNavElement>
        </MenuItem>
      </Menu>
        </UserInfoStyled>
      </UserNavContainerStyled>

      <BurgerIconMenu onClick={() => setOpenedAllMenu(!openedAllMenu)}>
        {openedAllMenu ? <CloseBurgerMenuIcon /> : <BurgerMenuIcon />}
      </BurgerIconMenu>


    </HeaderContainerStyled>
  );
};

export default React.memo(Header);
