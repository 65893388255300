import moment from "moment";
import {createBrowserHistory} from "history";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const roundNumber = (val: string | number, multiplier = 100): number =>
  Math.round(Number(val) * multiplier) / multiplier;

export const isEmptyObject = (obj: object | null): boolean =>
  !obj ? true : !Object.values(obj).length;

export const generateHash = (): string =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const cutString = (str: string, length: number): string =>
  str.length <= length ? str : `${str.slice(0, length)}...`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onlyUnique = (value: any, index: number, self: any): boolean =>
  self.indexOf(value) === index;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getItemFromLocalStorage = (item: string): any =>
  localStorage.getItem(item) ? JSON.parse((localStorage.getItem(item) as string) || "") : null;

export const deepClone = <TInput>(input: TInput): TInput =>
  input === null || typeof input !== "object"
    ? input
    : Object.keys(input).reduce((acc, key) => {
      acc[key as keyof TInput] = deepClone(input[key as keyof TInput]);
      return acc as TInput;
    }, (Array.isArray(input) ? [] : {}) as TInput);

/// checking for the exact version of the customer
export const returnVariantCustomer = (
  customerActivity: ["surprise" | null, "group" | null]
): "double" | "group" | "surprise" | "none" => {
  if (customerActivity[0] && customerActivity[1]) {
    return "double";
  } else if (!customerActivity[0] && customerActivity[1]) {
    return "group";
  } else if (customerActivity[0] && !customerActivity[1]) {
    return "surprise";
  } else {
    return "none";
  }
};

export const isNumberLessNine = (number: number): string => (number <= 9 ? "0" : "");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce(callback: any, delay: number): VoidFunction {
  let timeoutHandler: NodeJS.Timeout | null = null;
  return () => {
    if (timeoutHandler) clearTimeout(timeoutHandler);
    timeoutHandler = setTimeout(function () {
      callback();
    }, delay);
  };
}

export const isStringDate = (string: string): boolean => {
  const regexData = new RegExp(
    // eslint-disable-next-line
    "([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})"
  );
  return regexData.test(string);
};

export const isPriceRange = (string: string): boolean => {
  const regexPrice = new RegExp("([0-9][-][1-9])");
  return regexPrice.test(string);
};

export const getFormattedDateTime = (date: string | null): string => {
  const formatted = date ? moment(date).format('LLL') : '-'
  return formatted;
};

export const getAuthToken = (): string => {
  return getItemFromLocalStorage("token");
};

export const getUserId = (): string => {
  return getItemFromLocalStorage("id");
};

export const createDomainData = (
  name: string,
  numericalValue: string | number | null,
  issuer: string | undefined | null,
  expiryDate: string,
  chackedDate: string,
  hostname: "error" | "warning" | "success",
  id: string,
  isTracking: boolean,
) => {
  return { name, numericalValue, issuer, expiryDate, chackedDate, hostname, id, isTracking };
}

export const history = createBrowserHistory();

export const checkIsErrorUnauthenticated = (error: string): boolean => error !== "Unauthenticated.";