export const SIGN_UP: any = {
    F_NAME: "First Name:",
    L_NAME: "Last Name:",
    EMAIL: "Email:",
    PASS: "Password:",
    PASS_CONFIRM: "Confirm Password:",

    BUTTON: "Sign Up",
    SIGN_IN: "Sign In",
    ALREADY: "Already a Member?",
    TITLE: "Get notified before it expires! Free for up to 2 domains:",
    EXPIRE: "Certificate Expires In 76 Days",

    OPT_1: "Issued:",
    OPT_2: "Expiration:",
    OPT_3: "Issuer:",
    OPT_4: "Valid Hostname:",
    OPT_5: "Port:",
} as const;

export const SIGN_IN: any = {
    F_NAME: "First Name:",
    L_NAME: "Last Name:",
    EMAIL: "Email:",
    PASS: "Password:",
    PASS_CONFIRM: "Confirm Password:",

    BUTTON: "Login",
    SIGN_IN: "Sign In",
    NOT_REGISTERED: "Not registered?",
    CREATE_ACCOUNT: "Create an Account",
    TITLE: "Login to Your Account",
    EXPIRE: "Certificate Expires In 76 Days",

    OPT_1: "Issued:",
    OPT_2: "Expiration:",
    OPT_3: "Issuer:",
    OPT_4: "Valid Hostname:",
    OPT_5: "Port:",
} as const;