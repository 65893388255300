import { AxiosResponse } from "axios";

import instance from "../../../service/api";

const setNotifTypes = async ({
  is_encrypt,
  is_change_alerts,
}: {is_encrypt: boolean; is_change_alerts: boolean}): Promise<AxiosResponse<any>> =>
  await instance
    .post(`/api/v1/user-notifications/types?is_encrypt=${is_encrypt}&is_change_alerts=${is_change_alerts}`, {
      is_encrypt, is_change_alerts
    })
    .then(response => response)

    const setNotifByEmail = async ({
      is_notify_email,
    }: {is_notify_email: boolean}): Promise<AxiosResponse<any>> =>
      await instance
        .post(`/api/v1/user-notifications/by-email?is_notify_email=${is_notify_email}`, {
          is_notify_email
        })
        .then(response => response)

        const setNotifSchedule = async ({
          is_one_day_before_expiry,
    is_three_days_before_expiry,
    is_seven_days_before_expiry,
    is_fourteen_days_before_expiry,
    is_thirty_days_before_expiry,
    is_sixty_days_before_expiry
        }: {is_one_day_before_expiry: boolean;
          is_three_days_before_expiry: boolean;
          is_seven_days_before_expiry: boolean;
          is_fourteen_days_before_expiry: boolean;
          is_thirty_days_before_expiry: boolean;
          is_sixty_days_before_expiry: boolean;}): Promise<AxiosResponse<any>> =>
          await instance
            .post(`/api/v1/user-notifications/schedule?is_one_day_before_expiry=${is_one_day_before_expiry}&is_three_days_before_expiry=${is_three_days_before_expiry}&is_seven_days_before_expiry=${is_seven_days_before_expiry}&is_fourteen_days_before_expiry=${is_fourteen_days_before_expiry}&is_thirty_days_before_expiry=${is_thirty_days_before_expiry}&is_sixty_days_before_expiry=${is_sixty_days_before_expiry}`, {
              is_one_day_before_expiry, is_three_days_before_expiry, is_seven_days_before_expiry, is_fourteen_days_before_expiry, is_thirty_days_before_expiry, is_sixty_days_before_expiry
            })
            .then(response => response)

const getNotifications = async (): Promise<AxiosResponse<any>> =>
  await instance
    .get("/api/v1/user-notifications")
    .then(response => response);

const notificationsService = {
  getNotifications,
  setNotifTypes,
  setNotifByEmail,
  setNotifSchedule,
};

export default notificationsService;
