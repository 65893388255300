import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { LogoMain } from "../../../components";
import { PasswordResetForm } from "./components/PasswordResetForm";
import { SignUpContainerStyled, SignUpWrapperStyled } from "./utils/styles/styled";
import { resetPassword } from "../api/async";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/global";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "../../../pages/router/routes";

export const ResetPassword = (): React.ReactElement => {
  const { register, handleSubmit } = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((store) => store.user);
  const [recoveredToken, setRecoveredToken] = useState<any>(null);

  const onSetPassword = (data: any) => {
    dispatch(
      resetPassword({
        password: data.password,
        password_confirmation: data.password_confirm,
        token: recoveredToken,
      })
    );
  };

  const goToSignIn = () => {
    navigate(ROUTES.LOGIN);
  };

  useEffect(() => {
    if (location?.search) {
      const pathnameList = location?.search.split("=");
      setRecoveredToken(pathnameList[1]);
    }
  }, [location]);

  useEffect(() => {
    if (user.isLoggedIn) {
      window.location.reload();
    }
  }, [user])

  return (
    <SignUpContainerStyled>
      <SignUpWrapperStyled>
        <div style={{ marginBottom: 10, cursor: "pointer" }} onClick={() => navigate(ROUTES.HOME)}>
          <LogoMain size="small" />
        </div>
        <PasswordResetForm handleSubmitToSignUp={onSetPassword} />
        {/* <div style={{ marginTop: 20 }}>
          <span style={{ cursor: "pointer", color: COLORS.MAIN_BLUE }} onClick={goToSignIn}>
            {SIGN_UP.SIGN_IN}
          </span>
        </div> */}
      </SignUpWrapperStyled>
    </SignUpContainerStyled>
  );
};

export default React.memo(ResetPassword);
