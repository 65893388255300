const email = localStorage.getItem('email');

export const NOTIFICATIONS_LIST_TEMPLATE: any = [
    {
        TITLE: "Types",
        BUTTON_TITLE: "Save",
        PRE_BUTTON_TEXT: "",
        BUTTON_VARIANT: "primary",
        TOP_TEXT: "Decide which types of notifications you'd like to receive.",
        BOTTOM_TEXT: "",
        SELECTIONS: [
            {
                value: "Let's Encrypt",
                checked: true,
            },
            {
                value: "Change Alerts",
                checked: false,
            },
        ],
    },
    {
        TITLE: "Email",
        BUTTON_TITLE: "Save",
        BUTTON_VARIANT: "primary",
        PRE_BUTTON_TEXT: "To use additional emails for notifications:",
        PRE_BUTTON_TITLE: "Upgrade Required",
        PRE_BUTTON_VARIANT: "secondary",
        TOP_TEXT: "",
        BOTTOM_TEXT: `Notification emails will always be delivered to your registered email address, which is ${email ? JSON.parse(email) : ''}.`,    
        SELECTIONS: [
            {
                value: "Email Notifications Enabled?",
                checked: true,
            },
        ],
    },
    {
        TITLE: "Slack",
        BUTTON_TITLE: "Add to Slack",
        PRE_BUTTON_TEXT: "",
        BUTTON_VARIANT: "secondary",
        TOP_TEXT: "",
        BOTTOM_TEXT: "",
        SELECTIONS: [],
    },
    {
        TITLE: "Schedule",
        BUTTON_TITLE: "Save",
        PRE_BUTTON_TEXT: "",
        BUTTON_VARIANT: "primary",
        TOP_TEXT: "Control at what point you'd like to start receiving emails about your expiring certificates.",
        BOTTOM_TEXT: "",
        SELECTIONS: [
            {
                value: "One day before expiry",
                checked: true,
            },
            {
                value: "Three days before expiry",
                checked: false,
            },
            {
                value: "Seven days before expiry",
                checked: true,
            },
            {
                value: "Fourteen days before expiry",
                checked: true,
            },
            {
                value: "Twenty One days before expiry",
                checked: true,
            },
            {
                value: "Thirty days before expiry",
                checked: true,
            },
            {
                value: "Sixty days before expiry",
                checked: true,
            },
        ],
    },
    {
        TITLE: "Microsoft Teams",
        BUTTON_TITLE: "Upgrade Required",
        PRE_BUTTON_TEXT: "",
        BUTTON_VARIANT: "secondary",
        TOP_TEXT: "",
        BOTTOM_TEXT: "",
        SELECTIONS: [],
    },
 ] as const;