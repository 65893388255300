import React from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { CircularProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import {
  BlackHeadTitle,
  BlueHeadTitle,
  DomainName,
  IconWrapper,
  NumericalValue,
} from "../ui/styles/domainsList.styled";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { ROUTES } from "../../../../pages/router/routes";
import { ButtonMain } from "components";

export const DomainsList = ({domains, domainsInProgress, currentPage, onChangePage, totalCount, checkingInProgress, showOrHideUntrackedDomains, showAll}: any): React.ReactElement => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  function createData(
    name: string,
    numericalValue: string | number,
    issuer: string | null,
    expiryDate: string,
    chackedDate: string,
    hostname: "error" | "warning" | "success"
  ) {
    return { name, numericalValue, issuer, expiryDate, chackedDate, hostname };
  }

  const rows = [
    createData(
      "ideus.biz",
      159,
      "Let's Encrypt",
      "April 24, 2023 1:51 PM",
      "January 31, 2023 11:01 AM",
      "success"
    ),
    createData(
      "net-craft.com",
      12,
      "Let's Encrypt",
      "April 24, 2023 1:51 PM",
      "January 31, 2023 11:01 AM",
      "warning"
    ),
    createData(
      "nike.com",
      -262,
      "Let's Encrypt",
      "April 24, 2023 1:51 PM",
      "January 31, 2023 11:01 AM",
      "error"
    ),
    createData(
      "adidas.com",
      305,
      "Let's Encrypt",
      "April 24, 2023 1:51 PM",
      "January 31, 2023 11:01 AM",
      "success"
    ),
    createData(
      "net-craft.co",
      -356,
      "Let's Encrypt",
      "April 24, 2023 1:51 PM",
      "January 31, 2023 11:01 AM",
      "error"
    ),
    createData(
      "ideus.bizz",
      15,
      "Let's Encrypt",
      "April 24, 2023 1:51 PM",
      "January 31, 2023 11:01 AM",
      "warning"
    ),
  ];

  return (
    <TableContainer className="domainsList">
      <ButtonMain 
        variant="simple" 
        onClick={showOrHideUntrackedDomains}
        title={!showAll ? 'Show Untracked Domains' : 'Hide Untracked Domains'} 
        size="small" 
        style={{color: 'black', marginLeft: 0, height: 30, borderBottom: '1px solid black', borderRadius: 'initial', paddingRight: 0, paddingLeft: 0, marginTop: -5, display: 'flex', alignItems: 'center'}} 
        icon={require('../../../../assets/images/icon/show-all.png')}
      />
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell>
              <BlueHeadTitle>Domain</BlueHeadTitle>
            </TableCell>
            <TableCell>
              <BlueHeadTitle>Issuer</BlueHeadTitle>
            </TableCell>
            <TableCell>
              <BlueHeadTitle>Expiry Date</BlueHeadTitle>
            </TableCell>
            <TableCell>
              <BlueHeadTitle>Last Checked</BlueHeadTitle>
            </TableCell>
            <TableCell>
              <BlueHeadTitle>Tracking Status</BlueHeadTitle>
            </TableCell>
            <TableCell align="center">
              <BlackHeadTitle>Hostname</BlackHeadTitle>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {domains.map((row: any) => (
            <TableRow key={row.name} onClick={() => navigate(ROUTES.DOMAIN_DETAIL, {
              state: {
                domainId: row.id,
                hostname: row.hostname,
              }
            })} style={{cursor: "pointer"}}>
              <TableCell align="center">
                {/* {domainsInProgress.includes(row.id) ? <CircularProgress /> : <NumericalValue $color={row.hostname}>{row.numericalValue}</NumericalValue>} */}
                {(checkingInProgress === 'yes' && row.isTracking) ? <CircularProgress /> : <NumericalValue $color={row.hostname}>{row.numericalValue}</NumericalValue>}
              </TableCell>
              <TableCell>
                <DomainName>{row.name}</DomainName>
              </TableCell>
              <TableCell>{row.issuer}</TableCell>
              <TableCell>{domainsInProgress.includes(row.id) ? '-' : row.expiryDate}</TableCell>
              <TableCell>{domainsInProgress.includes(row.id) ? '-' : row.chackedDate}</TableCell>
              <TableCell align="left"><span style={{width: 8, height: 8, backgroundColor: row.isTracking ? '#19DD21' : 'gray', display: 'inline-block', borderRadius: '50%', marginRight: 7, marginLeft: 10}}></span><span>{row.isTracking ? 'TRACKING' : 'NOT TRACKING'}</span></TableCell>
              <TableCell align="center">
              {domainsInProgress.includes(row.id) ? '-' : 
                <IconWrapper $color={row.hostname}>
                  {row.hostname === "warning" ? (
                    <PriorityHighRoundedIcon />
                  ) : row.hostname === "error" ? (
                    <CloseRoundedIcon />
                  ) : (
                    <DoneRoundedIcon />
                  )}
                </IconWrapper>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Pagination count={Math.ceil(totalCount / 10)} variant="outlined" style={{marginTop: 20}} page={currentPage} onChange={onChangePage} /> */}
    </TableContainer>
  );
};

export default DomainsList;
