import { styled } from "@mui/system";
import { ExtendedThemeOptions } from "../../../../assets/theme-types";

export const TypedTextStyled = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  fontStyle: "italic",
  height: "380px",
  marginTop: 30,
  marginBottom: 30,

  [theme.breakpoints.down("md")]: {
    height: "170px",
  },
}));

export const TypedTextWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  maxWidth: "800px",
  height: "380px",
  ...(theme.typography as ExtendedThemeOptions["typography"]).inter_300_88px,

  [theme.breakpoints.down("md")]: {
    width: 500,
    height: 150,
    fontSize: "40px",
    lineHeight: "45px",

    span: {
      fontSize: "40px",
      lineHeight: "45px",
    },
  },
}));

export const DesktopTyped = styled("div")(({ theme }) => ({
  display: "block",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const MobileTyped = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));